import {
  api,
  readerActions,
  inputInitializer,
  getPaginatedTableMetaChanges,
  populateInitialReduxPaginatedListState,
} from '../../internal'

import { ReaderC } from '@unikey/unikey-commons/release/comm';

const initialReadersState = populateInitialReduxPaginatedListState(['name'], 'forward');
export const readers = (state = initialReadersState, action: any) => {
  switch (action.type) {
    case readerActions.GET_READERS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case readerActions.GET_READERS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        data: action.value,
        queryParams: Object.assign({}, state.queryParams, { sort: action.value.sort })
      }
    case readerActions.GET_READERS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        data: initialReadersState.data
      }
    case readerActions.UPDATE_READER_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case readerActions.UPDATE_READERS_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}

const initialReaderDetailsState = {
  loading: true,
  readerData: {},
  editData: inputInitializer('', ['name']),
}

const setEditReaderFieldsBasedOnResult = (readerResult: ReaderC) => {
  return {
    name: {
      value: readerResult.name,
      valid: true
    }
  }
};

export const readerDetails = (state = initialReaderDetailsState, action: any) => {
  switch (action.type) {
    case readerActions.GET_READER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case readerActions.GET_READER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        readerData: action.value,
        editData: setEditReaderFieldsBasedOnResult(action.value)
      }
    case readerActions.GET_READER_DETAILS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        readerData: {},
        editData: inputInitializer('', ['name'])
      }
    case readerActions.HANDLE_READER_DETAILS_CHANGE:
      return {
        ...state,
        editData: {
          name: action.newName ? action.newName : state.editData.name
        }
      }
    case readerActions.UPDATE_READER_DETAILS_REQUEST:
    case readerActions.UPDATE_READER_DETAILS_SUCCESS:
    case readerActions.UPDATE_READER_DETAILS_FAILURE:
      return {
        ...state,
        loading: action.pending
      }
    default:
      return state
  }
}


const cleanEnrollmentMaps = () => {
  return {
    enrollableSerialNumbers: new Map(),
    unenrollableSerialNumbers: new Map(),
  }
}
const initialReaderEnrollmentFormState = {
  loading: false,
  readerData: {},
  modalOpen: false,
  ...inputInitializer('', ['serialNumber']),
  ...cleanEnrollmentMaps(),
  workflowStepIndex: 0,
}

export const readerEnrollmentForm = (state = initialReaderEnrollmentFormState, action: any) => {
  switch (action.type) {
    case readerActions.TOGGLE_ENROLL_READERS_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen
      }
    case readerActions.UPDATE_SERIAL_NUMBER_ENROLLMENT_MAPS:
      return {
        ...state,
        enrollableSerialNumbers: action.enrollable ? action.enrollable : state.enrollableSerialNumbers,
        unenrollableSerialNumbers: action.unenrollable ? action.unenrollable : state.unenrollableSerialNumbers,
      }
    case readerActions.CLEAR_SERIAL_NUMBER_ENROLLMENT_MAPS:
      return {
        ...state,
        ...cleanEnrollmentMaps()
      }
    case readerActions.UPDATE_ENROLL_READERS_WORKFLOW_STEP: 
      return {
        ...state,
        workflowStepIndex: action.stepTo,
      }
    case readerActions.GET_READER_ENROLLMENT_ELIGIBILITY_REQUEST:
      return {
        ...state,
        loading: true
      }
    case readerActions.GET_READER_ENROLLMENT_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        loading: api.pendingReqs.getNum(readerActions.GET_READER_ENROLLMENT_ELIGIBILITY_REQUEST) > 0,
        ...inputInitializer('', ['serialNumber'])
      }
    case readerActions.GET_READER_ENROLLMENT_ELIGIBILITY_FAILURE:
      return {
        ...state,
        loading: api.pendingReqs.getNum(readerActions.GET_READER_ENROLLMENT_ELIGIBILITY_REQUEST) > 0,
        ...inputInitializer('', ['serialNumber'])
      }
    case readerActions.HANDLE_SERIAL_NUMBER_CHANGE:
      return {
        ...state,
        serialNumber: action.serialNumber ? action.serialNumber : state.serialNumber
      }
    default:
      return state;
  }
}

