import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniOverflowActions,
  Editable,
  IMultiInputUpdate,
  ReaderC,
  UniKeyVal,
  UniLocalize,
  EOrganizationStatus,
  EOperationCodesC,
  IUniConfirm_Config,
  IUniMenu_ItemConfig,
  notBlankV10n,

} from '@unikey/unikey-commons/release/comm';

import {
  canI,
  portalRedirect,
  attemptRetrieveReaderDetails,
  attemptUpdateReaderDetails,
  attemptDeleteReader,
  handleReaderDetailsChange,
  openConfirmModal,
  closeConfirmModal,

  PartnerCustomizations, IPartnerCustomizations,
  addAlert,
  navConfig, ENavPages
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  orgId: string,
  readerName: Editable<string>,
  readerData: ReaderC,
  permissionToEditReader: boolean,
  permissionToDeleteReader: boolean,

  getReaderDetails(readerId: string): Promise<void>,
  updateReaderDetails(readerId: string): Promise<void>,
  handleDetailsChange(change: IMultiInputUpdate): void,
  deleteReader(readerId: string): Promise<void>,
  openConfirmDialog(dialogConfig: IUniConfirm_Config): void,
  closeConfirmModal(): void,
}

class ReaderDetailsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getReaderDetails(this.props.match.params.readerId);
  }

  _readerNameChange = (newName: Editable<string>) => {
    this.props.handleDetailsChange({ newName });
  }

  _saveReaderDetailsAndReload = () => {
    return this.props.updateReaderDetails(this.props.readerData.id).then(() => {
      this.props.getReaderDetails(this.props.match.params.readerId);
    })
  }

  _deleteReaderAndBackToList = () => {
    return this.props.deleteReader(this.props.readerData.id).then(() => {
      portalRedirect(
        navConfig.get(ENavPages.readers)!.linkTo([
          this.props.match.params.organizationId
        ])!
      )
    })
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const actionsMenuContents: Map<string, IUniMenu_ItemConfig> = new Map()
      .set('removeReader', {
        textKey: 'removeReader',
        handleClick: () => this.props.openConfirmDialog({
          titleKey: 'removeReader',
          titleIcon: 'delete',
          messageKeys: ['youAreAboutToDeleteThisReader', this.props.readerName.value, 'areYouSure'],
          confirmTextKey: 'delete',
          cancelHandler: this.props.closeConfirmModal,
          confirmHandler: () => {
            this._deleteReaderAndBackToList()
            this.props.closeConfirmModal()
          }
        }),
        hidden: !this.props.permissionToDeleteReader
      });

    return (
      <section className='readerDetails-container'>
        <Row justify="between">
          <Col>
            <h3 className="reader-details-title">
              <UniLocalize translate="readerDetails" /> - {this.props.readerData.name}</h3>
          </Col>
          <Col xs={6} lg={4}>
            <UniOverflowActions
              className="title-actions"
              nameKey="readerActions"
              icon="arrowDropDownCircle"
              actions={actionsMenuContents}
              allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl']}
              theme="primary" />
          </Col>
        </Row>

        <UniKeyVal
          label={`orgDetails`}
          showLoader={!this.props.readerData.id}
          preventEdit={!this.props.permissionToEditReader}
          preventSave={this.props.readerData.name === this.props.readerName.value}
          cancelClick={this.props.getReaderDetails.bind(this, this.props.match.params.readerId)}
          saveClick={this._saveReaderDetailsAndReload}
          fields={[
            {
              keyName: 'identifier',
              value: `${this.props.readerData.id}`,
              type: 'string',
              disabled: true,
            },
            {
              keyName: 'name',
              value: `${this.props.readerName.value}`,
              type: 'string',
              placeholderKey: 'readerName',
              preventTranslate: true,
              handleUpdate: this._readerNameChange,
              validations: [notBlankV10n]
            },
            {
              keyName: 'serialNumber',
              value: `${this.props.readerData.serialNumber}`,
              type: 'string',
              disabled: true
            },
            {
              keyName: 'firmwareVersion',
              value: `${this.props.readerData.firmwareVersion}`,
              type: 'string',
              disabled: true,
            },
            {
              keyName: 'upgradeVersion',
              value: `${this.props.readerData.availableUpgradeVersion}`,
              type: 'string',
              disabled: true
            }
          ]} />

      </section>
    )
  }
}

function mapStateToProps(state: any, ownProps: IProps) {
  return {
    readerName: state.readerDetails.editData.name,
    readerData: state.readerDetails.readerData,
    rederLoading: state.readerDetails.loading,

    // permissions
    permissionToEditReader: canI(EOperationCodesC.UpdateReader, state.dealer.dealerData.id, ownProps.match.params.organizationId),
    permissionToDeleteReader: canI(EOperationCodesC.DeleteReader, state.dealer.dealerData.id, ownProps.match.params.organizationId)
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getReaderDetails: attemptRetrieveReaderDetails,
  updateReaderDetails: attemptUpdateReaderDetails,
  handleDetailsChange: handleReaderDetailsChange,

  deleteReader: attemptDeleteReader,
  openConfirmDialog: openConfirmModal,
  closeConfirmModal,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(ReaderDetailsContainer)
  ), { componentName: 'ReaderDetails' })
