import React, { Component } from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { Route, Switch, Redirect, matchPath } from 'react-router'
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system'
import classNames from 'classnames';

import {
  darkThemeName,
  AuthUserC,
  UniThemeToggle,
  UniLeftNav,
  UniRightNav,
  UniButton,
  UniAvatar,
  UniMenu,
  UniConfirm,
  UniBreadcrumbs,
  UniConditionalRender,
  UniImg,
  UserC,
  DealerC,
  IDynamicSkinConfig,
  IUniConfirm_Config,
  INavConfig_FlowPage,
  INavConfig_NestedPage,
  PermissionMapC,
  IUniMenu_ItemConfig,
  UniOverflowActions,
  UniTopNav
} from '@unikey/unikey-commons/release/comm';

import partnerConfig from '@alias-current-partner-customizations';

import {
  mockAuth,
  api,
  portalRedirect,
  partnerKey,
  SplashContainer,
  FooterContainer,
  toggleAvatarMenu,
  toggleDealerSwitcher,
  setActivePortalDealer,
  updateCanUserCreateDealer,
  showAllAlerts,
  setDarkModeTheme,
  attemptRetrieveDealerDetails,
  attemptRetrieveAuthUser,
  attemptGetDealerSkinDetails,
  logoutKey,
  navConfig, ENavPages,
  redirectToLogin,
  firstTruthyOf,
  PartnerCustomizations, IPartnerCustomizations,
  OrganizationCreateContainer,
  ReaderEnrollContainer,
  ChangePasswordContainer,
  AddPasswordEmailRequestContainer,
  AdminInviteContainer,
  AdminPermissionsEditContainer,
  CredentialCreateContainer,
  DealerEditCreditsContainer
} from '../internal'

interface IProps extends IPartnerCustomizations {
  location: any,
  authenticatedUser: AuthUserC,
  activePath: string,
  avatarMenuOpen: boolean,
  activeDealer: DealerC,
  activeDealerId: string,
  dealerSwitcherOpen: boolean,
  numAlerts: number,
  navigationConfig: Map<string, INavConfig_FlowPage | INavConfig_NestedPage>,
  minimized?: boolean,
  logo?: string,
  darkModeTheme: string,
  confirmDialogOpen: boolean,
  confirmDialogConfig: IUniConfirm_Config,
  dealerSkinningOffered?: boolean,
  dealerSkinOverrides?: IDynamicSkinConfig,
  dealerSkinLoading?: boolean,
  permissions?: PermissionMapC,
  canUserCreateDealer?: boolean,
  passwordEditModalOpen?: boolean,
  addPasswordModalOpen?: boolean,
  createOrganizationModalOpen?: boolean,
  enrollReaderModalOpen?: boolean,
  inviteAdminModalOpen?: boolean,
  adminPermissionManagerModalOpen?: boolean,
  credentialCreateModalOpen?:boolean,
  addDealerCreditsModalOpen?: boolean,
  darkModeActive?: boolean,
  changeDarkModeTheme(theme: string): void,
  updateCanUserCreateDealer(canCreate :boolean): void,
  showAllAlerts(): void,
  getCurrentUser(): void,
  getDealerSkin(): void,
  toggleAvatarMenu(open?: boolean): void,
  toggleDealerSwitcher(open?: boolean): void,
  setActivePortalDealer(dealer: DealerC, switchingDealers?: boolean): void,
  redirectToLogin(): any;
}

class Portal extends Component<IProps> {
  avatarMenuConfig: Map<string, IUniMenu_ItemConfig>;
  dealerSwitcherConfig: Map<string, IUniMenu_ItemConfig>;
  logoSrc: any;
  leftNavPages: Map<string, INavConfig_FlowPage>;
  leftNavInitiallyClosed: boolean;

  constructor(props: IProps) {
    super(props);

    this.avatarMenuConfig = new Map()
      .set('myAccount', {
        textKey: navConfig.get(ENavPages.myAccount)!.nameKey,
        handleClick: () => portalRedirect(navConfig.get(ENavPages.myAccount)!.linkTo())
      })
      .set('logout', {
        textKey: 'logout',
        theme: 'secondary',
        handleClick: () => portalRedirect(logoutKey)
      });

    this.leftNavPages = this._calcLeftNavPages(props);
    this.leftNavInitiallyClosed = api.cacheImpl.get('leftNavOpen') === 'true';
    this.dealerSwitcherConfig = new Map();

    if (!props.authenticatedUser) {
      redirectToLogin();
    }
  }

  componentDidMount() {
    this._applyDealerSkin();
  }

  componentDidUpdate(prevProps: IProps) {
    // if we've calculated new permissions, we should refigure the leftNav pages
    if (prevProps.permissions !== this.props.permissions 
        || prevProps.authenticatedUser?.getDealer?.()?.id !== this.props.authenticatedUser?.getDealer?.()?.id) {
      this.leftNavPages = this._calcLeftNavPages(this.props);
    }
    // if we previously did not have an activeDealerId, but we do now
    // fetch the dealer skin to make sure we've always applied the correct skin.
    if (this.props.activeDealerId && !prevProps.activeDealerId) {
      this._applyDealerSkin();
    }

    if (this.props.canUserCreateDealer === undefined && !!this.props.authenticatedUser?.id) {
      // if it is not already set, we need to load in whether
      // the auth user has the ability to create dealers from the cache
      // it is saved in the cache on login. Will need to load from 
      // cache between logins on page refreshes.
      const userId = this.props.authenticatedUser?.id;
      const canCreateDealers = api.canUserCreateDealer(userId);
      this.props.updateCanUserCreateDealer(!!canCreateDealers);
    }

    // testing pruproses only -- load the mock default dealer data
    if (mockAuth && !this.props.activeDealerId) {
      const cachedMockDealerString = window.sessionStorage.getItem('mock-active-dealer');
      const cachedMockDealerString1 = api.cacheImpl.get('dealer');
      const mockDealer = JSON.parse(cachedMockDealerString ?? cachedMockDealerString1 ?? '{}');
      if (mockDealer?.id) {
        this.props.setActivePortalDealer(new DealerC(mockDealer));
      }
    } 
  }

  _applyDealerSkin = () => {
    if (this.props.dealerSkinningOffered) {
      this.props.getDealerSkin();
    }
  }
  

  _calcLeftNavPages = (props: IProps) => {
    return [...props.navigationConfig.keys()].reduce((map: Map<string, INavConfig_FlowPage>, navKey: string) => {
      const item: INavConfig_FlowPage | INavConfig_NestedPage = props.navigationConfig.get(navKey)!;
      if ((item as INavConfig_FlowPage).flowMatch) {
        // if something is restricted access, only add to the left nav if the user has elevated permissions
        if (!((item as INavConfig_FlowPage).evalHidden ? (item as INavConfig_FlowPage).evalHidden!() : false)) {
          map.set(navKey, (item as INavConfig_FlowPage)!);
        }
      }
      return map;
    }, new Map());
  }

  _determineActivePage(): INavConfig_FlowPage | INavConfig_NestedPage {
    var matchedNavConfig: any = {};
    [...this.props.navigationConfig.values()].some((navItem: INavConfig_FlowPage | INavConfig_NestedPage) => {
      matchedNavConfig = navItem;
      if ((navItem as INavConfig_FlowPage).flowMatch) {
        return (navItem as INavConfig_FlowPage).flowMatch.test(this.props.activePath);
      } else if ((navItem as INavConfig_NestedPage).pageMatch) {
        return (navItem as INavConfig_NestedPage).pageMatch.test(this.props.activePath);
      } else {
        return false;
      }
    });
    return (matchedNavConfig as INavConfig_FlowPage | INavConfig_NestedPage);
  }

  _avatarMenuToggle = () => {
    this.props.toggleAvatarMenu(!this.props.avatarMenuOpen);
  }

  _buildDealerSwitcherConfig = () => { 
    const switcherConfig = new Map();

    this.props.authenticatedUser?.getDealers?.().forEach((dealer: DealerC) => {
      switcherConfig.set(dealer.id!, {
        textKey: dealer?.name?.value,
        handleClick: () => this._chooseNewActiveDealer(dealer),
        disabled: dealer.id === this.props.activeDealer.id
      });
    });

    if (this.props.canUserCreateDealer) {
      switcherConfig.set('createNewDealer', {
        textKey: 'createNewDealer',
        handleClick: () => portalRedirect(navConfig.get(ENavPages.dealerCreate)?.linkTo([]) ?? ''),
        theme: 'secondary'
      });
    }

    return switcherConfig;
  }
  
  _dealerSwitcherToggle = () => {
    this.props.toggleDealerSwitcher(!this.props.dealerSwitcherOpen);
  }

  _chooseNewActiveDealer = (dealer: DealerC) => {
    this.props.setActivePortalDealer(dealer, true);
  }

  _getCurrentPageBreadcrumbs(location: any) {
    var activeNav: any;
    [...navConfig.values()].some((navItem: any, index: number) => {
      if (location && navItem.pageMatch && navItem.pageMatch!.test(location.pathname)) {
        activeNav = Object.assign({}, navItem, { active: true });
        return true;
      }
      return false;
    });
    if (activeNav && activeNav.breadcrumbs) {
      return [].concat(activeNav.breadcrumbs, activeNav);
    }
    return [];
  }

  _displayAlertHistory = () => {
    this.props.showAllAlerts();
  }

  _determineSubNavItems = (activePage: INavConfig_FlowPage | INavConfig_NestedPage): INavConfig_NestedPage[] => {
    // active page does not have breadcrumbs, then we're not in a nested page, so dont show any pagess
    if (!Array.isArray((activePage as INavConfig_NestedPage).breadcrumbs)) {
      return [];
    }
    return ([...navConfig.values()].filter((flowData: INavConfig_FlowPage | INavConfig_NestedPage, index: number): boolean => {
      if (!Array.isArray((flowData as INavConfig_NestedPage).breadcrumbs)) {
        return false;
      } else if ((flowData as INavConfig_NestedPage).breadcrumbs[0].nameKey === (activePage as INavConfig_NestedPage).breadcrumbs[0].nameKey) {
        // if the current page shares the same parent as the active page, then show it in this subnav
        return true;
      }
      return false;
    }) as INavConfig_NestedPage[]);
  }

  render() {

    this.dealerSwitcherConfig = this._buildDealerSwitcherConfig();

    if (this.props.render) {
      return this.props.render();
    }
    const logoImgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });

    const activePage = this._determineActivePage();
    var pathParams: string[] = [];
    if ((activePage as INavConfig_NestedPage).pageMatch) {
      pathParams = this.props.activePath.match((activePage as INavConfig_NestedPage).pageMatch) ? this.props.activePath.match((activePage as INavConfig_NestedPage).pageMatch)!.slice(1) : [];
    }

    // const currPageSubNavOptions: INavConfig_NestedPage[] = this._determineSubNavItems(activePage);
    const routes = [...this.props.navigationConfig.values()].map((navItem: INavConfig_FlowPage | INavConfig_NestedPage) => {
      const ComponentName: any = navItem.component;
      // TODO: There is a bug with connected-react-router not passing the correct match to the switch component
      // to get around this issue, we are passing in an extra unique key so that the route
      // recognizes when it matched AND the location is different from before.
      // After bug is fixed we should be able to remove the `-${props.activePath}` in the key below
      return (
        <Route path={navItem.linkTo()} exact={true} key={`route-${navItem.nameKey}-${this.props.activePath}`} children={(routeProps: any) => (
          <>
            {/* <UniConditionalRender visible={!!(navItem as INavConfig_NestedPage).breadcrumbs}>
              <UniBreadcrumbs
                // crumbs={this._getCurrentPageBreadcrumbs(routeProps.location)}
                // subNavOptions={currPageSubNavOptions}
                navConfig={navConfig}
                activePage={activePage}
                navigateTo={this.props.history.push.bind(this)}
                {...routeProps} />
            </UniConditionalRender> */}
            <ComponentName {...routeProps} />
          </>
        )} />
      );
    });

    // boolean to determine if we have everything needed to proceed loading the /portal pages
    let pendingPrerequisiteData = false;
    // first auth user data,
    if (!this.props.authenticatedUser) {
      pendingPrerequisiteData = true;
    }
    // we must have an active dealer set before we can render anything
    if (!this.props.activeDealerId) {
      pendingPrerequisiteData = true;
    }
    // make sure we have a dealer otherwise, should go to 
    if (!this.props.authenticatedUser?.getDealer?.()?.id) {
      pendingPrerequisiteData = true;
    }
    // second dealer skin (if required)
    if (this.props.dealerSkinningOffered && this.props.dealerSkinLoading) {
      pendingPrerequisiteData = true;
    }

    
    var brandLogo: string; // logo url
    if (this.props.darkModeActive) {
      // dark mode uses: a user specified dark mode logo or a user specificed light mode logo or a fallback to the partner configured dark mode logo
      brandLogo = firstTruthyOf(this.props.dealerSkinOverrides.logoDM, partnerConfig.assets.logoOnDark)
    } else {
      // light mode uses a user specified logo or the fallback partner logo
      brandLogo = firstTruthyOf(this.props.dealerSkinOverrides.logo, partnerConfig.assets.logoOnBackground);
    }

    return (
      <ScreenClassRender render={(screenClass: string) => (
        <>
          <UniConditionalRender visible={pendingPrerequisiteData}>
            <SplashContainer 
              match={this.props.match}
              history={this.props.history}
              withLoader={true}
              hideLogo={true} />
          </UniConditionalRender>
          {/* show the portal or show the splash screen while fetchinig prerequite data */}
          <UniConditionalRender visible={!pendingPrerequisiteData}>
            <section className="portal-container">
              <div className="portal-nav">
                <UniLeftNav
                  className={`portal-left-nav-dealer-id ${this.props.activeDealerId}`}
                  key={`${this.props.dealerSkinLoading}-${this.props.activeDealerId}`}
                  dynamicSkinConfig={this.props.dealerSkinOverrides}
                  activeNav={activePage}
                  initiallyClosed={this.leftNavInitiallyClosed}
                  onToggleOpenClose={(isOpen: boolean) => api.cacheImpl.set('leftNavOpen', isOpen)}
                  flowConfig={this.leftNavPages} 
                  contextIcon="locationCity"
                  contextSwitcherConfig={this.dealerSwitcherConfig}
                  activeContextKey={this.props.activeDealerId}
                  />
              </div>

              <div className="portal-main">
                <div className={classNames('left-of-secondary-nav', { 'room-for-secondary-nav': screenClass !== 'xs' })}>
                  <div className={classNames('portal-header', { 'room-for-mobile-nav': screenClass === 'xs' })}>
                    <Row>
                      <Col xs={10} sm={8} offset={{ xs: 2, sm: 0, md: 0, xl: 2 }} style={{ marginBottom: '6px' }}>
                        <UniImg className={logoImgClassName} textKey="partner logo" src={brandLogo} key={this.props.dealerSkinLoading}/>
                      </Col>


                      <Col xs={12} sm={16} xl={14}>
                        <UniConditionalRender visible={!!this.props.authenticatedUser.email}>
                          <UniAvatar
                            firstName={this.props.authenticatedUser?.getFirstName?.()}
                            lastName={this.props.authenticatedUser?.getLastName?.()}
                            username={this.props.authenticatedUser?.getUsername?.()}
                            handleClick={this._avatarMenuToggle}
                            namePosition="right"
                            nameVisible={screenClass !== 'xs' && screenClass !== 'sm'}>
                            <UniMenu
                              toggleMenuVisibility={this._avatarMenuToggle}
                              visible={this.props.avatarMenuOpen}
                              items={this.avatarMenuConfig}
                              invisibleBackdrop={true}
                              bordered={true}
                              theme="default" />
                          </UniAvatar>
                        </UniConditionalRender>

                        <UniButton
                          className="alert-history-btn top-nav-btn"
                          textKey="alertHistory"
                          icon="notifications"
                          hideText={true}
                          theme="inverted"
                          handleClick={this._displayAlertHistory}
                          disabled={this.props.numAlerts === 0}>
                          <UniConditionalRender visible={this.props.numAlerts > 0}>
                            <div className="alert-notice" />
                          </UniConditionalRender>
                        </UniButton>

                        <UniThemeToggle
                          className="top-nav-btn"
                          hideText={true}
                          onThemeChange={this.props.changeDarkModeTheme} />
                          
                      </Col>
                    </Row>
                  </div>

                  <Row>
                    <Col>
                      <UniTopNav
                        match={this.props.match}
                        pathParams={pathParams}
                        activePage={activePage}
                        initiallyClosed={true}
                        showTooltipOnChange={true}
                        navConfig={navConfig}
                        evalHiddenArgs={{}}
                        navigateTo={(to: string) => { portalRedirect(to); }} />
                    </Col>
                  </Row>

                  <div className="portal-content">
                    <Row nogutter>
                      <Col xl={20} offset={{ xl: 2 }}>
                        {/* passing in a custom location so that we can navigate in this nested switch statement as expected */}
                        <Switch location={Object.assign(this.props.location, { pathname: this.props.activePath })}>
                          {routes}
                          <Route component={SplashContainer} />
                        </Switch>
                      </Col>
                    </Row>
                  </div>

                </div>
                {/* <UniConditionalRender visible={screenClass !== 'xs'}>
                  <div className="portal-right-nav">
                    <UniRightNav
                      pathParams={pathParams}
                      activePage={activePage}
                      initiallyClosed={true}
                      showTooltipOnChange={true}
                      subNavOptions={currPageSubNavOptions} />
                  </div>
                </UniConditionalRender> */}
              </div>

            </section>
            <FooterContainer />

            <UniConditionalRender visible={this.props.createOrganizationModalOpen}>
              <OrganizationCreateContainer />
            </UniConditionalRender>
  
            <UniConditionalRender visible={this.props.enrollReaderModalOpen}>
              <ReaderEnrollContainer match={this.props.match} history={this.props.history} onClose={this._refreshReadersList}/>
            </UniConditionalRender>

            <UniConditionalRender visible={this.props.passwordEditModalOpen}>
              <ChangePasswordContainer />
            </UniConditionalRender>
  
            <UniConditionalRender visible={this.props.addPasswordModalOpen}>
              <AddPasswordEmailRequestContainer />
            </UniConditionalRender>

            <UniConditionalRender visible={this.props.inviteAdminModalOpen}>
              <AdminInviteContainer />
            </UniConditionalRender>
  
            <UniConditionalRender visible={this.props.adminPermissionManagerModalOpen}>
              <AdminPermissionsEditContainer />
            </UniConditionalRender>
  
            <UniConditionalRender visible={this.props.credentialCreateModalOpen}>
              <CredentialCreateContainer match={this.props.match} history={this.props.history} />
            </UniConditionalRender>
  
            <UniConditionalRender visible={this.props.addDealerCreditsModalOpen}>
              <DealerEditCreditsContainer />
            </UniConditionalRender>
            

            {/* Confirm dialog can appear anywhere in the portal,so we're including the markdown for it here rather than repeating it. */}
            <UniConditionalRender visible={this.props.confirmDialogOpen}>
              <UniConfirm {...this.props.confirmDialogConfig} />
            </UniConditionalRender>
          </UniConditionalRender>
        </>
      )} />
    )
  }
}

function mapStateToProps(state: any) {
  return {
    activeDealerId: state.portal.activeDealer?.id, // needs to be here
    activeDealer: state.portal.activeDealer,
    // is the user allowed to Create Dealer?
    canUserCreateDealer: state.oidcUser.canUserCreateDealer,
    authenticatedUser: state.authenticatedUser.currentUser,
    permissions: state.authenticatedUser.currentUser?.permissions,
    activePath: state.router.location.pathname,
    avatarMenuOpen: state.portal.avatarMenuOpen,
    dealerSwitcherOpen: state.portal.dealerSwitcherOpen,
    numAlerts: state.alerts.numAlerts,
    confirmDialogConfig: state.confirm.config,
    confirmDialogOpen: state.confirm.open,
    darkModeTheme: state.portal.darkModeTheme,
    dealerSkinOverrides: state.dealerSkin.skin,
    dealerSkinLoading: state.dealerSkin.loading,
    minimized: false,
    // modals and windows
    passwordEditModalOpen: state.changePasswordForm.open,
    addPasswordModalOpen: state.addPasswordModal?.open,
    createOrganizationModalOpen: state.createOrg.modalOpen,
    enrollReaderModalOpen: state.readerEnrollmentForm.modalOpen,
    credentialCreateModalOpen: state.newCredential.modalOpen,
    addDealerCreditsModalOpen: state.dealer.scratch.modalOpen,
    inviteAdminModalOpen: state.createAdmin.modalOpen,
    adminPermissionManagerModalOpen: state.adminDetails.permissionsEditor.modalOpen,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getCurrentUser: attemptRetrieveAuthUser,
  updateCanUserCreateDealer,
  getDealerSkin: attemptGetDealerSkinDetails,
  setActivePortalDealer,
  toggleDealerSwitcher,
  toggleAvatarMenu,
  showAllAlerts,
  redirectToLogin,
  changeDarkModeTheme: setDarkModeTheme
}, dispatch);

export default PartnerCustomizations(connect(mapStateToProps, mapDispatchToProps)(Portal), 
  { componentName: 'Portal', unauthenticated: false, waitForToken: false  }
)
