import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniKeyVal,
  UniConditionalRender,
  UniLocalize,
  EOperationCodesC,
  ES10nSettings,
  Device,
  CredentialC,
  IPartnerCredImpls,
} from '@unikey/unikey-commons/release/comm';

import {
  PartnerCustomizations, IPartnerCustomizations,
  attemptRetrieveCredentialById,
  s10n, canI,
  navConfig
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  devices: Device[],
  deviceData?: Device,
  credentialData: CredentialC,
  loading: boolean,
  partnerCredImpls: IPartnerCredImpls,
  getCredentialById: (id: string) => void
}

class DeviceDetailsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  // used only within the credential details view sometimes,
  // so no data fetching is required unless we have a mismatch 
  // between desired cred and current cred path.
  componentDidMount() {
    if (this.props.match.params.credentialId !== this.props.credentialData?.id) {
      this.props.getCredentialById(this.props.match.params.credentialId);
    }
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    return (
      <section className='deviceDetails-container'>
        <Row>
          <Col>
            <h3 className="device-details-title"><UniLocalize translate="deviceDetails" /></h3>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <UniKeyVal
              label={`deviceData-1`}
              showLoader={!this.props.deviceData?.id}
              preventEdit={true}
              stacked={true}
              fields={[
                {
                  keyName: 'deviceId',
                  value: `${this.props.deviceData?.id}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'deviceType',
                  value: `${this.props.deviceData?.deviceType}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'family',
                  value: `${this.props.deviceData?.family}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'make',
                  value: `${this.props.deviceData?.make}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'model',
                  value: `${this.props.deviceData?.model}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'redeemedOn',
                  value: `${this.props.deviceData?.lastRedeemedOn}`,
                  type: 'date',
                  disabled: true
                }
              ]} />
          </Col>

          <Col md={12}>
            <UniKeyVal
              label={`deviceData-2`}
              showLoader={!this.props.deviceData?.id}
              preventEdit={true}
              stacked={true}
              fields={[
                {
                  keyName: 'os',
                  value: `${this.props.deviceData?.os}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'osVersion',
                  value: `${this.props.deviceData?.osVersion}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'sdkVersion',
                  value: `${this.props.deviceData?.sdkVersion}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'applicationName',
                  value: `${this.props.deviceData?.applicationName}`,
                  type: 'string',
                  disabled: true
                },
                {
                  keyName: 'applicationVersion',
                  value: `${this.props.deviceData?.applicationVersion}`,
                  type: 'string',
                  disabled: true
                }
              ]} />
          </Col>
        </Row>

      </section>
    )
  }
}

function mapStateToProps(state: any, ownProps: IProps) {
  const foundDevices = state.credentialDetails.credentialDevices;
  const desiredDeviceId = ownProps.match.params.deviceId ?? foundDevices?.[0]?.id;
  return {
    credentialData: state.credentialDetails.credentialData,
    devices: foundDevices,
    deviceData: foundDevices?.filter((d: Device) => d.id === desiredDeviceId)?.[0],
  }
}

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => bindActionCreators({
  getCredentialById: attemptRetrieveCredentialById.bind(null, ownProps.partnerCredImpls),
}, dispatch)

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(injectIntl(DeviceDetailsContainer));
export default PartnerCustomizations(connectedComponent, { componentName: 'DeviceDetails' })
