import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Container, Row, Col } from 'react-grid-system'

import {
  UniKeyVal,
  UniLocalize,
} from '@unikey/unikey-commons/release/comm';

import { privacyPolicyDocument, termsOfServiceDocument } from '@alias-environment';

import {
  PartnerCustomizations, IPartnerCustomizations
} from '../internal'

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  loading: boolean,
  history: any,
}

class LegalContainer extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <section className='access-container'>
        <section className='legal-container'>
          <Container>

            <Row>
              <Col xs={20} sm={16} offset={{ sm: 4, xs: 2 }}>
                <h3 className="legal-center-title"><UniLocalize translate="legalCenter" /></h3>
              </Col>
            </Row>

            <Row>
              <Col xs={20} sm={16} offset={{ sm: 4, xs: 2 }}>
                <UniKeyVal
                  label="legal-privacy"
                  stacked={true}
                  showLoader={false}
                  primaryStateButtonSet={[
                    {
                      textKey: 'viewDocument',
                      icon: 'launch',
                      clickHandler: () => window.location.href = window.location.href.split('/#/')[0] + privacyPolicyDocument
                    }
                  ]}
                  secondaryStateButtonSet={[]}
                  preventEdit={false}
                  fields={[
                    {
                      keyName: 'privacyPolicy',
                      value: '_emptyString',
                      type: 'string',
                    }
                  ]} />
              </Col>

              <Col xs={20} sm={16} offset={{ sm: 4, xs: 2}}>
                <UniKeyVal
                  label="legal-terms"
                  stacked={true}
                  primaryStateButtonSet={[
                    {
                      textKey: 'viewDocument',
                      icon: 'launch',
                      clickHandler: () => window.location.href = window.location.href.split('/#/')[0] + termsOfServiceDocument
                    }
                  ]}
                  secondaryStateButtonSet={[]}
                  preventEdit={false}
                  fields={[
                    {
                      keyName: 'termsOfUse',
                      value: '_emptyString',
                      type: 'string'
                    }
                  ]} />
              </Col>
            </Row>

          </Container>
        </section>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {

  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch);

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(LegalContainer, { forwardRef: true })
    ), { componentName: 'Legal', unauthenticated: true })
