import {
  IPaginationQueryBuilderParamsC,
  IUniTable_Filter,
  IUniTable_Sort,
  IUniTable_UpdatePaginationSummary
} from '@unikey/unikey-commons/release/comm'

import { 
  api
} from '../internal'


const pageSizeCacheKey = 'queryParamsPageSize';
export const setPageSizeInCache = (pageSize?: number) => {
  if (Number.isInteger(pageSize)) {
    api.cacheImpl.set(pageSizeCacheKey, pageSize);
  }
  return;
}

export const getPageSizeFromCache = (): number => {
  const pageSize = api.cacheImpl.get(pageSizeCacheKey);
  const numericPageSize = Number(pageSize);
  return numericPageSize ?? undefined;
}


export const initialPageSize = getPageSizeFromCache() || 10; // default page size

export const getTableSortDirection = (qps: IPaginationQueryBuilderParamsC): 'asc'| 'desc' => {
  if (qps && qps.sortField) {
    return qps.reverse ? 'asc' : 'desc';
  }
  return 'desc';
}


const getSearchFieldName = (currFilter: IUniTable_Filter): string => {
  if ((currFilter.filterFieldKeys?.length ?? -1) > 0) {
    return `${currFilter.filterFieldKeys?.[0]}`;

  } else {
    // no filterFieldKeys, use nameKey
    return currFilter.nameKey ?? '';
  }
}

export const getTableParamsFromUpdate = (update: IUniTable_UpdatePaginationSummary): IPaginationQueryBuilderParamsC => {
  const params: IPaginationQueryBuilderParamsC = {};
  // defaults filters
  params.searchField = '';
  params.searchText = '';

  // make sure to update the name param even if the string is empty
  if (update.filters) {
    const firstFilter: IUniTable_Filter = update.filters[0];
      
    // normal filter
    if (firstFilter?.value) {
      params.searchText = `in:${firstFilter?.value}`
      params.searchField = getSearchFieldName(firstFilter);
    }
  }

  if (update.sorts) {
      const firstSort: IUniTable_Sort = update.sorts[0]; 
      
      if (firstSort) {
        params.sortField = firstSort.field;
        params.reverse = firstSort.direction === 'asc';
      }
  }

  if (update.currPage) {
    params.pageNum = update.currPage;
  }

  if (update.pageSize) {
    params.pageSize = Number(update.pageSize);
  }
  return params;
}

export type TTableUpdateFunc = (update: IUniTable_UpdatePaginationSummary) => void;
export const buildTableUpdateFunc = (
  fetchTableData: (queryParams: IPaginationQueryBuilderParamsC) => Promise<void>,
  updateTableMeta: (metaSummary: IUniTable_UpdatePaginationSummary) => void, 
  updateQueryParams?: (params: IPaginationQueryBuilderParamsC) => void,
  ): TTableUpdateFunc  => {
    return (update: IUniTable_UpdatePaginationSummary) => {
      if (updateQueryParams) {
        const params = getTableParamsFromUpdate(update);
        updateTableMeta(update);
        updateQueryParams(params);
        return fetchTableData(params);
      }
    }
  };