import { ApiReduxAction } from '@unikey/unikey-commons/release/comm'
import {
  api,
  IExposeRedux
} from '../../internal'

export enum subscriptionActions {

  RETRIEVE_SUBSCRIPTION_MODELS_REQUEST = 'RETRIEVE_SUBSCRIPTION_MODELS_REQUEST',
  RETRIEVE_SUBSCRIPTION_MODELS_SUCCESS = 'RETRIEVE_SUBSCRIPTION_MODELS_SUCCESS',
  RETRIEVE_SUBSCRIPTION_MODELS_FAILURE = 'RETRIEVE_SUBSCRIPTION_MODELS_FAILURE',
}

// Subscription Setup
const getAvailableSubscriptionModels = new ApiReduxAction(api.subs, {
  request: { type: subscriptionActions.RETRIEVE_SUBSCRIPTION_MODELS_REQUEST },
  success: { type: subscriptionActions.RETRIEVE_SUBSCRIPTION_MODELS_SUCCESS },
  failure: {
    type: subscriptionActions.RETRIEVE_SUBSCRIPTION_MODELS_FAILURE,
    title: 'failedToRetrieveSubscriptionModelSummary'
  }
}, (dux: IExposeRedux) => {
  return api.subs.getAvailableSubscriptionModels.bind(api.subs);
});
export const attemptRetrieveAvailableSubscriptionModels = getAvailableSubscriptionModels.go;

