import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'

import { UniOverlapButton, UniKeyVal, UniLocalize } from '@unikey/unikey-commons/release/comm';

import { PartnerCustomizations, IPartnerCustomizations } from '../internal'
import { buildId, cdpVersion, commonsVersion, releaseId } from '@alias-environment';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {

}

class VersionContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    if (this.props.render) {
      return this.props.render()
    }
    return (
      <>
        <Row>
          <Col xs={24}>
            <h3 className="version-details"><UniLocalize translate="currentVersionInfo" /></h3>
          </Col>

          <Col lg={16}>
            <UniKeyVal
              label="currentVersionInfo"
              preventEdit={true}
              fields={[
                {
                  keyName: 'releaseId',
                  value: releaseId,
                  type: 'string',
                },
                {
                  keyName: 'buildId',
                  value: buildId,
                  type: 'string',
                },
                {
                  keyName: 'cdpVersion',
                  value: cdpVersion,
                  type: 'string',
                },
                {
                  keyName: 'commonsVersion',
                  value: commonsVersion,
                  type: 'string',
                }
              ]} />
          </Col>
        </Row>
      </>
    )
  }
}

function mapStateToProps(state: any) {
  return {}
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({

}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(VersionContainer)
  ), { componentName: 'Version', unauthenticated: true })
