
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniTable,
  UniIcon,
  UniConditionalRender,
  UniLocalize,
  UniInput,
  UniKeyVal,
  Editable,
  IUniTable_Column,
  IPaginatedResponseC,
  IPaginationQueryBuilderParamsC,
  ICredentialC,
  CredentialC,
  DealerC,
  OrganizationC,
  IUniTable_UpdatePaginationSummary,
  IUniTable_Filter,
  IUniTable_Sort,
  IUniTable_PaginationSummary,
  IUniConfirm_Config,
  IPartnerCredImpls,
  WiegandCredentialC,
  UniKeyV1CredentialC,
  RawCredentialC,
  ECredentialStatus,
  ECredentialType,
  EOperationCodesC,
  ES10nSettings,
  emailV10n
} from '@unikey/unikey-commons/release/comm';

import {
  toggleCreateCredentialModal,
  attemptRetrieveOrgDetails,
  attemptRetrieveDealerDetails,
  updateCredentialListQueryParams,
  attemptRetrieveCredentialsList,
  updateCredentialsTableMeta,
  openConfirmModal,
  closeConfirmModal,
  attemptRevokeCredential,
  attemptDisableCredential,
  attemptEnableCredential,
  attemptReissueCredential,
  attemptCopyCredential,
  attemptDeleteCredential,
  attemptToggleCredentialPermissions,
  handleCopyCredToEmailChange,

  CredentialCreateContainer,
  navConfig, ENavPages,
  portalRedirect,
  s10n, canI,
  getTableSortDirection, getTableParamsFromUpdate,
  buildTableUpdateFunc, TTableUpdateFunc,
  PartnerCustomizations, IPartnerCustomizations,
  IGetPartnerCredsActionParams,
  ICopyCredentialActionParams
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  credentialData: IPaginatedResponseC<ICredentialC, CredentialC>,
  credentialListQueryParams: IPaginationQueryBuilderParamsC,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  paginationSummary: IUniTable_PaginationSummary,
  listLoading: boolean,
  orgDetails: OrganizationC,
  orgLoading: boolean,
  dealerData: DealerC,
  copyCredToEmail: Editable<string>,

  partnerDefaultCredentialType: ECredentialType,
  partnerCredImpls: IPartnerCredImpls,

  preventToggleUserInstaller?: boolean,
  permissionToCreateCredential: boolean,
  permissionToDisableCredential: boolean,
  permissionToEnableCredential: boolean,
  permissionToRevokeCredential: boolean,
  permissionToReissueCredential: boolean,
  permissionToDeleteCredential: boolean,
  permissionToUpdateCredential: boolean,
  permissionToRedeemCredits: boolean,
  subFreeInstallerCredentials: boolean,
  subAllowEnabledCredReissue: boolean,
  subCreditCountRelevant: boolean,

  updateCopyCredentialTo(email: Editable<string>): void,
  toggleCredentialPermissions(existingCredential: CredentialC): Promise<void>,
  copyCredential(copyActionParams: ICopyCredentialActionParams): Promise<CredentialC>,
  revokeCredential(credId: string): Promise<void>,
  reissueCredential(credId: string): Promise<void>,
  enableCredential(credId: string): Promise<void>,
  disableCredential(credId: string): Promise<void>,
  deleteCredential(credId: string): Promise<void>,
  updateQueryParams?(params: IPaginationQueryBuilderParamsC): void,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  getAccountDetails(): void,
  toggleCreateModal(): void,
  getOrgDetails(orgId: string): Promise<void>,
  getOrgCredentials(): Promise<void>,
  closeConfirmModal(): void,
  openConfirmDialog(dialogConfig: IUniConfirm_Config): void,
}

class CredentialListContainer extends Component<IProps> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);

    this._updateTable = buildTableUpdateFunc(
      props.getOrgCredentials,
      props.updateTableMeta,
      props.updateQueryParams
    )
  }

  componentDidMount() {
    if (Object.keys(this.props.dealerData).length === 0) {
      this.props.getAccountDetails();
    }
    this.props.getOrgDetails(this.props.match.params.organizationId);
    this.props.getOrgCredentials();
  }

  _refreshCredentialsAndCreditSummary = () => {
    // get org credentials and org details 
    // credit summary counts comme from the org details requeust
    this.props.getOrgCredentials().then(() => {
      this.props.getOrgDetails(this.props.match.params.organizationId);
    });
  }

  _revokeCredentialAndRefreshList = (credId: string) => {
    return this.props.revokeCredential(credId)
      .then(this._refreshCredentialsAndCreditSummary);
  }

  _copyCredentialAndRefreshList = (credId: string, emailTo?: string) => {
    return this.props.copyCredential({ credId, email: emailTo })
      .then(this._refreshCredentialsAndCreditSummary);
  }

  _enableCredentialAndRefreshList = (credId: string) => {
    return this.props.enableCredential(credId)
      .then(this._refreshCredentialsAndCreditSummary);
  }

  _disableCredentialAndRefreshList = (credId: string) => {
    return this.props.disableCredential(credId)
      .then(this._refreshCredentialsAndCreditSummary);
  }

  _deleteCredentialAndRefreshList = (credId: string) => {
    return this.props.deleteCredential(credId)
      .then(this._refreshCredentialsAndCreditSummary);
  }

  _toggleCredentialPermissionsAndRefreshList = (cred: CredentialC) => {
    return this.props.toggleCredentialPermissions(cred)
      .then(this._refreshCredentialsAndCreditSummary);
  }

  _getAppropriateCopyMessage = (cred: CredentialC): string | undefined => {
    var copyCredSecondaryMessage;
    if (this.props.subCreditCountRelevant) {
      copyCredSecondaryMessage = 'credentialActionWillConsume1Credit';
      if (this.props.subFreeInstallerCredentials && cred.isInstaller) {
        // this credential does not consume a credit since it is an installer credential 
        // inside a subscription model that allows free installer creds 
        copyCredSecondaryMessage = 'installerCredentialsConsume0Credits';
      }
    }
    return copyCredSecondaryMessage;
  }

  _getAppropriateReissueMessage = (cred: CredentialC) => {
    var reissueSecondaryMessage;
    if (this.props.subCreditCountRelevant) {
      reissueSecondaryMessage = 'credentialActionWillConsume1Credit';
      if (this.props.subFreeInstallerCredentials && cred.isInstaller) {
        // this credential does not consume a credit since it is an installer credential 
        // inside a subscription model that allows free installer creds 
        reissueSecondaryMessage = 'installerCredentialsConsume0Credits';
      }
      if ((this.props.subAllowEnabledCredReissue && cred.status === ECredentialStatus.enabled) 
        || cred.status === ECredentialStatus.invited) {
        // if we're doing a multi device reissue, it should be not consume a credit
        reissueSecondaryMessage = 'credentialActionWillConsume0Credits';
      }
    }
    return reissueSecondaryMessage;
  }

  _buildColumnsAndActions() {
    const orgAvailableCredits = this.props.orgDetails?.getAvailableCredits?.();
    const columns = new Map<string, IUniTable_Column>()
      .set('email', {
        nameKey: 'email',
        isSortable: true,
        sortFields: ['email'],
        isPrimaryFilter: true,
        size: 6,
      })
      .set('facilityCode', {
        nameKey: 'facility',
        isSortable: false,
        size: 2,
        template: (rowItem: CredentialC) => rowItem.facilityCode ? Number(rowItem.facilityCode) : (<i><UniLocalize translate='_notApplicable' /></i>)
      })
      .set('cardNumber', {
        nameKey: 'cardNumber',
        isSortable: false,
        size: 4,
        template: (rowItem: CredentialC) => rowItem.cardNumber ? rowItem.cardNumber : (<i><UniLocalize translate='_notApplicable' /></i>)
      })
      .set('type', {
        nameKey: 'format',
        isSortable: false,
        type: 'enum',
        enumType: ECredentialType,
        size: 4,
      })
      .set('status', {
        nameKey: 'status',
        isSortable: true,
        sortFields: ['status'],
        type: 'enum',
        enumType: ECredentialStatus,
        size: 4,
      })
      .set('installerIndicator', {
        nameKey: '_emptyString',
        isSortable: false,
        size: 2,
        template: (rowItem: CredentialC) => rowItem.label === 'installer' ? (<i><UniIcon size="sm" name="build" tooltipTextKeys={['installer']} /></i>) : <></>
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        size: 2,
        collapsed: true
      });

    const actions = new Map()
      .set('view', {
        nameKey: 'view',
        icon: 'removeRedEye',
        isDefaultAction: true,
        func: (rowItem: CredentialC) => portalRedirect(navConfig.get(ENavPages.credentialDetails)!.linkTo([this.props.match.params.organizationId, rowItem.id])!),
      })
      .set('copy', {
        nameKey: 'copy',
        icon: 'contentCopy',
        func: (rowItem: CredentialC) => this.props.openConfirmDialog({
          titleKey: 'copy',
          titleIcon: 'contentCopy',
          messageKeys: ['youAreAboutToCopyThisCredential', this._getAppropriateCopyMessage(rowItem), 'areYouSure'],
          confirmTextKey: 'copy',
          cancelHandler: this.props.closeConfirmModal,
          customContentRender: () => (
            <UniInput
              editable={new Editable({ value: rowItem.email })}
              type="string"
              placeholderKey={rowItem.email}
              labelKey="copyCredentialTo"
              handleUpdate={(email: Editable<string>) => this.props.updateCopyCredentialTo(email)}
              validateOnInitialRender={true}
              validations={[emailV10n]} />
          ),
          confirmDisabled: () => false, // TODO: !this.props.copyCredToEmail.valid,
          confirmHandler: () => {
            this._copyCredentialAndRefreshList(rowItem.id, this.props.copyCredToEmail.value),
              this.props.closeConfirmModal()
          }
        }),
        evalVisible: (rowItem: CredentialC) => !!this.props.permissionToReissueCredential,
        evalDisabled: (rowItem: CredentialC) => orgAvailableCredits === 0,
      })
      .set('reissue', {
        nameKey: 'reissue',
        icon: 'removeRedEye',
        func: (rowItem: CredentialC) => this.props.openConfirmDialog({
          titleKey: 'reissue',
          titleIcon: 'send',
          messageKeys: ['reissueCredentialMessage', this._getAppropriateReissueMessage(rowItem), 'areYouSure'],
          confirmTextKey: 'reissue',
          cancelHandler: this.props.closeConfirmModal,
          confirmHandler: () => {
            this.props.reissueCredential(rowItem.id),
              this.props.closeConfirmModal()
          }
        }),
        evalVisible: (rowItem: CredentialC) => !(!this.props.permissionToReissueCredential || this.props.subAllowEnabledCredReissue ? false : rowItem.status > ECredentialStatus.invited),
        evalDisabled: (rowItem: CredentialC) => this.props.subAllowEnabledCredReissue ? false : rowItem.status !== ECredentialStatus.invited,
      })
      .set('disable', {
        nameKey: 'disable',
        icon: 'removeRedEye',
        func: (rowItem: CredentialC) => this._disableCredentialAndRefreshList(rowItem.id),
        // can only mark as disabled, if the credential is currently enabled
        evalDisabled: (rowItem: CredentialC) => rowItem.status !== ECredentialStatus.enabled,
        evalVisible: (rowItem: CredentialC) => this.props.permissionToDisableCredential && rowItem.status === ECredentialStatus.enabled
      })
      .set('enable', {
        nameKey: 'enable',
        icon: 'removeRedEye',
        func: (rowItem: CredentialC) => this._enableCredentialAndRefreshList(rowItem.id),
        // can only mark as enabled, if the credential is currently disabled
        evalVisible: (rowItem: CredentialC) => rowItem.status === ECredentialStatus.disabled
      })
      .set('revoke', {
        nameKey: 'revoke',
        icon: 'removeRedEye',
        theme: 'error',
        evalVisible: (rowItem: CredentialC) => this.props.permissionToRevokeCredential,
        func: (rowItem: CredentialC) => this.props.openConfirmDialog({
          titleKey: 'revokeCredential',
          titleIcon: 'delete',
          messageKeys: ['youAreAboutToRevokeThisCredential', 'areYouSure'],
          confirmTextKey: 'revoke',
          cancelHandler: this.props.closeConfirmModal,
          confirmHandler: () => {
            this._revokeCredentialAndRefreshList(rowItem.id),
              this.props.closeConfirmModal()
          }
        })
      })
      .set('delete', {
        nameKey: 'delete',
        icon: 'delete',
        theme: 'error',
        evalVisible: (rowItem: CredentialC) => this.props.permissionToDeleteCredential && rowItem.status === ECredentialStatus.revoked,
        func: (rowItem: CredentialC) => this.props.openConfirmDialog({
          titleKey: 'deleteCredential',
          titleIcon: 'deleteForever',
          messageKeys: ['youAreAboutToPermanentlyDeleteThisCredential', 'areYouSure'],
          confirmTextKey: 'delete',
          cancelHandler: this.props.closeConfirmModal,
          confirmHandler: () => {
            this._deleteCredentialAndRefreshList(rowItem.id),
              this.props.closeConfirmModal()
          }
        })
      })
      .set('addInstallerPermissions', {
        nameKey: 'addInstallerPermissions',
        icon: '',
        func: (rowItem: CredentialC) => this._toggleCredentialPermissionsAndRefreshList(rowItem),
        evalVisible: (rowItem: CredentialC) => this.props.permissionToUpdateCredential && !rowItem.isInstaller && !this.props.preventToggleUserInstaller
      })
      .set('removeInstallerPermissions', {
        nameKey: 'removeInstallerPermissions',
        icon: '',
        func: (rowItem: CredentialC) => this._toggleCredentialPermissionsAndRefreshList(rowItem),
        evalVisible: (rowItem: CredentialC) => this.props.permissionToUpdateCredential && rowItem.isInstaller && !this.props.preventToggleUserInstaller,
      })

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();
    const remainingAvailCreditsInOrg = this.props.orgDetails?.getAvailableCredits?.();
    return (
      <>
        <section className='credentialList-container'>
          <Row>
            <Col>
              <h3 className="org-credential-summary"><UniLocalize translate="credentialSummary" /></h3>
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <UniKeyVal
                key={`claimed-count-${this.props.orgDetails.creditsClaimed}`}
                label="org-issued-credentials"
                stacked={true}
                showLoader={!this.props.orgDetails.name || this.props.orgLoading}
                preventEdit={true}
                fields={[
                  {
                    keyName: 'credentialsIssued',
                    value: '' + this.props.orgDetails.creditsClaimed,
                    type: 'string',
                    keyInfo: {
                      icon: 'infoOutline',
                      textKeys: ['credentialsIssued', '_orgCredentialsIssuedExplanation'],
                    }
                  }
                ]} />
            </Col>

            <UniConditionalRender visible={this.props.subCreditCountRelevant}>
              <Col sm={12}>
                <UniKeyVal
                  key={`credits-claimed-${remainingAvailCreditsInOrg}`}
                  label="org-available-credits"
                  stacked={true}
                  showLoader={!this.props.orgDetails.name || this.props.orgLoading}
                  preventEdit={true}
                  fields={[
                    {
                      keyName: 'availableCredits',
                      value: '' + (remainingAvailCreditsInOrg !== null ? remainingAvailCreditsInOrg : this.props.dealerData.unallocatedCredentials),
                      type: 'string',
                      keyInfo: {
                        icon: (remainingAvailCreditsInOrg !== null && remainingAvailCreditsInOrg === 0) || (remainingAvailCreditsInOrg === null && this.props.dealerData.unallocatedCredentials === 0) ? 'warning' : 'infoOutline',
                        textKeys: [
                          'availableCredits',
                          remainingAvailCreditsInOrg !== null && remainingAvailCreditsInOrg === 0 ? '_zeroOrgCreditsRemainingExplanation' :
                            remainingAvailCreditsInOrg === null && this.props.dealerData.unallocatedCredentials > 0 ? '_unlimitedOrgAllocationTypeExplanation' :
                              remainingAvailCreditsInOrg === null && this.props.dealerData.unallocatedCredentials === 0 ? 'dealerHasInsufficientCreditsContactYourDealerAdministrator' : '_availableOrgCreditsExplanation'
                        ],
                      }
                    }
                  ]} />
              </Col>
            </UniConditionalRender>
          </Row>

          <UniTable
            searchable={true}
            titleKey="credentialsList"
            postTitleKey={this.props.orgDetails.name ? ` - ${this.props.orgDetails.name}` : ''}
            createButtonTextKey="credential"
            handleUpdate={this._updateTable}
            handleCreateClick={this.props.permissionToCreateCredential ? this.props.toggleCreateModal : undefined}
            createDisabled={remainingAvailCreditsInOrg === 0 || (remainingAvailCreditsInOrg === null && this.props.dealerData.unallocatedCredentials === 0)} // should be disabled only on 0 remaining or unlimited org with no dealer unallocated remaining
            createDisabledReasonKeys={[
              remainingAvailCreditsInOrg !== null ? '_zeroOrgCreditsRemainingExplanation' : 'dealerHasInsufficientCreditsContactYourDealerAdministrator'
            ]}
            data={this.props.credentialData?.models ?? []} 
            columnConfig={columns}
            actionsConfig={actions}
            activeSorts={this.props.appliedSorts}
            activeFilters={this.props.appliedFilters}
            paginationSummary={this.props.paginationSummary}
            showLoader={this.props.listLoading} />
        </section>
      </>
    )
  }
}

function mapStateToProps(state: any, ownProps: IProps) {
  return {
    dealerData: state.dealer.dealerData,
    credentialData: state.credentials.data,
    credentialListQueryParams: state.credentials.queryParams,
    appliedFilters: state.credentials.tableFilters,
    appliedSorts: state.credentials.tableSorts,
    paginationSummary: state.credentials.paginationSummary,
    listLoading: state.credentials.loading,
    orgDetails: state.orgDetails.origOrg,
    orgLoading: state.orgDetails.loading,
    copyCredToEmail: state.credentialDetails.copyToEmail,

    // permissions
    permissionToRedeemCredits: canI(EOperationCodesC.RedeemCredits, state.dealer.dealerData.id),
    permissionToCreateCredential: canI(EOperationCodesC.CreateCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId),
    permissionToDisableCredential: canI(EOperationCodesC.DisableCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId),
    permissionToEnableCredential: canI(EOperationCodesC.EnableCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId),
    permissionToRevokeCredential: canI(EOperationCodesC.DeleteCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId), // yes its a mismatched name - operation
    permissionToReissueCredential: canI(EOperationCodesC.CreateCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId), // yes its a mismatched name - operation
    permissionToDeleteCredential: canI(EOperationCodesC.DeleteCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId),
    permissionToUpdateCredential: canI(EOperationCodesC.UpdateCredential, state.dealer.dealerData.id, ownProps.match.params.organizationId),
    // subscription
    subDisplayCouponRedemption: s10n(ES10nSettings.CouponRedemption),
    subDisplayCredCount: s10n(ES10nSettings.CredCountRelevant),
    subAllowEnabledCredReissue: s10n(ES10nSettings.MultiDeviceCredentials),
    subFreeInstallerCredentials: s10n(ES10nSettings.FreeInstallerCredentials),
    subCreditCountRelevant: s10n(ES10nSettings.CredCountRelevant)
  }
}

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => {
  const partnerCredActionParams: IGetPartnerCredsActionParams = {
    partnerCredImpls: ownProps.partnerCredImpls,
    orgId: ownProps.match.params.organizationId
  };

  return bindActionCreators({
    toggleCreateModal: toggleCreateCredentialModal,
    getOrgDetails: attemptRetrieveOrgDetails,
    getAccountDetails: attemptRetrieveDealerDetails,
    closeConfirmModal,
    openConfirmDialog: openConfirmModal,
    openDeleteCredentialConfirmDialog: openConfirmModal,
    updateCopyCredentialTo: handleCopyCredToEmailChange,

    deleteCredential: attemptDeleteCredential,
    reissueCredential: attemptReissueCredential,
    copyCredential: attemptCopyCredential,
    revokeCredential: attemptRevokeCredential,
    enableCredential: attemptEnableCredential,
    disableCredential: attemptDisableCredential,
    toggleCredentialPermissions: attemptToggleCredentialPermissions,
    
    getOrgCredentials: attemptRetrieveCredentialsList.bind(null, partnerCredActionParams),
    updateQueryParams: updateCredentialListQueryParams,
    updateTableMeta: updateCredentialsTableMeta,
  }, dispatch)
}

export default PartnerCustomizations(connect(mapStateToProps, mapDispatchToProps)(injectIntl(CredentialListContainer)), { componentName: 'CredentialList' })