import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniImg,
  UniInput,
  UniSteps,
  UniLocalize,
  UniWorkflow,
  UniOverlapGroup,
  UniOverlapButton,
  UniConditionalRender,
  Editable,
  IUniSteps_StepConfig,
  IMultiInputUpdate,
} from '@unikey/unikey-commons/release/comm';

import {
  toggleDealerCreditsModal,
  updateScratchValues,
  attemptRedeemScratchCard,
  changeCredentialRedeemStepTo,
  inputInitializer,
  PartnerCustomizations, IPartnerCustomizations
} from '../internal';

import partnerConfig from '@alias-current-partner-customizations';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,
  serialNumber: Editable,
  authenticationNumber: Editable,
  currentStepIndex: number,
  loading?: boolean,

  toggleDealerCreditsModal(): void,
  redeemScratchCard(): Promise<void>,
  updateScratchValues(scratchChanges: IMultiInputUpdate): void,
  clearScratchCard(): void,
  changeWorkflowStep(stepTo: number): void,
}

class DealerEditCreditsContainer extends Component<IProps> {
  steps: IUniSteps_StepConfig[];

  constructor(props: IProps) {
    super(props);

    this.steps = [
      { nameKey: 'serialNumber' },
      { nameKey: 'authenticationCode' }
    ];
  }

  _redeemAndReload = () => {
    this.props.redeemScratchCard();
  }

  _updateSerial = (serialChange: Editable): void => {
    return this.props.updateScratchValues({ serial: serialChange });
  }

  _updateAuth = (authChange: Editable): void => {
    return this.props.updateScratchValues({ auth: authChange });
  }

  render() {
    if (this.props.render) {
      return this.props.render.apply(this);
    }
    return (
      <section className='dealer-redeem-credits-container'>
        <UniWorkflow 
          titleKey="redeemDealerCredits"
          handleClose={this.props.toggleDealerCreditsModal} 
          inModal={true}>
          <UniSteps
            steps={this.steps}
            activeStepIndex={this.props.currentStepIndex}
            allStepsUnlocked={false}
            handleStepChange={(val: number) => this.props.changeWorkflowStep(val)} />

          {/* First Step - Serial Number */}
          <UniConditionalRender visible={this.props.currentStepIndex === 0}>

            <p><UniLocalize translate="_enterSerialNumberMessage" /></p>

            <UniConditionalRender visible={!!partnerConfig.assets.scratchCardFront}>
              <UniImg
                className="scratch-card-img"
                src={partnerConfig.assets.scratchCardFront}
                textKey="imageOfScratchCardFront" />
            </UniConditionalRender>

            <UniInput
              editable={this.props.serialNumber}
              placeholderKey="serialNumber"
              labelKey="serialNumber"
              handleUpdate={this._updateSerial}
              focusOnInitialRender={true}
              validations={[
                {
                  validationType: 'regex',
                  regCheck: /^\d{10}$/,
                  messageKey: 'mustBeValidSerialNumber'
                }
              ]} />

            <p><UniLocalize translate="_scratchCardWarningMessage" /></p>

            {/* step actions */}
            <UniOverlapGroup>
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex + 1)}
                textKey="goNext"
                icon="navigateNext"
                disabled={!this.props.serialNumber.valid}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                textKey="goPrevious"
                icon="navigateBefore"
                secondary={true}
                disabled={this.props.currentStepIndex === 0}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={() => { this.props.toggleDealerCreditsModal(); this.props.clearScratchCard(); }}
                textKey="cancel"
                icon="close"
                secondary={true}
                tooltipPosition="right" />
            </UniOverlapGroup>
          </UniConditionalRender>
          {/* End First Step */}

          {/* Second Step - Auth Code */}
          <UniConditionalRender visible={this.props.currentStepIndex === 1}>

            <p><UniLocalize translate="_enterAuthenticationCodeMessage" /></p>

            <UniConditionalRender visible={partnerConfig.assets.scratchCardBack}>
              <UniImg
                className="scratch-card-img"
                src={partnerConfig.assets.scratchCardBack}
                textKey="imageOfScratchCardBack" />
            </UniConditionalRender>

            <UniInput
              editable={this.props.authenticationNumber}
              placeholderKey="authenticationCode"
              labelKey="authenticationCode"
              handleUpdate={this._updateAuth}
              focusOnInitialRender={true}
              validations={[
                { 
                  validationType: 'regex', 
                  regCheck: /^\d{6}$/,
                  messageKey: 'mustBeValidAuthenticationCode'
                }
              ]} />

            {/* step actions */}
            <UniOverlapGroup>
              <UniOverlapButton
                handleClick={this._redeemAndReload}
                textKey="redeem"
                icon="save"
                showLoader={this.props.loading}
                disabled={!this.props.authenticationNumber.valid || !this.props.serialNumber.valid}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
                textKey="goPrevious"
                icon="navigateBefore"
                secondary={true}
                disabled={this.props.currentStepIndex === 0}
                tooltipPosition="right" />
              <UniOverlapButton
                handleClick={() => { this.props.toggleDealerCreditsModal(); this.props.clearScratchCard() }}
                textKey="cancel"
                icon="close"
                secondary={true}
                tooltipPosition="right" />
            </UniOverlapGroup>
          </UniConditionalRender>
          {/* End Second Step */}

        </UniWorkflow>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    loading: state.dealer.scratch.loading,
    serialNumber: state.dealer.scratch.serial,
    authenticationNumber: state.dealer.scratch.auth,
    currentStepIndex: state.dealer.scratch.workflowStepIndex
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  updateScratchValues,
  toggleDealerCreditsModal,
  redeemScratchCard: attemptRedeemScratchCard,
  changeWorkflowStep: changeCredentialRedeemStepTo,
  clearScratchCard: () => { dispatch(updateScratchValues(inputInitializer('', ['serial', 'auth']))); return dispatch(changeCredentialRedeemStepTo(0)); },
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(DealerEditCreditsContainer)
  ), { componentName: 'DealerEditCredits' })
