import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import bluebird from 'bluebird'

import {
  UniIcon,
  Editable,
  UniInput,
  UniChips,
  UniSteps,
  UniToggle,
  UniTable,
  UniButton,
  UniWorkflow,
  UniLocalize,
  UniOverlapButton,
  UniOverlapGroup,
  UniConditionalRender,
  IUniChips_Chip,
  IUniTable_Column,
  IUniSteps_StepConfig,
  IReaderEnrollmentResponseC,
  IBulkReaderEnrollmentResponseC,
  EUniTable_FooterStyle,
  IMultiInputUpdate,
  ES10nSettings,
  notBlankV10n,
  noop
} from '@unikey/unikey-commons/release/comm';

import {
  IEligibiltyResult,
  serialNumberV10n,
  toggleEnrollReadersModal,
  clearSerialNumberEnrollmentMaps,
  attemptCheckReaderEligibility,
  attemptEnrollReadersInOrg,
  updateSerialNumberEnrollmentMaps,
  updateEnrollReaderWorkflowStep,
  handleSerialNumberChange,
  PartnerCustomizations, 
  IPartnerCustomizations,
} from '../internal';


export interface IStagedReaderToEnroll {
  serialNumber: string,
  readerName?: Editable<string>,
  v10nTrigger?: string,
  failed?: boolean,
  message?: string
}


interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  serialNumberField: Editable<string>,
  serialNumberValid: boolean,
  loading?: boolean,
  currentStepIndex: number,
  enrollanbleReaderNamesAllValid: boolean,
  unenrollable: Map<string, IUniChips_Chip<IStagedReaderToEnroll>>,
  enrollable: Map<string, IUniChips_Chip<IStagedReaderToEnroll>>,
  numEnrollable: number,
  numUnenrollable: number,
  
  toggleModal(): void,
  enrollReadersInOrg(orgId: string, readers: IStagedReaderToEnroll[]): Promise<IBulkReaderEnrollmentResponseC>,
  clearInProgressEnrollment(): void,
  onClose(): void,
  changeWorkflowStep(stepTo: number): void,
  updateSerialNumberField(serialNumber: Editable<string>): void
  attemptCheckReaderEligibility(serialNumber: string): Promise<IEligibiltyResult>,
  updateSerialNumberEnrollmentMaps(enrollable?: Map<string, IUniChips_Chip<IStagedReaderToEnroll>>, unenrollable?: Map<string, IUniChips_Chip<IStagedReaderToEnroll>>): void,
}

class ReaderEnrollContainer extends Component<IProps> {
  steps: IUniSteps_StepConfig[];

  constructor(props: IProps) {
    super(props);

    this.steps = [
      { nameKey: 'readerSelection' },
      { nameKey: 'complete' }
    ];
  }

  _processBulkResponse = (enrollmentData: IBulkReaderEnrollmentResponseC) => {
    enrollmentData.failures.forEach((reader: IReaderEnrollmentResponseC) => {
      const failedReader = this.props.enrollable.get(reader.serial_number) as IUniChips_Chip<IStagedReaderToEnroll>;
      failedReader.failed = true;
      failedReader.ref.failed = true;
      failedReader.disabledReasonKeys = [reader.reason];
      failedReader.ref.message = reader.reason;
      this.props.enrollable.set(reader.serial_number, failedReader);
    });
    this.props.updateSerialNumberEnrollmentMaps(this.props.enrollable);

    return enrollmentData.failures.length;
  }

  _buildChipFromReaderVals = (sNum: string, name?: Editable<string>, reason?: string): IUniChips_Chip<IStagedReaderToEnroll> => {
    return {
      id: sNum,
      nameKey: `#: ${sNum}`,
      failed: !!reason,
      disabledReasonKeys: reason ? [reason] : undefined,
      ref: {
        serialNumber: sNum,
        failed: !!reason,
        message: reason,
        readerName: name ?? new Editable<string>({ value: sNum }),
      }
    };
  }

  _closeEnrollmentWorkflow = () => {
    this.props.clearInProgressEnrollment();
    this.props.changeWorkflowStep(0);
    this.props.toggleModal();
    this.props.onClose();
  }

  _bulkEnrollReaders = () => {
    const readers = [...this.props.enrollable.values()].map(r => r.ref);
    this.props.enrollReadersInOrg(this.props.match.params.organizationId, readers).then((response: IBulkReaderEnrollmentResponseC) => {
      const numFailures = this._processBulkResponse(response);
      if (numFailures > 0) {
        this.props.changeWorkflowStep(2);
      } else {
        this._closeEnrollmentWorkflow();
      }
      return;
    })
  }

  _verifySerialNumberEligibility() {
    const listOfSerials = this.props.serialNumberField.value.split(/[\s,]/).reduce((all: string[], curr: string) => {
      if (curr.length > 0) {
       all.push(curr);
      }
      return all;
    }, []);
    
    bluebird.mapSeries(listOfSerials, (serial: string) => this.props.attemptCheckReaderEligibility(serial).then(({ serialNumber: eligibleSerialNumber }) => {
      const successMapClone = new Map<string, IUniChips_Chip<IStagedReaderToEnroll>>(this.props.enrollable);
      successMapClone.set(eligibleSerialNumber, this._buildChipFromReaderVals(eligibleSerialNumber));
      this.props.updateSerialNumberEnrollmentMaps(successMapClone, undefined);
      return;
    }, ({ serialNumber: failedSerialNumber, reasonKey }) => {
      const failedMapClone = new Map<string, IUniChips_Chip<IStagedReaderToEnroll>>(this.props.unenrollable);
      failedMapClone.set(failedSerialNumber, this._buildChipFromReaderVals(failedSerialNumber, undefined, reasonKey));
      // reasonKeys are: 
      // - enrollmentFailedSerialNumberNotFound or
      // - 
      this.props.updateSerialNumberEnrollmentMaps(undefined, failedMapClone);
      return;
    })).finally(() => {
      // re-focus input after serial number(s) processed
      document.getElementById('serial-num-input')?.focus();
    });
  }

  _readersForTable = (enrollableReaders: Map<string, IUniChips_Chip<IStagedReaderToEnroll>>) => {
    return [...enrollableReaders.values()].map(chipToNonChip => chipToNonChip.ref);
  }

  _setNewNameOnR2E = (serialNum: string, newName: Editable<string>) => {
    if (this.props.enrollable.has(serialNum)) {
      this.props.enrollable.set(serialNum, this._buildChipFromReaderVals(serialNum, newName));
      this.props.updateSerialNumberEnrollmentMaps(this.props.enrollable);
    }
  }

  _buildColumnsAndActions() {
    const columns = new Map<string, IUniTable_Column>()
      .set('serialNumber', {
        nameKey: 'serialNumber',
        isSortable: false,
        type: 'string',
        size: 4,
      })
      .set('readerName', {
        nameKey: 'readerName',
        isSortable: false,
        size: 6,
        template: (r2e: IStagedReaderToEnroll, rowIndex: number) => {
          return (
            <UniInput
              id={`enroll-table-row-${rowIndex}-input-newName`}
              editable={r2e.readerName}
              type="string"
              placeholderKey="name"
              focusOnInitialRender={rowIndex === 0}
              handleUpdate={(newName: Editable) => this._setNewNameOnR2E(r2e.serialNumber, newName)}
              disabled={this.props.loading || this.props.currentStepIndex === 2}
              tooltipBoundary="scrollParent"
              triggerV10nCheck={`${r2e?.v10nTrigger}`}
              validations={[notBlankV10n]} />
          )
        }
      })
      .set('errorMessage', {
        nameKey: '_emptyString',
        isSortable: false,
        size: 2,
        template: (r2e: IStagedReaderToEnroll, rowIndex: number) => {
          return (
            <>
              <UniConditionalRender visible={r2e.failed && this.props.currentStepIndex === 2}>
                <UniIcon
                  className={`enroll-table-row-${rowIndex}-warning error`}
                  name="error"
                  size="xs"
                  tooltipPosition="left"
                  tooltipTextKeys={[r2e.message!]}
                  tooltipBoundary="scrollParent"
                  hidden={!r2e.failed} />
              </UniConditionalRender>
              <UniConditionalRender visible={!r2e.failed && this.props.currentStepIndex === 2}>
                <UniIcon
                  className={`enroll-table-row-${rowIndex}-warning success`}
                  name="check"
                  size="xs"
                  hidden={r2e.failed} />
              </UniConditionalRender>
            </>
          )
        }
      })

    const actions = new Map();

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render.apply(this);
    }
    const { columns, actions } = this._buildColumnsAndActions();
    const enrollableReadersForTable = this._readersForTable(this.props.enrollable);
    const step1 = (
      <>
        <Row>
          <Col md={24}>
            <p><UniLocalize translate="_scanOrEnterSerialNumbers" /></p>
            <div className="serial-number-scan-form">
              <UniInput
                id="serial-num-input"
                editable={this.props.serialNumberField}
                labelKey="serialNumber"
                placeholderKey="_serialNumberPlaceholder"
                validations={[serialNumberV10n]}
                disabled={this.props.loading}
                handleUpdate={(serialNumber: Editable<string>) => {
                  this.props.updateSerialNumberField(serialNumber); 
                  if (serialNumber?.valid && serialNumber?.value?.length === 10) {
                    this._verifySerialNumberEligibility()
                  }
                }}
                handleEnter={() => this._verifySerialNumberEligibility()}
                focusOnInitialRender={true} />
            </div>
          </Col>

          <UniConditionalRender visible={this.props.enrollable?.size > 0}>
            <Col md={24}>
              <h4 className="reader-enroll-serial-num-buckets">
                <span>
                  <UniLocalize translate="enrollableSerialNumbers" /> ({this.props.enrollable.size})
                </span>
              </h4>
              <UniChips
                collection={this.props.enrollable}
                handleRemoveChip={noop}
                disabled={true}
                maxCount={16} />
              <br/>
            </Col>
          </UniConditionalRender>
          
          <UniConditionalRender visible={this.props.unenrollable?.size > 0}>
            <Col md={24}>
              <h4 className="reader-enroll-serial-num-buckets">
                <UniLocalize translate="unenrollableSerialNumbers" />
                <UniIcon
                  name="help"
                  size="xs"
                  tooltipTextKeys={['_explainUnenrollableSerialNumbers']}
                  tooltipPosition="top" />
              </h4>

              <UniChips
                collection={this.props.unenrollable}
                handleRemoveChip={noop}
                disabled={true}
                maxCount={16} />
            </Col>
          </UniConditionalRender>
        </Row>

        {/* step actions */}
        <UniOverlapGroup foldEarly={true}>
          <UniOverlapButton
            handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex + 1)}
            textKey="goNext"
            icon="navigateNext"
            disabled={this.props.numEnrollable === 0 || this.props.loading}
            disabledReasonKeys={['disabled', '_explainDisabledEnrollmentStep1']}
            tooltipPosition="right" />
          <UniOverlapButton
            handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
            textKey="goPrevious"
            icon="navigateBefore"
            secondary={true}
            disabled={this.props.currentStepIndex === 0}
            tooltipPosition="right" />
          <UniOverlapButton
            handleClick={this._closeEnrollmentWorkflow}
            textKey="cancel"
            icon="close"
            secondary={true}
            tooltipPosition="right" />
        </UniOverlapGroup>

      </>
    );

    const step2 = (
      <>
        {/* selectable list of organizations to make user a limited admin of */}
        <UniTable
          searchable={false}
          handleUpdate={noop}
          createButtonTextKey="credentials"
          data={enrollableReadersForTable}
          columnConfig={columns}
          actionsConfig={actions}
          showLoader={false}
          preventDynamicColumnHiding={true}
          footerStyle={EUniTable_FooterStyle.none} />
        
        {/* step actions */}
        <UniConditionalRender visible={this.props.currentStepIndex === 1}>
          <UniOverlapGroup foldEarly={true}>
            {/* step 2 - before save */}
            <UniOverlapButton
              handleClick={() => this._bulkEnrollReaders()}
              textKey="save"
              icon="save"
              disabled={![...this.props.enrollable.values()].every(chip => chip.ref?.readerName?.valid)}
              showLoader={!!this.props.loading} />
            <UniOverlapButton
              handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
              textKey="goPrevious"
              icon="navigateBefore"
              secondary={true}
              disabled={this.props.currentStepIndex === 0}
              tooltipPosition="right" />
            <UniOverlapButton
              handleClick={this._closeEnrollmentWorkflow}
              textKey="cancel"
              icon="close"
              secondary={true}
              tooltipPosition="right" />
            </UniOverlapGroup>
          </UniConditionalRender>

          {/* step 3 - after save */}
          <UniConditionalRender visible={this.props.currentStepIndex === 2}>
            <UniOverlapGroup foldEarly={true}>
              <UniOverlapButton
                handleClick={this._closeEnrollmentWorkflow}
                textKey="close"
                icon="close"
                tooltipPosition="right" />
            </UniOverlapGroup>
          </UniConditionalRender>
      </>
    );

    return (
      <section className='enrollReader-container'>
        <UniWorkflow 
          inModal
          size="wider"
          titleKey="enrollReaders"
          titleIcon="settingsRemote"
          handleClose={this.props.toggleModal}>

          <UniSteps
            steps={this.steps}
            activeStepIndex={this.props.currentStepIndex}
            allStepsUnlocked={false}
            handleStepChange={(val: number) => this.props.changeWorkflowStep(val)} />

          <UniConditionalRender visible={this.props.currentStepIndex === 0}>
          {/* First Step - Email */}
          {step1}
          {/* End First Step */}
          </UniConditionalRender>

          {/* Second Step - Review & Rename */}
          <UniConditionalRender visible={this.props.currentStepIndex === 1 || this.props.currentStepIndex === 2}>
            {step2}
          {/* End Second Step */}
          </UniConditionalRender>

        </UniWorkflow>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  const allReaderNamesValid = [...state.readerEnrollmentForm.enrollableSerialNumbers.values()]?.every((r) => r?.ref?.readerName?.valid);
  return {
    serialNumberField: state.readerEnrollmentForm.serialNumber,
    serialNumberValid: state.readerEnrollmentForm.serialNumber.valid,
    currentStepIndex: state.readerEnrollmentForm.workflowStepIndex,
    loading: state.readerEnrollmentForm.loading,
    enrollanbleReaderNamesAllValid: allReaderNamesValid,
    enrollable: state.readerEnrollmentForm.enrollableSerialNumbers,
    unenrollable: state.readerEnrollmentForm.unenrollableSerialNumbers,
    numEnrollable: state.readerEnrollmentForm.enrollableSerialNumbers?.size || 0,
    numUnenrollable: state.readerEnrollmentForm.unenrollableSerialNumbers?.size || 0,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  attemptCheckReaderEligibility,
  updateSerialNumberEnrollmentMaps,
  toggleModal: toggleEnrollReadersModal,
  updateSerialNumberField: handleSerialNumberChange,
  changeWorkflowStep: updateEnrollReaderWorkflowStep,
  clearInProgressEnrollment: clearSerialNumberEnrollmentMaps,
  enrollReadersInOrg: attemptEnrollReadersInOrg,

}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(ReaderEnrollContainer)
  ), { componentName: 'ReaderEnroll' })
