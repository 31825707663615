import {
  DealerC,
  IMultiInputUpdate,
  IAddressDetailsC,
  IDealerSkinC,
  ApiReduxAction,
} from '@unikey/unikey-commons/release/comm'

import {
  api,
  inputInitializer,
  IExposeRedux,
  portalRedirect,
  oops404Key
} from '../../internal'

export enum dealerActions {

  GET_DEALER_DETAILS_REQUEST = 'GET_DEALER_DETAILS_REQUEST',
  GET_DEALER_DETAILS_SUCCESS = 'GET_DEALER_DETAILS_SUCCESS',
  GET_DEALER_DETAILS_FAILURE = 'GET_DEALER_DETAILS_FAILURE',

  GET_DEALER_SKIN_REQUEST = 'GET_DEALER_SKIN_REQUEST',
  GET_DEALER_SKIN_SUCCESS = 'GET_DEALER_SKIN_SUCCESS',
  GET_DEALER_SKIN_FAILURE = 'GET_DEALER_SKIN_FAILURE',

  UPDATE_DEALER_SKIN_FORM = 'UPDATE_DEALER_SKIN_FORM',

  UPDATE_DEALER_SKIN_REQUEST = 'UPDATE_DEALER_SKIN_REQUEST',
  UPDATE_DEALER_SKIN_SUCCESS = 'UPDATE_DEALER_SKIN_SUCCESS',
  UPDATE_DEALER_SKIN_FAILURE = 'UPDATE_DEALER_SKIN_FAILURE',

  UPDATE_DEALER_DETAILS_REQUEST = 'UPDATE_DEALER_DETAILS_REQUEST',
  UPDATE_DEALER_DETAILS_SUCCESS = 'UPDATE_DEALER_DETAILS_SUCCESS',
  UPDATE_DEALER_DETAILS_FAILURE = 'UPDATE_DEALER_DETAILS_FAILURE',
  HANDLE_DEALER_DETAILS_CHANGE = 'HANDLE_DEALER_DETAILS_CHANGE',

  UPDATE_DEALER_CREDITS_REQUEST = 'UPDATE_DEALER_CREDITS_REQUEST',
  UPDATE_DEALER_CREDITS_SUCCESS = 'UPDATE_DEALER_CREDITS_SUCCESS',
  UPDATE_DEALER_CREDITS_FAILURE = 'UPDATE_DEALER_CREDITS_FAILURE',
  HANDLE_DEALER_CREDITS_CHANGE = 'HANDLE_DEALER_CREDITS_CHANGE',

  TOGGLE_DEALER_CREDITS_MODAL = 'TOGGLE_DEALER_CREDITS_MODAL',
  UPDATE_SCRATCH_VALUES = 'UPDATE_SCRATCH_VALUES',

  DEALER_REDEEM_SCRATCH_REQUEST = 'DEALER_REDEEM_SCRATCH_REQUEST',
  DEALER_REDEEM_SCRATCH_SUCCESS = 'DEALER_REDEEM_SCRATCH_SUCCESS',
  DEALER_REDEEM_SCRATCH_FAILURE = 'DEALER_REDEEM_SCRATCH_FAILURE',

  UPDATE_REDEEM_CREDITS_WORKFLOW_STEP = 'UPDATE_REDEEM_CREDITS_WORKFLOW_STEP',
}

// Dealer Details
interface IDealerId {
  dealerId?: string
}
const getDealerDetailsAction = new ApiReduxAction<any>(api.deal, {
  request: { type: dealerActions.GET_DEALER_DETAILS_REQUEST },
  success: { type: dealerActions.GET_DEALER_DETAILS_SUCCESS },
  failure: {
    type: dealerActions.GET_DEALER_DETAILS_FAILURE,
    title: 'getDealerDetailsFail'
  },
  handleErrorCodes: {
    404: (dux: IExposeRedux, err: any) => {
      portalRedirect(oops404Key);
      // not the error we were hoping to handle, so return an 
      // empty object to tell the action handler to handle as normal
      return { preventAlert: true };
    }
  }
}, (dux: IExposeRedux, params?: IDealerId) => {
  const dealerId = params?.dealerId ?? dux.getState().portal.activeDealer?.id;
  if (dealerId) {
    return api.deal.getDealerDetails.bind(api.deal, dealerId);
  }
  return () => Promise.resolve({});
});
export const attemptRetrieveDealerDetails = getDealerDetailsAction.go;

// update dealer
const updateDealerDetailsAction = new ApiReduxAction(api.deal, {
  request: { type: dealerActions.UPDATE_DEALER_DETAILS_REQUEST },
  success: {
    type: dealerActions.UPDATE_DEALER_DETAILS_SUCCESS,
    title: 'successfullyUpdatedDealer',
    message: 'dealerChangesApplied',
    after: (dux: IExposeRedux) => {
      dux.dispatch(attemptRetrieveDealerDetails())
    }
  },
  failure: {
    type: dealerActions.UPDATE_DEALER_DETAILS_FAILURE,
    title: 'updateDealerDetailsFail'
  }
}, (dux: IExposeRedux) => {
  const state = dux.getState();
  const dealerId = state.portal.activeDealer?.id;
  const dealerData: DealerC = state.dealer.dealerData;
  const editData: IMultiInputUpdate = state.dealer.editData;

  // use the address from the response or just fill in the bare minimum blank so that we can edit
  const dealerAddress: Partial<IAddressDetailsC> = dealerData.address ? dealerData.address : { address_lines: [{ address: '' }, { address: '' }], administrative_area: { name: '' } };
  // eventually, we wil need to come back to this to provide true internatioal address format
  // for now, just use the 2 line address
  const numAddressLines: number = 2;
  const updateData: any = {
    phone_number: editData.phone ? editData.phone!.value : dealerData.phoneNumber?.value,
    address_details: {
      locality: dealerAddress.locality,
      country: dealerAddress.country,
      administrative_area: {
        name: editData.administrativeArea ? editData.administrativeArea!.value : dealerAddress.administrative_area!.name
      },
      address_lines: [...Array(numAddressLines)].map((val, index) => {
        let lineString: string = '';
        if (editData[`streetAddressL${index + 1}`] && editData[`streetAddressL${index + 1}`].value) {
          lineString = (editData[`streetAddressL${index + 1}`].value as string);
        } else if (dealerAddress.address_lines![index]) {
          lineString = dealerAddress.address_lines![index].address;
        }
        return {
          address: lineString,
          // 1-based line-type ordering
          address_line_type: `${index + 1}`
        };
      }),
      postal_code: {
        code_number: editData.postalCode ? editData.postalCode!.value : dealerAddress.postal_code
      }
    }
  }
  return api.deal.updateDealerDetails.bind(api.deal, dealerId, updateData);
});
export const attemptUpdateDealerDetails = updateDealerDetailsAction.go;

export function handleDealerDetailsChange(changes: IMultiInputUpdate) {
  return {
    type: dealerActions.HANDLE_DEALER_DETAILS_CHANGE,
    ...changes
  };
}

export function toggleDealerCreditsModal() {
  return {
    type: dealerActions.TOGGLE_DEALER_CREDITS_MODAL
  }
}

export function updateScratchValues(fields: IMultiInputUpdate) {
  return {
    type: dealerActions.UPDATE_SCRATCH_VALUES,
    ...fields
  }
}

// redeem scratch card
const redeemDealerScratchCardAction = new ApiReduxAction(api.deal, {
  request: { type: dealerActions.DEALER_REDEEM_SCRATCH_REQUEST },
  success: {
    type: dealerActions.DEALER_REDEEM_SCRATCH_SUCCESS,
    after: (dux: IExposeRedux) => {
        // reload the dealer details
        dux.dispatch(updateScratchValues(inputInitializer('', ['serial', 'auth'])));
        dux.dispatch(changeCredentialRedeemStepTo(0))
        dux.dispatch(attemptRetrieveDealerDetails())
    }
  },
  failure: {
    type: dealerActions.DEALER_REDEEM_SCRATCH_FAILURE,
    title: 'scratchRedeemFail'
  }
}, (dux: IExposeRedux) => {
  const state = dux.getState();
  const dealerId: string = state.portal.activeDealer?.id;
  const scratchData = state.dealer.scratch;
  const scratchCode: string = `${scratchData.serial.value}${scratchData.auth.value}`;
  return api.deal.redeemScratchCard.bind(api.deal, dealerId, scratchCode);
});
export const attemptRedeemScratchCard = redeemDealerScratchCardAction.go;

export function changeCredentialRedeemStepTo(stepTo: number) {
  return {
    type: dealerActions.UPDATE_REDEEM_CREDITS_WORKFLOW_STEP,
    stepTo
  };
}

// dealer skin
const getDealerSkinAction = new ApiReduxAction(api.deal, {
  request: { type: dealerActions.GET_DEALER_SKIN_REQUEST },
  success: { type: dealerActions.GET_DEALER_SKIN_SUCCESS },
  failure: { type: dealerActions.GET_DEALER_SKIN_FAILURE },
  // handle404: (dux: IExposeRedux, error: any) => {
  //   // TODO: once the api update to not fail on missing skins is in place, we can remove this.
  //   // for now we need to ignore the 404 and prented it came back as an empty skin
  //   dux.dispatch({
  //     type: dealerActions.GET_DEALER_SKIN_SUCCESS, 
  //     value: {}
  //   });
  //   return;
  // },
  handleErrorCodes: {
    404: (dux: IExposeRedux, err: any) => {
      // TODO: once the api update to not fail on missing skins is in place, we can remove this.
      // for now we need to ignore the 404 and prented it came back as an empty skin
      dux.dispatch({
        type: dealerActions.GET_DEALER_SKIN_SUCCESS, 
        value: {}
      });
      // not the error we were hoping to handle, so return an 
      // empty object to tell the action handler to handle as normal
      return { preventAlert: true, resolveWithSuccess: true };
    },
  }
}, (dux: IExposeRedux) => {
  const dealerId: string = dux.getState().portal.activeDealer?.id;
  if (dealerId) {
    return api.deal.getDealerSkin.bind(api.deal, dealerId);
  }
  return () => Promise.resolve({});
});
export const attemptGetDealerSkinDetails = getDealerSkinAction.go;

// update dealer skin
const setDealerSkinAction = new ApiReduxAction<IDealerSkinC>(api.deal, {
  request: { type: dealerActions.UPDATE_DEALER_SKIN_REQUEST },
  success: { type: dealerActions.UPDATE_DEALER_SKIN_SUCCESS },
  failure: {
    type: dealerActions.UPDATE_DEALER_SKIN_FAILURE,
    title: 'updateDealerSkinFailure'
  }
}, (dux: IExposeRedux, skinConfig) => {
  const dealerId: string = dux.getState().portal.activeDealer?.id;
  return api.deal.updateDealerSkin.bind(api.deal, dealerId, skinConfig);
});
export const attemptUpdateDealerSkin = setDealerSkinAction.go;

export function updateDealerSkinForm(changes: IMultiInputUpdate) {
  return {
    type: dealerActions.UPDATE_DEALER_SKIN_FORM,
    ...changes
  };
}