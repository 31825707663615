import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniConditionalRender,
  IPaginatedResponseC,
  IReaderC,
  IMultiInputUpdate,
  IUniToast_Alert,
  ReaderC,
  OrganizationC,
  UniKeyVal,
  UniLocalize,
  EOrganizationStatus,
  EOperationCodesC,
  ES10nSettings,
  Editable,
  notBlankV10n,
  minV10n,
  maxV10n,
  UniButton,
} from '@unikey/unikey-commons/release/comm';

import {
  canI,
  s10n,
  portalRedirect,
  toggleDealerCreditsModal,
  attemptRetrieveOrgDetails,
  attemptUpdateOrgCredits,
  attemptUpdateOrgDetails,
  handleOrgDetailsChange,
  handleOrgCreditsChange,
  attemptRetrieveDealerDetails,
  DealerEditCreditsContainer,
  PartnerCustomizations, IPartnerCustomizations,
  addAlert,
  navConfig, ENavPages
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  orgId: string,
  orgName: Editable<string>,
  orgStatus: Editable<number>,
  orgReaders: IPaginatedResponseC<IReaderC, ReaderC>,
  initialCreditsAllocated: number,
  initialCreditsClaimed: number,
  isLimitedOrg: Editable<boolean>,
  creditsAllocated: Editable<number>,
  numDealerCreditsAvailable: number,
  remainingDealerCredits: number,

  permissionToUpdateCreditAllocation: boolean,
  permissionToUpdateOrgDetails: boolean,
  permissionToRedeemCredits: boolean,
  permissionToViewDealerCredits: boolean,
  s10nDisplayDealerCreditCount: boolean,
  s10nAllowCreditAllocationEdit: boolean,

  showAlertNotice(notice: IUniToast_Alert): void,
  getOrgDetails(orgId: string): Promise<void>,
  updateOrgDetails(orgId: string): Promise<void>,
  handleDetailsChange(change: IMultiInputUpdate): void,
  handleCreditsChange(change: IMultiInputUpdate): void,
  updateCredits(orgId: string): Promise<void>,
  getDealerAccountDetails(): Promise<void>,
  toggleDealerCreditsModal(): void,
}

class OrganizationDetailsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getOrgDetails(this.props.match.params.organizationId);
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.remainingDealerCredits < 0 && prevProps.remainingDealerCredits >= 0) {
      this.props.showAlertNotice({
        id: Date.now(),
        titleKey: 'notEnoughCredits',
        type: 'warn',
        messageKeys: ['dealerHasInsufficientCredits']
      });
    }
  }

  // credit update requets
  _creditAllocationTypeChange = (isLimited: Editable<boolean>) => {
    this.props.handleCreditsChange({ isLimited });
  }

  _numCreditsAllocatedChange = (numAllocated: Editable<number>) => {
    this.props.handleCreditsChange({ numAllocated });
  }

  _saveCreditAllocationAndReload = (): Promise<void> => {
    return this.props.updateCredits(this.props.match.params.organizationId).then(() => {
      this.props.getDealerAccountDetails();
      this.props.getOrgDetails(this.props.match.params.organizationId);
    });
  }

  // org update request
  _orgStatusChange = (status: Editable<number>) => {
    this.props.handleDetailsChange({ status });
  }

  _orgNameChange = (name: Editable<string>) => {
    this.props.handleDetailsChange({ name });
  }

  _saveOrgDetailsAndReload = () => {
    return this.props.updateOrgDetails(this.props.match.params.organizationId);
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    // determine the max number of credits available to this org for the summary gauage.
    var orgMaxCredits = this.props.initialCreditsClaimed;
    if (this.props.isLimitedOrg.value === false) {
      // non-limited org
      orgMaxCredits = this.props.initialCreditsClaimed + this.props.remainingDealerCredits;
    } else {
      if (this.props.creditsAllocated.value) {
        // limited org and actively customizing the allocation
        // use max credits as either the credits already consumed or the value in the input
        orgMaxCredits = Math.max(this.props.initialCreditsClaimed, this.props.creditsAllocated.value);
      }
      else if (this.props.initialCreditsAllocated === null) {
        // limited org with nothing allocated
        orgMaxCredits = this.props.initialCreditsClaimed;
      }
      else {
        // limited org with allocation
        orgMaxCredits = this.props.initialCreditsAllocated;
      }
    }

    return (
      <section className='orgDetails-container'>
        <Row>
          <Col>
            <h3 className="organization-details-title">{this.props.orgName.value ? this.props.orgName.value : (<UniLocalize translate="organizationDetails" />)}</h3>
          </Col>
        </Row>

        <UniKeyVal
          label={`orgDetails`}
          showLoader={!this.props.orgId}
          preventEdit={!this.props.permissionToUpdateOrgDetails}
          cancelClick={this.props.getOrgDetails.bind(this, this.props.match.params.organizationId)}
          saveClick={this._saveOrgDetailsAndReload}
          fields={[
            {
              keyName: 'identifier',
              value: '' + this.props.orgId,
              type: 'string',
              disabled: true,
            },
            {
              keyName: 'name',
              value: `${this.props.orgName.value}`,
              type: 'string',
              preventTranslate: true,
              placeholderKey: 'organizationName',
              handleUpdate: this._orgNameChange,
              validations: [notBlankV10n]
            },
            {
              keyName: 'status',
              value: `${this.props.orgStatus.value}`,
              type: 'enum',
              enumType: EOrganizationStatus,
              handleUpdate: this._orgStatusChange
            }
          ]} />
        
        {/* Show the jump-to buttons when we've fetched and org and it matches the org page we're on */}
        <UniConditionalRender visible={this.props.orgId === this.props.match.params.organizationId}>
          <Row>
            <Col xs={24} md={12}>
              <br/>
              <UniButton
                fullWidth
                icon="launch"
                theme="invertedbox"
                textKey="manageOrgReaders"
                reverseContentOrder={true}
                allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl', 'xxxl']}
                handleClick={() => portalRedirect(navConfig.get(ENavPages.readers)!.linkTo([this.props.orgId]))} />

            </Col> 

            <Col xs={24} md={12}>
              <br/>
              <UniButton
                fullWidth
                icon="launch"
                theme="invertedbox"
                textKey="manageOrgCredentials"
                reverseContentOrder={true}
                allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl', 'xxxl']}
                handleClick={() => portalRedirect(navConfig.get(ENavPages.credentials)!.linkTo([this.props.orgId]))} />

            </Col>
          </Row>
        </UniConditionalRender>

        <UniConditionalRender visible={this.props.s10nDisplayDealerCreditCount}>
          <Row>
            <Col>
              <h3 className="organization-details-credits-title"><UniLocalize translate="creditAllocation" /></h3>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <UniKeyVal
                label="orgCredits"
                showLoader={!this.props.orgId}
                preventEdit={!this.props.permissionToUpdateCreditAllocation || !this.props.s10nAllowCreditAllocationEdit}
                preventSave={!this.props.creditsAllocated.valid}
                onEdit={this.props.getDealerAccountDetails}
                stacked={this.props.s10nDisplayDealerCreditCount}
                cancelClick={this.props.getOrgDetails.bind(this, this.props.match.params.organizationId)}
                saveClick={this._saveCreditAllocationAndReload}
                fields={[
                  {
                    keyName: 'creditLimitedOrg',
                    value: this.props.isLimitedOrg.value,
                    type: 'boolean',
                    handleUpdate: this._creditAllocationTypeChange,
                    keyInfo: {
                      icon: 'infoOutline',
                      textKeys: ['organizationCreditAllocation', this.props.isLimitedOrg.value ? '_limitedOrgAllocationTypeExplanation' : '_unlimitedOrgAllocationTypeExplanation'],
                    }
                  },
                  {
                    keyName: 'creditsAllocated',
                    value: this.props.creditsAllocated.value || this.props.initialCreditsClaimed,
                    type: 'number', // cant compare NaN === NaN
                    min: this.props.initialCreditsClaimed || 0,
                    max: this.props.numDealerCreditsAvailable + (this.props.initialCreditsAllocated === null ? this.props.initialCreditsClaimed : this.props.initialCreditsAllocated),
                    validations: [
                      minV10n(this.props.initialCreditsClaimed || 0, 'cannotBeLessThanCurrentlyConsumed'),
                      maxV10n(this.props.numDealerCreditsAvailable + (this.props.initialCreditsAllocated === null ? this.props.initialCreditsClaimed : this.props.initialCreditsAllocated), 'mustBeLessThanDealerMax')
                    ],
                    disabled: this.props.isLimitedOrg.value === false,
                    handleUpdate: this._numCreditsAllocatedChange,
                    hidden: this.props.isLimitedOrg.value === false,
                    keyInfo: {
                      icon: 'infoOutline',
                      textKeys: ['creditsAllocated', '_orgCreditsAllocatedExplanation'],
                    }
                  },
                  {
                    keyName: 'credentialsIssued',
                    value: this.props.initialCreditsClaimed,
                    type: 'number',
                    disabled: true,
                    keyInfo: {
                      icon: 'infoOutline',
                      textKeys: ['credentialsIssued', '_orgCredentialsIssuedExplanation'],
                    }
                  }
                ]} />
            </Col>

            <UniConditionalRender visible={this.props.permissionToViewDealerCredits}>
              <Col lg={12}>
                <Row>
                  <Col xs={24}>
                    <UniKeyVal
                      key={this.props.creditsAllocated.value}
                      label="org-credit-summary"
                      stacked={true}
                      showLoader={!Number.isInteger(this.props.remainingDealerCredits)}
                      preventEdit={true}
                      allowForOverlapAlignment={['md', 'lg', 'xl', 'xxl']}
                      fields={[
                        {
                          keyName: 'credentialCreditSummary',
                          type: 'gauge',
                          value: this.props.initialCreditsClaimed + '',
                          gaugeOpts: {
                            nameKey: 'credentialCreditSummary',
                            stacked: true,
                            max: orgMaxCredits,
                            preventReorder: true,
                            size: 'lg',
                            vals: [
                              {
                                nameKey: 'credentialsIssued',
                                value: this.props.initialCreditsClaimed,
                                descriptionKeys: ['_orgCredentialsIssuedExplanation'],
                                theme: 'secondary'
                              }
                            ]
                          }
                        }
                      ]
                      } />
                  </Col>

                  <Col xs={24}>
                    <UniKeyVal
                      label="dealer-available-credentials"
                      showLoader={!Number.isInteger(this.props.remainingDealerCredits)}
                      primaryStateButtonSet={[
                        {
                          textKey: 'getMoreCredits',
                          icon: 'add',
                          clickHandler: () => { this.props.toggleDealerCreditsModal(); return Promise.resolve() }
                        }
                      ]}
                      secondaryStateButtonSet={[]}
                      preventEdit={!this.props.permissionToRedeemCredits}
                      stacked={this.props.s10nDisplayDealerCreditCount}
                      fields={[
                        {
                          keyName: `dealerCreditsRemaining${this.props.numDealerCreditsAvailable !== this.props.remainingDealerCredits ? 'Preview' : ''}`,
                          value: `${this.props.remainingDealerCredits}`,
                          type: 'string',
                          disabled: true,
                          keyInfo: {
                            icon: 'infoOutline',
                            textKeys: ['dealerCreditsRemaining', '_availableDealerCreditsExplanation'],
                          }
                        }
                      ]} />
                  </Col>
                </Row>
              </Col>
            </UniConditionalRender>
          </Row>
        </UniConditionalRender>

      </section>
    )
  }
}

function mapStateToProps(state: any, props: IProps) {
  const numDealerCreditsAvailable = state.dealer.dealerData.availableCredentials - state.dealer.dealerData.unclaimedAllocatedCredentials;

  const calcRemainingDealerCredits = () => {
    let numRemaining = numDealerCreditsAvailable;
    // if the org was initially unlimited...
    if (!Number.isInteger(state.orgDetails.origOrg.creditsAllocated)) {
      // the allocated val will initially be NaN until we start modfying the input
      // if it is NaN, use the creditsClaimed as the starting point
      numRemaining += state.orgDetails.origOrg.creditsClaimed - (state.orgDetails.editCredits.allocated.value || state.orgDetails.origOrg.creditsClaimed);
    }
    else {
      // if it was limited initially, then adjust the remaining avail
      // dealer credits by the change in the orgs allocation
      numRemaining += state.orgDetails.origOrg.creditsAllocated - state.orgDetails.editCredits.allocated.value;
    }
    return numRemaining;
  }

  return {
    orgId: state.orgDetails.orgData.id,
    orgName: state.orgDetails.orgData.name,
    orgStatus: state.orgDetails.orgData.status,
    orgLoading: state.orgDetails.loading,
    orgReaders: state.orgDetails.orgReaders,
    initialCreditsAllocated: state.orgDetails.origOrg.creditsAllocated,
    initialCreditsClaimed: state.orgDetails.origOrg.creditsClaimed,
    creditsAllocated: state.orgDetails.editCredits.allocated,
    remainingDealerCredits: calcRemainingDealerCredits(),
    isLimitedOrg: state.orgDetails.editCredits.isLimited,
    numDealerCreditsAvailable,
    // permissions
    permissionToUpdateCreditAllocation: canI(EOperationCodesC.UpdateOrgCredits, state.dealer.dealerData.id, props.match.params.organizationId),
    permissionToUpdateOrgDetails: canI(EOperationCodesC.UpdateOrg, state.dealer.dealerData.id, props.match.params.organizationId),
    permissionToRedeemCredits: canI(EOperationCodesC.RedeemCredits, state.dealer.dealerData.id),
    permissionToViewDealerCredits: canI(EOperationCodesC.UpdateDealer, state.dealer.dealerData.id),
    // subscription
    s10nDisplayDealerCreditCount: s10n(ES10nSettings.CredCountRelevant),
    s10nAllowCreditAllocationEdit: s10n(ES10nSettings.OrgCreditAllocation),
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getOrgDetails: attemptRetrieveOrgDetails,
  updateOrgDetails: attemptUpdateOrgDetails,
  handleDetailsChange: handleOrgDetailsChange,
  handleCreditsChange: handleOrgCreditsChange,
  updateCredits: attemptUpdateOrgCredits,
  getDealerAccountDetails: attemptRetrieveDealerDetails,

  toggleDealerCreditsModal,
  showAlertNotice: addAlert
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(OrganizationDetailsContainer)
  ), { componentName: 'OrganizationDetails' })
