import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'

import {
  UniWorkflow,
  UniLoader,
  UniButton,
  UniInput,
  UniIcon,
  UniToggle,
  UniTable,
  UniChips,
  UniSteps,
  UniSelect,
  UniMessage,
  UniLocalize,
  UniFileUpload,
  UniOverlapButton,
  UniOverlapGroup,
  UniConditionalRender,
  WiegandCredentialC,
  UniKeyV1CredentialC,
  RawCredentialC,
  CredentialC,
  OrganizationC,
  IUniTable_UpdatePaginationSummary,
  IPaginationQueryBuilderParamsC,
  IUniSteps_StepConfig,
  IUniTable_Column,
  IUniTable_PaginationSummary,
  IUniTable_Sort,
  Editable,
  IUniChips_Chip,
  IMultiInputUpdate,
  IUniToast_Alert,
  IPartnerCredImpls,
  IPartnerCredentialClass,
  ICredentialRequestC,
  ICredentialEncodeAndBuild,
  ES10nSettings,
  ECredentialType,
  IUniTable_Filter,
  EOperationCodesC,
  ECredentialStatus,
  IPartnerCredentialFields,
  EUniTable_FooterStyle,
  emailV10n,
  base64V10n,
  hexV10n,
  minV10n,
  maxV10n,
  EMessageType,
} from '@unikey/unikey-commons/release/comm';

import {
  calcLoaderPercentageComplete,
  toggleCreateCredentialModal,
  attemptRetrieveOrgDetails,
  attemptCreateCredential,
  checkForSimilarCredsInOrg,
  handleNewCredentialChange,
  handleBulkUpdate,
  attemptReissueCredential,
  attemptRetrieveCredentialsList,
  updateNewCredentialWorkflowStep,
  wipeNewCredentialFormData,
  attemptCreateCredentialsInBulk,
  PartnerCustomizations, IPartnerCustomizations,
  IGetPartnerCredsActionParams,
  IHandleBulkUpdateActionParams,
  canI, s10n, noop,
  addAlert,
  dismissAllAlerts,
  IBulkCredentialC,
  IGenericCredPartsFromCSV,
} from '../internal'


interface INamedComponent {
  displayName: string
}

interface ICsvColumnOrder {
  email: number,
  facilityCode?: number,
  cardNumber?: number,
  credentialDataB64?: number,
  credentialDataHex?: number,
  credentialDataBitLength?: number,
  type?: number,
  installer?: number
}

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any, 
  history: any, 

  orgDetails: OrganizationC,

  credsMatchingEmail: CredentialC[],
  credsMatchingCardData: CredentialC[],
  credsMatchingDuplicatesInvited: CredentialC[],
  credsMatchingDuplicates: CredentialC[],

  newSingleCred: any,
  email: Editable,
  facilityCode: Editable<number>,
  cardNumber: Editable<number>,
  type: Editable<number>,
  credentialDataFormat: Editable<string>,
  credentialDataB64: Editable<string>,
  credentialDataHex: Editable<string>,
  credentialDataBin: Editable<string>,
  credentialDataBitLength: Editable<number>,
  isInstaller: boolean,

  // the available credential types for the partner
  partnerCredImplAvailability: ECredentialType[],
  // the default credential for the partner
  partnerDefaultCredentialType: ECredentialType,
  // still need to supply all cred types for all partners
  partnerCredImpls: IPartnerCredImpls,
  // some partners dont want base64 input for credential data
  preventBase64CredentialDataInput?: boolean,
  preventBulkUpload?: boolean,
  bulkCredentialsSaveJobPercentageComplete: number,
  bulkCredentialsSaveJobPercentageInflight: number,

  loading?: boolean,
  bulkCsvColumnOrder: ICsvColumnOrder,
  bulkUploadMode: boolean,
  bulkUploadValid: boolean,
  bulkCredentials: IBulkCredentialC[], // all bulk credentials from the csv
  remainingBulkCredentials: IBulkCredentialC[], // bulk credentials not yet saved (useful after first save)
  selectedBulkCredentials: Map<string, IBulkCredentialC>, // list of credentials marked as selected in table of remaining creds
  numSelectedBulkCredentials: number, // forces re-render as the number ofselected credenitals updates
  successfulBulkCredentials: IBulkCredentialC[], // list of credentials already saved from the initial bulk csv list
  currentStepIndex: number,
  permissionToCreateCredential: boolean,

  numUnallocatedDealerCreditsAvailable: number,
  dealerHasAvailableCredits: boolean,
  dealerAvailableCredits: number,
  dealerContactPhoneNumber: string,

  // Template for the partner's suggested CSV format
  partnerCsvExampleTemplate?: any,

  showAlertNotice(notice: IUniToast_Alert): void,
  dismissAllAlerts(): void,

  toggleCreateCredentialModal(): void,
  clearNewCredentialForm(): void,
  updateNewCredential(formParts: IMultiInputUpdate, isInstaller?: boolean): void
  updateBulkCredential(updates: IHandleBulkUpdateActionParams): void,
  createBulkCredentialsInBatch(credsToSave: IBulkCredentialC[]): Promise<any>,
  changeWorkflowStep(stepTo: number): void,
  checkForSimilarCredsInOrg(orgId: string, credToCheck: ICredentialRequestC): void,
  reissueCredential(credId: string): Promise<void>,
  partnerCredentialEncoder(cred: any): any,
  createNewCredential(credentialToSave: ICredentialRequestC, hideSuccessFailureAlerts?: boolean): Promise<void>,
  updateCredentialListForCurrentOrg(params: IGetPartnerCredsActionParams): Promise<void>,
  getOrgDetails(orgId: string): void,

}

interface IState {
  // simple variable to initialte a revalidation on an input component
  triggerCardNumV10n: boolean,
  // tracking if we have available credits
  insufficientOrgCredits: boolean,
  insufficientDealerCredits: boolean
}

class CredentialCreateContainer extends Component<IProps, IState> {
  steps: IUniSteps_StepConfig[];
  currCredentialConfig: Partial<IPartnerCredentialFields>;

  constructor(props: IProps) {
    super(props);

    this.state = {
      triggerCardNumV10n: true,
      insufficientDealerCredits: false,
      insufficientOrgCredits: false
    };

    this.steps = [
      { nameKey: 'credentialInfo' },
      { nameKey: 'review' }
    ];
    this.currCredentialConfig = this._getCurrCredConfig(props.type.value || props.partnerDefaultCredentialType);
    this._handleTypeChange(({ value: props.partnerDefaultCredentialType, valid: true } as Editable<number>))
  }

  componentDidMount() {
    this._verifyOrgAndDealerHaveEnoughCredits(1)
  }

  componentDidUpdate(prevProps: IProps) {
    // card number validation is sometimes tied to the installer type of the credential,
    // so if we find the installer type has changed we should send a "change" event to revalidate
    if (prevProps.isInstaller !== this.props.isInstaller) {
      this.setState({ triggerCardNumV10n: !this.state.triggerCardNumV10n })
    }

    // we need to check the credential against the others in the organization
    // to provide alternates to creating additional credentials
    if (!this.props.bulkUploadMode && this.props.currentStepIndex === 1 && prevProps.currentStepIndex !== 1) {
      this._checkForSimilarCredentials();
    }
  }

  _checkForSimilarCredentials = () => {
    const DesiredCredential = this._getDesiredCredentialClass(this.props.type.value);
    // build the credential according to the desired type
    const proposedCredential = DesiredCredential.buildForApi(DesiredCredential.encode({
      orgId: this.props.orgDetails.id,
      isInstaller: this.props.isInstaller,
      email: this.props.email.value,
      cardNumber: this.props.cardNumber.value,
      credentialDataB64: this.props.credentialDataB64.value,
      credentialDataBitLength: this.props.credentialDataBitLength.value,
      facilityCode: this.props.facilityCode.value,
    }));
    this.props.checkForSimilarCredsInOrg(this.props.orgDetails.id, proposedCredential);
  }

  _verifyOrgAndDealerHaveEnoughCredits = (creditsNeeded: number) => {
    // if the org is unlimited, and the dealer does not have enough "unallocated credits" then warn
    if (this.props.orgDetails.creditsAvailable === null && this.props.numUnallocatedDealerCreditsAvailable < creditsNeeded) {
      this.setState({
        insufficientDealerCredits: true
      });
      // not enough in dealer
      this.props.showAlertNotice({
        id: Date.now(),
        titleKey: 'cantIssueCredential',
        type: EMessageType.warn,
        messageKeys: ['dealerHasInsufficientCreditsContactYourDealerAdministrator'],
        duration: 36000
      })
      return false;
    }

    // if the org is limited and not enought credits have been allocated then warn
    if (this.props.orgDetails.creditsAvailable !== null && this.props.orgDetails.creditsAvailable < creditsNeeded) {
      this.setState({
        insufficientOrgCredits: true
      });
      this.props.showAlertNotice({
        id: Date.now(),
        titleKey: 'cantIssueCredential',
        type: EMessageType.warn,
        messageKeys: ['organizationHasInsufficientCreditAllocation'],
        duration: 36000
      })
      return false;
    }

    // sufficient credits exist based on what is needed
    this.setState({
      insufficientDealerCredits: false,
      insufficientOrgCredits: false
    });
    this.props.dismissAllAlerts();
    return true;
  }

  _clearAndToggleCreateCredentialModal = () => {
    this.props.clearNewCredentialForm();
    this.props.toggleCreateCredentialModal();
  }

  _getCurrCredConfig = (currCredType: number): Partial<IPartnerCredentialFields> => {
    let credPresets: Partial<IPartnerCredentialFields>;
    if (currCredType === ECredentialType.wiegand44Bit) {
      credPresets = this.props.partnerCredImpls.PartnerWCred.getCredentialConfig();
    } else if (currCredType === ECredentialType.unikeyV1) {
      credPresets = this.props.partnerCredImpls.PartnerU1Cred.getCredentialConfig();
    } else if (currCredType === ECredentialType.raw) {
      credPresets = this.props.partnerCredImpls.PartnerRCred.getCredentialConfig();
    } else if (currCredType === ECredentialType.corp1000) {
      credPresets = this.props.partnerCredImpls.PartnerC1000Cred.getCredentialConfig();
    } else if (currCredType === ECredentialType.tsbWfacility) {
      credPresets = this.props.partnerCredImpls.Partner37BwFCred.getCredentialConfig();
    } else if (currCredType === ECredentialType.tsbNfacility) {
      credPresets = this.props.partnerCredImpls.Partner37BnFCred.getCredentialConfig();
    } else {
      // if for some reason the current cred type is not one of the 3 types, go back to the set partner default
      // this could possibly happen if we are in a _selectOne state
      return this._getCurrCredConfig(this.props.partnerDefaultCredentialType);
    }
    this.currCredentialConfig = credPresets;
    return credPresets;
  }

  _getDesiredCredentialClass = (type: ECredentialType): IPartnerCredentialClass<RawCredentialC | WiegandCredentialC | UniKeyV1CredentialC> => {
    // set the credential based on the provided type
    let DesiredCredential: IPartnerCredentialClass<RawCredentialC | WiegandCredentialC | UniKeyV1CredentialC>;
    if (type === ECredentialType.wiegand44Bit) {
      DesiredCredential = this.props.partnerCredImpls.PartnerWCred!;
    } else if (type === ECredentialType.raw) {
      DesiredCredential = this.props.partnerCredImpls.PartnerRCred!;
    } else if (type === ECredentialType.unikeyV1) {
      DesiredCredential = this.props.partnerCredImpls.PartnerU1Cred!;
    } else if (type === ECredentialType.corp1000) {
      DesiredCredential = this.props.partnerCredImpls.PartnerC1000Cred!;
    } else if (type === ECredentialType.tsbWfacility) {
      DesiredCredential = this.props.partnerCredImpls.Partner37BwFCred!;
    } else if (type === ECredentialType.tsbNfacility) {
      DesiredCredential = this.props.partnerCredImpls.Partner37BnFCred!;
    } else {
      DesiredCredential = this.props.partnerCredImpls.PartnerWCred!;
    }
    return DesiredCredential;
  }

  _createCredentialAndReloadList = () => {
    const DesiredCredential = this._getDesiredCredentialClass(this.props.type.value);
    // build the credential according to the desired type
    const credToCreate = DesiredCredential.buildForApi(DesiredCredential.encode({
      orgId: this.props.orgDetails.id,
      isInstaller: this.props.isInstaller,
      email: this.props.email.value,
      cardNumber: this.props.cardNumber.value,
      credentialDataB64: this.props.credentialDataB64.value,
      credentialDataHex: this.props.credentialDataHex.value,
      credentialDataBitLength: this.props.credentialDataBitLength.value,
      facilityCode: this.props.facilityCode.value,
    }));

    this.props.createNewCredential(credToCreate).then(() => {
      this.props.clearNewCredentialForm();
      this.props.toggleCreateCredentialModal();

      // after cred created re-request org data to ensure credential counts are up-to-date
      this.props.updateCredentialListForCurrentOrg({
        partnerCredImpls: this.props.partnerCredImpls,
        orgId: this.props.orgDetails.id
      });
      return this.props.getOrgDetails(this.props.orgDetails.id);
    })
  }

  _handleEmailChange = (email: Editable) => {
    this.props.updateNewCredential({ email });
  }

  _handleInstallerChange = (isInstaller: boolean) => {
    this.props.updateNewCredential({}, isInstaller);
  }

  _handleCredentialDataFormatChange = (credentialDataFormat: Editable<string>) => {
    this.props.updateNewCredential({ credentialDataFormat });

    // re-run the conversions for the new field
    if (credentialDataFormat.value === 'hex') {
      this._handleCredentialDataHexChange(this.props.credentialDataHex);
    } else if (credentialDataFormat.value === 'base64') {
      this._handleCredentialDataB64Change(this.props.credentialDataB64);
    }
  }

  _handleCredentialDataB64Change = (credentialDataB64: Editable<string>) => {
    const credentialDataHex = CredentialC.convertBase64ToHex(credentialDataB64.value);
    const credentialDataBin = CredentialC.convertHexToBinary(credentialDataHex, this.props.credentialDataBitLength.value);
    this.props.updateNewCredential({
      credentialDataB64,
      credentialDataHex: new Editable({ value: credentialDataHex }),
      credentialDataBin: new Editable({ value: credentialDataBin })
     });
  }

  _handleCredentialDataHexChange = (credentialDataHex: Editable<string>) => {
    const formUpdates: IMultiInputUpdate = {
      credentialDataHex
    };
    if (credentialDataHex.valid) {
      const credentialDataB64 = CredentialC.convertHexToBase64(credentialDataHex.value);
      const credentialDataBin = CredentialC.convertHexToBinary(credentialDataHex.value, this.props.credentialDataBitLength.value);
      formUpdates.credentialDataB64 = new Editable({ value: credentialDataB64 });
      formUpdates.credentialDataBin = new Editable({ value: credentialDataBin });
    }
    this.props.updateNewCredential(formUpdates);
  }

  _handleBitLengthChange = (cdbl: Editable<number>) => {
    const formUpdates: IMultiInputUpdate = {
      credentialDataBitLength: new Editable({ value: Number(cdbl.value), valid: cdbl.valid })
    };
    
    if (this.props.credentialDataHex.valid) {
      // if the data has already been supplied, apply the length restriction to the data, otherwise, just save the length
      const credentialDataBin = CredentialC.convertHexToBinary(this.props.credentialDataHex.value, cdbl.value);
      formUpdates.credentialDataBin = new Editable({ value: credentialDataBin })
    }

    this.props.updateNewCredential(formUpdates);
  }

  _handleCardNumberChange = (cardNumber: Editable) => {
    this.props.updateNewCredential({ cardNumber });
  }

  _handleFacilityChange = (facility: Editable<number>) => {
    this.props.updateNewCredential({ facility });
  }

  _handleTypeChange = (type: Editable<number>) => {
    const numType: number = Number(type.value) || this.props.partnerDefaultCredentialType;
    const credPresets: Partial<IPartnerCredentialFields> = this._getCurrCredConfig(numType);

    // if the chooseOne type option is selected, the credPresets will be {}
    // we should only update the in-prog credential if they selecte some other option.
    if (Object.keys(credPresets).length !== 0) {
      // set the new type
      const credUpdate: IMultiInputUpdate = { type: new Editable({ value: numType, valid: type.valid }) };
      // set the presets if the new type has default data
      if (credPresets.credentialDataBitLength && credPresets.credentialDataBitLength()!.value) {
        credUpdate.credentialDataBitLength = new Editable({ value: credPresets.credentialDataBitLength()!.value!, valid: true });
      }
      if (credPresets.facilityCode && credPresets.facilityCode()!.value) {
        credUpdate.facility = new Editable({ value: credPresets.facilityCode()!.value!, valid: true });
      }
      this.props.updateNewCredential(credUpdate);
    }
  }

  _handleBulkModeChange = (bulkMode: boolean) => {
    // reverify there are enought credentials for the
    // bulk/single request depending on desired bulk type
    if (bulkMode && this.props.remainingBulkCredentials?.length >= 0) {
      this._verifyOrgAndDealerHaveEnoughCredits(this.props.remainingBulkCredentials.length);
    }
    else {
      this._verifyOrgAndDealerHaveEnoughCredits(1);
    }

    // update mode for toggle and view swaps
    this.props.updateBulkCredential({ bulkMode });
  }

  _buildInputStructureWithValue(credParts: Partial<IGenericCredPartsFromCSV>, keyName: string, value: any, valid: boolean = true): Partial<IGenericCredPartsFromCSV> {
    (credParts as any)[keyName] = new Editable({
      value,
      valid
    });
    return credParts;
  }

  _resissueCredentialAndCloseModal = (credentialId: string) => {
    this.props.reissueCredential(credentialId).then(this._clearAndToggleCreateCredentialModal)
  }

  _buildCredentialFromCSVLine = (columnIndexes: ICsvColumnOrder, line: string, lineNum: number): IBulkCredentialC => {
    const lineParts = line.split(',');
    // set the "desired credential" based on the type passed into this csv OR the partner default type
    // if the credential has a type, then we should use to fill in the default fields
    var currCredType: ECredentialType;
    if (columnIndexes.type === undefined) {
      currCredType = this.props.partnerDefaultCredentialType;
    } else {
      // special case for type since we need to lookup the 
      // credential type value based on the name passed in
      currCredType = CredentialC.getCredentialTypeFromName(lineParts[columnIndexes.type!]);
      // set it as the parter default if it is empty or unknown
      currCredType = currCredType === ECredentialType.unknown ? this.props.partnerDefaultCredentialType : currCredType;
    }

    const credPresets: Partial<IPartnerCredentialFields> = this._getCurrCredConfig(currCredType);

    // assign the credential parts based on the flexible csv column order
    const credParts = Object.keys(columnIndexes).reduce((genericCredParts: Partial<IGenericCredPartsFromCSV>, columnName: string): Partial<IGenericCredPartsFromCSV> => {
      // first, add the presets for the credential and let the csv values overwrite them if set
      this._buildInputStructureWithValue(genericCredParts, 'type', currCredType);
      // any of the commonly used presets should be set here before we fill in the line values
      if (credPresets.facilityCode?.().value) {
        genericCredParts = this._buildInputStructureWithValue(genericCredParts, 'facilityCode', credPresets.facilityCode!().value);
      }

      // next, set the csv values on the credential according to the structure provided
      // setting up as { value, valid } for compatibility with the InputChange<string> form part
      // ex: columnName = email
      // set this credPart's [email] to the value at the index of this csv's [email] column
      const indexOfTheCurrentDeisredColunmName: number = (columnIndexes as any)[columnName];

      if (columnName === 'type') {
        this._buildInputStructureWithValue(genericCredParts, columnName, currCredType);
      } else {
        this._buildInputStructureWithValue(genericCredParts, columnName, lineParts[indexOfTheCurrentDeisredColunmName]);
      }
      return genericCredParts;
    }, ({} as Partial<IGenericCredPartsFromCSV>));

    return {
      id: `imported-cred-${lineNum}`,
      parts: credParts,
      config: credPresets,
      v10nTrigger: false
    };
  }

  // parse the first line to determine what type of columns and what order this csv is in
  _determineCSVColumnOrder = (firstLineOfCSV: string): ICsvColumnOrder => {
    const columnOrder: Partial<ICsvColumnOrder> = {};
    firstLineOfCSV.split(',').forEach((columnName: string, index: number) => {
      if (/\s*email\s*/i.test(columnName)) {
        columnOrder.email = index;
      } else if (/\s*facility_code\s*/i.test(columnName)) {
        columnOrder.facilityCode = index;
      } else if (/\s*card_number\s*/i.test(columnName)) {
        columnOrder.cardNumber = index;
      } else if (/\s*credential_data_b64\s*/i.test(columnName)) {
        columnOrder.credentialDataB64 = index;
      } else if (/\s*credential_data_hex\s*/i.test(columnName)) {
        columnOrder.credentialDataHex = index;
      } else if (/\s*credential_data_bit_length\s*/i.test(columnName)) {
        columnOrder.credentialDataBitLength = index;
      } else if (/\s*type\s*/i.test(columnName)) {
        columnOrder.type = index;
      } else if (/\s*installer\s*/i.test(columnName)) {
        columnOrder.installer = index;
      } else {
        throw new Error('csvUnknownColumn');
      }
    });

    // validate the necessary csv columns are provided
    if (columnOrder.email === undefined) {
      throw new Error('csvMissingColumnEmail');
    } else if (Number.isInteger(columnOrder.facilityCode!) && columnOrder.cardNumber === undefined) {
      // if we provided facility code but not card number
      throw new Error('csvFacilityCodeMustBePairedWithCardNumber');
    } else if (columnOrder.cardNumber === undefined && columnOrder.credentialDataB64 === undefined && columnOrder.credentialDataHex === undefined) {
      // if we provided neither card number nor credential data
      throw new Error('csvCardNumberOrCredentialDataAreRequired');
    } else if (columnOrder.cardNumber !== undefined && (columnOrder.credentialDataB64 !== undefined || columnOrder.credentialDataHex !== undefined)) {
      // if the contents include card number and UnikeyV1/Raw data, that would be too much to show in the review screen
      throw new Error('csvCannotParseCardDataAndCredentialData');
    }

    return (columnOrder as ICsvColumnOrder);
  }

  _handleCSVFileChange = (uploadedChange: Editable<any>) => {
    const uploadedFile = uploadedChange?.value;
    if (uploadedFile) {
      const reader = new FileReader();
      reader.readAsText(uploadedFile);
      reader.onload = (event: any) => {
        const csv = event.target.result;
        const allTextLines = csv.split(/\r\n|\n/);
        const lines = allTextLines.map((data: string) => data.split(';'));

        try {
          const headerLine = lines[0];
          const columnOrder = this._determineCSVColumnOrder(headerLine[0]);
          const bulkCsvCredentials: IBulkCredentialC[] = lines.slice(1).reduce((totalCredsToAdd: IBulkCredentialC[], currLine: string, lineIndex: number) => {
            if (currLine[0] === '') {
              // empty line, skip instead of failing
              return totalCredsToAdd;
            }

            // all valid, retun the individual BulkCredential
            totalCredsToAdd.push(this._buildCredentialFromCSVLine(columnOrder, currLine[0], lineIndex));

            return totalCredsToAdd;
          }, []);

          // make sure that the org and dealer have enough credits to handle this CSV
          this._verifyOrgAndDealerHaveEnoughCredits(bulkCsvCredentials.length)
          this.props.updateBulkCredential({
            bulkCsvColumnOrder: columnOrder,
            bulkFile: uploadedFile,
            bulkValid: true,
            bulkCsvLines: lines,
            bulkCsvCredentials,
            // initially, the remaining is the full list of credentials from the csv
            bulkCredentialsRemaining: bulkCsvCredentials,
            // add all the credential as initially selected
            bulkCredentialsSelected: bulkCsvCredentials.reduce((selectedMap: Map<string, IBulkCredentialC>, currC: IBulkCredentialC) => {
              selectedMap.set(currC.id, currC);
              return selectedMap;
            }, new Map()),
            bulkCredentialsSuccessful: [],
          });

        } catch (err: any) {
          let message: string = '';
          if (err?.message === 'invalidEmail') {
            message = this.props.intl.formatMessage({ id: 'csvEmailInvalid' });
          }
          else if (err?.message === 'invalidCardNumber') {
            message = this.props.intl.formatMessage({ id: 'csvCardNumberInvalid' });
          }
          else if (err?.message === 'invalidFacilityCode') {
            message = this.props.intl.formatMessage({ id: 'csvFacilityCodeInvalid' });
          }
          else {
            message = this.props.intl.formatMessage({ id: 'csvGenericParseError' })
          }

          this.props.updateBulkCredential({
            bulkFile: uploadedFile,
            bulkValid: false,
            bulkCsvFailureReason: message
          });
        }
      };
      reader.onerror = (err: any) => {
        this.props.updateBulkCredential({
          bulkFile: uploadedFile,
          bulkValid: false,
          bulkCsvFailureReason: err
        });
      };
    }
  }

  _createBulkCredentials = () => {
    // only send the credentials that are selected instead of all in the table
    const credsToSend: IBulkCredentialC[] = Array.from(this.props.selectedBulkCredentials.values()).map((currCred: IBulkCredentialC, currIndex: number) => {
      const DesiredCredential = this._getDesiredCredentialClass(Number(currCred.parts.type!.value));
      // build the credential according to the desired type
      currCred.built = DesiredCredential.buildForApi(DesiredCredential.encode({
        orgId: this.props.orgDetails.id,
        isInstaller: currCred.parts.isInstaller ? currCred.parts.isInstaller.value === 'true' : false,
        email: currCred.parts.email!.value,
        cardNumber: currCred.parts.cardNumber ? Number(currCred.parts.cardNumber!.value) : undefined,
        credentialDataB64: currCred.parts.credentialDataB64 ? currCred.parts.credentialDataB64!.value : undefined,
        credentialDataBitLength: currCred.parts.credentialDataBitLength ? Number(currCred.parts.credentialDataBitLength!.value) : undefined,
        facilityCode: currCred.parts.facilityCode ? Number(currCred.parts.facilityCode!.value) : undefined,
      }));
      return currCred;
    });
    this.props.createBulkCredentialsInBatch(credsToSend).finally(() => {
      // after creds created re-request the list and the org data to ensure credential counts are up-to-date
      this.props.updateCredentialListForCurrentOrg();
      return this.props.getOrgDetails(this.props.orgDetails.id);
    });
  }

  _toggleSelectedBulkCredential = (credToToggle?: IBulkCredentialC, toggleAll?: boolean): any => {
    // so that we're not modifying values from the 
    // redux store directly, we need to clone it first
    const modifiedBulkMap = new Map(this.props.selectedBulkCredentials);
    if (credToToggle) {
      // the key for this map is the email+cardnumber+facilityCode+
      if (modifiedBulkMap.has(credToToggle.id)) {
        modifiedBulkMap.delete(credToToggle.id);
      } else {
        modifiedBulkMap.set(credToToggle.id, credToToggle);
      }
    }
    this.props.updateBulkCredential({
      bulkCredentialsSelected: modifiedBulkMap
    });
  }

  _setBulkCredentialsAfterEdit = (bCred: IBulkCredentialC, credentialEditFields: IMultiInputUpdate, indexOfBulkCredential: number) => {
    // update the credential that has changed and set it back on the credentials list and the selected-list if it belongs there too
    const updatedBCred = bCred;
    updatedBCred.parts = {
      ...bCred.parts,
      ...credentialEditFields
    };

    // if we're changing the credential type, we need to 
    // reset the cred config on this credential so that 
    // the inputs and validation update appropriately  
    if (credentialEditFields.type) {
      updatedBCred.config = this._getCurrCredConfig(Number(credentialEditFields.type!.value));
      // flip the boolean to trigger a full validation for all inputs on the credential
      updatedBCred.v10nTrigger = !updatedBCred.v10nTrigger;
    }

    // if we're changing the credential hex data input, we should also convert it to the base64 input
    if (!!credentialEditFields.credentialDataHex) {
      const base64ConvertedValue = CredentialC.convertHexToBase64(credentialEditFields.credentialDataHex!.value as string);
      // if the hex is invalid then mark the base64 as invalid too to fail bulk table validations
      updatedBCred.parts.credentialDataB64 = new Editable<string>({ value: base64ConvertedValue, valid: credentialEditFields.credentialDataHex.valid })
    }

    // update it in the full cred list
    const remainingBulkCredentialsList = this.props.remainingBulkCredentials;
    remainingBulkCredentialsList.splice(indexOfBulkCredential, 1, updatedBCred);

    // update it in the selected credentials too
    const newSelectedCredentials = new Map(this.props.selectedBulkCredentials);
    if (newSelectedCredentials.has(updatedBCred.id)) {
      newSelectedCredentials.set(updatedBCred.id, updatedBCred);
    }

    this.props.updateBulkCredential({
      bulkCredentialsRemaining: remainingBulkCredentialsList,
      bulkCredentialsSelected: newSelectedCredentials
    });
    return;
  }

  _buildBulkUploadColumnsAndActions() {
    const columns = new Map<string, IUniTable_Column>()
      .set('email', {
        nameKey: 'email',
        isSortable: false,
        size: 6,
        template: (cred: IBulkCredentialC, rowIndex: number) => {
          return (
            <UniInput
              id={`cred-table-row-${rowIndex}-input-email`}
              editable={cred.parts.email}
              placeholderKey="email"
              handleUpdate={(email: Editable) => this._setBulkCredentialsAfterEdit(cred, { email }, rowIndex)}
              disabled={this.props.loading}
              required={true}
              validateOnInitialRender={true}
              tooltipBoundary="scrollParent"
              validations={[emailV10n]} />
          )
        },
      });

    if (Number.isInteger(this.props.bulkCsvColumnOrder.facilityCode!)) {
      columns.set('facilityCode', {
        nameKey: 'facility',
        isSortable: false,
        size: 4,
        template: (cred: IBulkCredentialC, rowIndex: number) => {
          if (cred.config.facilityCode) {
            const currFacilityCodeValidations = cred.config.facilityCode?.().validations;
            return (
              <UniInput
                id={`cred-table-row-${rowIndex}-input-facilityCode`}
                editable={cred.parts.facilityCode}
                type="number"
                min={currFacilityCodeValidations?.min}
                max={currFacilityCodeValidations?.max}
                placeholderKey="facilityCode"
                handleUpdate={(facilityCode: Editable) => this._setBulkCredentialsAfterEdit(cred, { facilityCode }, rowIndex)}
                required={cred.config.requiredFields?.().has('facilityCode')}
                disabled={this.props.loading || cred.config.facilityCode?.().disabled}
                validateOnInitialRender={true}
                triggerV10nCheck={`${cred.v10nTrigger}`}
                tooltipBoundary="scrollParent"
                validations={[
                  minV10n(currFacilityCodeValidations?.min, currFacilityCodeValidations?.message),
                  maxV10n(currFacilityCodeValidations?.max, currFacilityCodeValidations?.message)
                ]} />
            )
          } else {
            return (<i>&nbsp;<UniLocalize translate="_notApplicable" /></i>);
          }
        }
      });
    }
    
    if (Number.isInteger(this.props.bulkCsvColumnOrder.cardNumber!)) {
      columns.set('cardNumber', {
        nameKey: 'cardNumber',
        isSortable: false,
        size: 4,
        template: (cred: IBulkCredentialC, rowIndex: number) => {
          const currCardNumValidations = cred.config.cardNumber?.({ isInstaller: cred.parts.isInstaller?.value === 'true' }).validations;
          return (
            <UniInput
              id={`cred-table-row-${rowIndex}-input-cardNumber`}
              editable={cred.parts.cardNumber}
              type="number"
              min={currCardNumValidations?.min}
              max={currCardNumValidations?.max}
              placeholderKey="cardNumber"
              handleUpdate={(cardNumber: Editable) => this._setBulkCredentialsAfterEdit(cred, { cardNumber }, rowIndex)}
              required={cred.config.requiredFields?.().has('cardNumber')}
              disabled={this.props.loading}
              validateOnInitialRender={true}
              tooltipBoundary="scrollParent"
              triggerV10nCheck={`${cred.v10nTrigger}`}
              validations={[
                minV10n(currCardNumValidations?.min, currCardNumValidations?.message),
                maxV10n(currCardNumValidations?.max, currCardNumValidations?.message)
              ]} />
          )
        }
      })
    }

    if (Number.isInteger(this.props.bulkCsvColumnOrder.credentialDataBitLength!)) {
      columns.set('bitLength', {
        nameKey: 'bitLength',
        isSortable: false,
        size: 4,
        template: (cred: IBulkCredentialC, rowIndex: number) => {
          const currCardNumValidations = cred.config.credentialDataBitLength?.({ isInstaller: cred.parts.isInstaller?.value === 'true' }).validations;
          return (
            <UniInput
              id={`cred-table-row-${rowIndex}-input-credentialDataBitLength`}
              editable={cred.parts.credentialDataBitLength}
              type="number"
              min={currCardNumValidations?.min}
              max={currCardNumValidations?.max}
              placeholderKey="credentialDataBitLength"
              handleUpdate={(credentialDataBitLength: Editable) => this._setBulkCredentialsAfterEdit(cred, { credentialDataBitLength }, rowIndex)}
              required={cred.config.requiredFields?.().has('credentialDataBitLength')}
              disabled={this.props.loading}
              validateOnInitialRender={true}
              tooltipBoundary="scrollParent"
              triggerV10nCheck={`${cred.v10nTrigger}`}
              validations={[
                minV10n(currCardNumValidations?.min, currCardNumValidations?.message),
                maxV10n(currCardNumValidations?.max, currCardNumValidations?.message)
              ]} />
          )
        }
      })
    }

    if (Number.isInteger(this.props.bulkCsvColumnOrder.credentialDataHex!)) {
      columns.set('credDataHex', {
        nameKey: 'hexShort',
        isSortable: false,
        size: 4,
        template: (cred: IBulkCredentialC, rowIndex: number) => {
          const currCardNumValidations = cred.config.credentialDataHex?.({ isInstaller: cred.parts.isInstaller?.value === 'true' }).validations;
          return (
            <UniInput
              id={`cred-table-row-${rowIndex}-input-credentialDataHex`}
              editable={cred.parts.credentialDataHex}
              type="string"
              min={currCardNumValidations?.min}
              max={currCardNumValidations?.max}
              placeholderKey="credentialDataHex"
              handleUpdate={(credentialDataHex: Editable) => this._setBulkCredentialsAfterEdit(cred, { credentialDataHex }, rowIndex)}
              required={cred.config.requiredFields?.().has('credentialDataHex')}
              disabled={this.props.loading}
              validateOnInitialRender={true}
              tooltipBoundary="scrollParent"
              triggerV10nCheck={`${cred.v10nTrigger}`}
              validations={[hexV10n]} />
          )
        }
      })
    }
    
    columns.set('type', {
      nameKey: 'format',
      isSortable: false,
      size: 4,
      template: (cred: IBulkCredentialC, rowIndex: number) => {
        return (
          <UniSelect
            id={`cred-table-row-${rowIndex}-select-type`}
            className={!cred.parts.type!.valid ? 'invalid' : undefined}
            value={cred.parts.type!.value}
            name="format"
            translateValues={true}
            options={this.props.partnerCredImplAvailability.map((credType: ECredentialType) => {
              return {
                value: credType,
                nameKey: `${ECredentialType[credType]}`
              };
            })}
            disabled={true}
            handleUpdate={(type: Editable) => this._setBulkCredentialsAfterEdit(cred, { type }, rowIndex)} />
        )
      },
    })
      .set('errorMessage', {
        nameKey: '_emptyString',
        isSortable: false,
        size: 2,
        template: (cred: IBulkCredentialC, rowIndex: number) => {
          return (
            <UniIcon
              className={`credential-row-${rowIndex}-warning error`}
              name="errorOutline"
              size="xs"
              tooltipPosition="left"
              tooltipTextKeys={[cred.message!]}
              tooltipBoundary="scrollParent"
              hidden={!cred.failed} />
          )
        }
      })

    const actions = new Map();

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const { columns, actions } = this._buildBulkUploadColumnsAndActions();
    const requiredFieldsFromConfig = this.currCredentialConfig.requiredFields?.() || new Set();
    const requiredFields: string[] = requiredFieldsFromConfig.size > 0 ? [...requiredFieldsFromConfig!.values()] : [];
    const allRequiredSingleCredentialFieldsValid: boolean = this.props.email.valid! && !!(requiredFields!.every((fieldName: string): boolean => {
      return ((this.props as any)[fieldName] as Editable).valid || false;
    }));

    // build the options for the select dropdown based on the partner's cred impl availability
    const partnerCredOptions = this.props.partnerCredImplAvailability.map((credType: ECredentialType) => {
      return {
        value: credType,
        nameKey: `${ECredentialType[credType]}`
      };
    });
    let desiredBitLengthTooLargeForCredentialData = false;
    if (this.props.credentialDataBitLength.value && this.props.credentialDataHex.valid) {
      desiredBitLengthTooLargeForCredentialData = Number(this.props.credentialDataBitLength.value) > CredentialC.convertHexToBinary(this.props.credentialDataHex.value).length;
    }
    const singleCredentialForm = (
      <>
        <p><UniLocalize translate="_newCredentialEnterInfoStepMessage" /></p>
        <Row>
          <Col lg={12}>
            <UniSelect
              value={this.props.type.value}
              name="credentialType"
              translateValues={true}
              options={partnerCredOptions}
              labelKey="credentialType"
              handleUpdate={this._handleTypeChange} />
          </Col>
          <Col lg={12}>
            <UniInput
              editable={this.props.email}
              placeholderKey="email"
              labelKey="email"
              handleUpdate={this._handleEmailChange}
              required={true}
              focusOnInitialRender={true}
              validations={[emailV10n]} />
          </Col>
        </Row>

        <Row>
          <UniConditionalRender visible={!!this.currCredentialConfig.facilityCode} >
            <Col>
              <UniInput
                editable={this.props.facilityCode}
                type="number"
                min={this.currCredentialConfig.facilityCode?.().validations?.min}
                max={this.currCredentialConfig.facilityCode?.().validations?.max}
                placeholderKey="facilityCode"
                labelKey="facilityCode"
                required={this.currCredentialConfig?.requiredFields?.().has('facilityCode')}
                disabled={this.currCredentialConfig?.facilityCode?.().disabled}
                handleUpdate={this._handleFacilityChange}
                focusOnInitialRender={false}
                validations={[
                  minV10n(this.currCredentialConfig.facilityCode?.().validations?.min, this.currCredentialConfig.facilityCode?.().validations?.message),
                  maxV10n(this.currCredentialConfig.facilityCode?.().validations?.max, this.currCredentialConfig.facilityCode?.().validations?.message),
                ]} />
            </Col>
          </UniConditionalRender>

          <UniConditionalRender visible={!!this.currCredentialConfig.cardNumber} >

            <Col>
              <UniInput
                editable={this.props.cardNumber}
                type="number"
                min={this.currCredentialConfig.cardNumber?.({ isInstaller: this.props.isInstaller }).validations?.min}
                max={this.currCredentialConfig.cardNumber?.({ isInstaller: this.props.isInstaller }).validations?.max}
                placeholderKey="cardNumber"
                labelKey="cardNumber"
                required={this.currCredentialConfig?.requiredFields?.().has('cardNumber')}
                handleUpdate={this._handleCardNumberChange}
                focusOnInitialRender={false}
                triggerV10nCheck={`${this.props.cardNumber.value}`}
                validations={[
                  minV10n(this.currCredentialConfig.cardNumber?.({ isInstaller: this.props.isInstaller }).validations?.min, this.currCredentialConfig.cardNumber?.({ isInstaller: this.props.isInstaller }).validations?.message),
                  maxV10n(this.currCredentialConfig.cardNumber?.({ isInstaller: this.props.isInstaller }).validations?.max, this.currCredentialConfig.cardNumber?.({ isInstaller: this.props.isInstaller }).validations?.message)
                ]} />
            </Col>

            {/* some partners (honeywell) need to have custom validation if the credential is an installer type */}
          </UniConditionalRender>


          <UniConditionalRender visible={!!this.currCredentialConfig.credentialDataBitLength} >
            <Col md={12}>
              <UniInput
                editable={this.props.credentialDataBitLength}
                type="number"
                min={this.currCredentialConfig.credentialDataBitLength?.({ isInstaller: this.props.isInstaller }).validations?.min}
                max={this.currCredentialConfig.credentialDataBitLength?.({ isInstaller: this.props.isInstaller }).validations?.max}
                placeholderKey="credentialDataBitLength"
                labelKey="credentialDataBitLength"
                required={this.currCredentialConfig?.requiredFields?.().has('credentialDataBitLength')}
                handleUpdate={this._handleBitLengthChange}
                focusOnInitialRender={false}
                validations={[
                  minV10n(this.currCredentialConfig.credentialDataBitLength?.({ isInstaller: this.props.isInstaller }).validations?.min, this.currCredentialConfig.credentialDataBitLength?.({ isInstaller: this.props.isInstaller }).validations?.message),
                  maxV10n(this.currCredentialConfig.credentialDataBitLength?.({ isInstaller: this.props.isInstaller }).validations?.max, this.currCredentialConfig.credentialDataBitLength?.({ isInstaller: this.props.isInstaller }).validations?.message)
                ]} />
            </Col>
          </UniConditionalRender>

          <UniConditionalRender visible={!this.props.preventBase64CredentialDataInput && (this.props.type.value === ECredentialType.unikeyV1 || this.props.type.value === ECredentialType.raw)} >
            <Col md={12}>
              <UniSelect
                value={this.props.credentialDataFormat?.value}
                name="credentialDataFormat"
                translateValues={true}
                options={[
                  {
                    nameKey: 'hex',
                    value: 'hex'
                  },
                  {
                    nameKey: 'base64',
                    value: 'base64'
                  }
                ]}
                labelKey="credentialDataFormat"
                handleUpdate={this._handleCredentialDataFormatChange} />
            </Col>
          </UniConditionalRender>

          <UniConditionalRender visible={!!this.currCredentialConfig.credentialDataB64 && this.props.credentialDataFormat?.value === 'base64'} >
            <Col md={12}>
              <UniInput
                editable={this.props.credentialDataB64}
                placeholderKey="credentialDataB64"
                labelKey="credentialDataB64"
                required={this.currCredentialConfig?.requiredFields?.().has('credentialDataB64')}
                handleUpdate={this._handleCredentialDataB64Change}
                validations={[base64V10n]} />
            </Col>
            <Col md={12}>
              <UniInput
                value={this.props.credentialDataHex.value}
                placeholderKey="preview"
                labelKey="credentialDataHexPreview" 
                disabled={true} />
            </Col>
            <Col md={24}>
              <UniInput
                value={this.props.credentialDataBin.value}
                placeholderKey="preview"
                labelKey="credentialDataBinaryPreview" 
                disabled={true} />

            </Col>
          </UniConditionalRender>

          <UniConditionalRender visible={!!this.currCredentialConfig.credentialDataHex && this.props.credentialDataFormat?.value === 'hex'} >
            <Col md={12}>
              <UniInput
                editable={this.props.credentialDataHex}
                placeholderKey="credentialDataHex"
                labelKey="credentialDataHex"
                required={this.currCredentialConfig?.requiredFields?.().has('credentialDataB64')} // supposed to be 64 here since that is the only required one -- hex is converted into b64 before sending, so hex is required when b64 is required
                handleUpdate={this._handleCredentialDataHexChange}
                validations={[hexV10n]} />
            </Col>
            <UniConditionalRender visible={!this.props.preventBase64CredentialDataInput}>
              <Col md={12}>
                <UniInput 
                  value={this.props.credentialDataB64.value}
                  placeholderKey="preview"
                  labelKey="credentialDataB64Preview"
                  disabled={true} />
              </Col>
            </UniConditionalRender>
            <Col md={24}>
              <UniInput
                value={this.props.credentialDataBin.value}
                placeholderKey="preview"
                labelKey="credentialDataBinaryPreview" 
                disabled={true} />
            </Col>
          </UniConditionalRender>

        </Row>

        <p><UniLocalize translate="giveUserInstallerPermissions" /></p>
        <UniToggle
          size="sm"
          theme="secondary"
          options={[
            {
              value: false,
              nameKey: 'no'
            },
            {
              value: true,
              nameKey: 'yes'
            }
          ]}
          value={this.props.isInstaller}
          handleUpdate={this._handleInstallerChange} />

        {/* step actions */}
        <UniOverlapGroup foldEarly={true}>
          <UniOverlapButton
            handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex + 1)}
            textKey="goNext"
            icon="navigateNext"
            disabled={!allRequiredSingleCredentialFieldsValid || !this.props.dealerHasAvailableCredits} />
          <UniOverlapButton
            handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
            textKey="goPrevious"
            icon="navigateBefore"
            secondary={true}
            disabled={this.props.currentStepIndex === 0} />
          <UniOverlapButton
            handleClick={this._clearAndToggleCreateCredentialModal}
            textKey="clearAndClose"
            icon="close"
            secondary={true} />
        </UniOverlapGroup>
      </>
    );

    const singleCredentialReview = (
      <>
        <div className="review">

          <h4><UniLocalize translate="email" /></h4>
          <p>{this.props.email.value}</p>

          <h4><UniLocalize translate="credentialType" /></h4>
          <p><UniLocalize translate={ECredentialType[this.props.type.value]} /></p>

          <UniConditionalRender visible={!!this.currCredentialConfig.requiredFields?.()!.has('facilityCode')} >
            <h4><UniLocalize translate="facility" /></h4>
            <p>{this.props.facilityCode.value}</p>
          </UniConditionalRender>

          <UniConditionalRender visible={!!this.currCredentialConfig.requiredFields?.()!.has('cardNumber')} >
            <h4><UniLocalize translate="cardNumber" /></h4>
            <p>{this.props.cardNumber.value}</p>
          </UniConditionalRender>

          <UniConditionalRender visible={!!this.currCredentialConfig.requiredFields?.()!.has('credentialDataB64')} >
            <UniConditionalRender visible={!this.props.preventBase64CredentialDataInput}>
              <h4><UniLocalize translate="credentialDataB64" /></h4>
              <p>{this.props.credentialDataB64.value}</p>
            </UniConditionalRender>

            <h4><UniLocalize translate="credentialDataHex" /></h4>
            <p>{this.props.credentialDataHex.value}</p>

            <h4><UniLocalize translate="credentialDataBin" /></h4>
            <p>{this.props.credentialDataBin.value}</p>
          </UniConditionalRender>

          <UniConditionalRender visible={!!this.currCredentialConfig.requiredFields?.()!.has('credentialDataBitLength')} >
            <h4><UniLocalize translate="credentialDataBitLength" /></h4>
            <p>{this.props.credentialDataBitLength.value}</p>
          </UniConditionalRender>

          <h4><UniLocalize translate="installer" /></h4>
          <p><UniLocalize translate={this.props.isInstaller ? 'yes' : 'no'} /></p>


          {/* Warn of similar existing credential */}
          <UniConditionalRender visible={this.props.credsMatchingDuplicatesInvited.length > 0} >
            <UniMessage
              messageKeys={['_dupeWarningDuplicatePending']}
              type={EMessageType.warn} >
              <UniButton
                textKey="reissueCredential"
                handleClick={() => this._resissueCredentialAndCloseModal(this.props.credsMatchingDuplicatesInvited[0].id)}
                icon="send"
                theme="inverted"
                iconSize="sm"
                hideText={true}
                tooltipPosition="top" />
            </UniMessage>
          </UniConditionalRender>

          {/* Warn of similar existing credential */}
          <UniConditionalRender visible={this.props.credsMatchingDuplicates.length > 0 && this.props.credsMatchingDuplicatesInvited.length === 0} >
            <UniMessage
              messageKeys={['_dupeWarningDuplicate']}
              type={EMessageType.warn} />
          </UniConditionalRender>

          <UniConditionalRender visible={this.props.credsMatchingEmail.length > 0 && this.props.credsMatchingDuplicatesInvited.length === 0} >
            <UniMessage
              messageKeys={['_dupeWarningMatchingEmail']}
              type={EMessageType.info} />
          </UniConditionalRender>

          {/* Warn of similar existing credential */}
          <UniConditionalRender visible={this.props.credsMatchingCardData.length > 0 && this.props.credsMatchingDuplicatesInvited.length === 0} >
            <UniMessage
              messageKeys={['_dupeWarningMatchingCardData']}
              type={EMessageType.info} />
            <UniChips
              labelKey="matchingCredentialsBelongToColon"
              collection={this.props.credsMatchingCardData.reduce((credMap: Map<string, IUniChips_Chip>, cred: CredentialC, currIndex: number) => {
                return credMap.set(cred.id, { nameKey: cred.email, ref: cred, disabled: false });
              }, new Map())}
              disabled={true}
              maxCount={2}
              handleRemoveChip={noop} />
          </UniConditionalRender>

        </div>

        {/* step actions */}
        <UniOverlapGroup foldEarly={true} >
          <UniOverlapButton
            handleClick={this._createCredentialAndReloadList}
            textKey="save"
            icon="save"
            disabled={!allRequiredSingleCredentialFieldsValid || !this.props.dealerHasAvailableCredits}
            showLoader={!!this.props.loading} />
          <UniOverlapButton
            handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
            textKey="goPrevious"
            icon="navigateBefore"
            secondary={true}
            disabled={this.props.currentStepIndex === 0} />
          <UniOverlapButton
            handleClick={this._clearAndToggleCreateCredentialModal}
            textKey="cancel"
            icon="close"
            secondary={true} />
        </UniOverlapGroup>
      </>
    );

    const bulkUploadForm = (
      <>

        <p><UniLocalize translate="_bulkUploadCsvCredentials" /></p>
        <p><UniLocalize translate="_csvCredentialFormatInstructions" /></p>

        <div className="csv-example-block">
          <UniConditionalRender visible={!this.props.partnerCsvExampleTemplate} >

            {/* Default Template -- This should not be translated */}
            <p>EMAIL,FACILITY_CODE,CARD_NUMBER,TYPE</p>
            {/* Wiegand Example Line */}
            <UniConditionalRender visible={this.props.partnerCredImplAvailability.includes(ECredentialType.wiegand44Bit)}>
              <p>address1@example.com,55,12345,wiegand</p>
            </UniConditionalRender>
            {/* Corp1K Example Line */}
            <UniConditionalRender visible={this.props.partnerCredImplAvailability.includes(ECredentialType.corp1000)}>
              <p>address3@example.com,44,65432,corp1000</p>
            </UniConditionalRender>
            {/* 37Bit w/o Facility Example Line */}
            <UniConditionalRender visible={this.props.partnerCredImplAvailability.includes(ECredentialType.tsbNfacility)}>
              <p>address4@example.com,,987654321,37bit</p>
            </UniConditionalRender>
            {/* 37Bit w Facility Example Line */}
            <UniConditionalRender visible={this.props.partnerCredImplAvailability.includes(ECredentialType.tsbWfacility)}>
              <p>address5@example.com,88,5678,37bitwfacility</p>
            </UniConditionalRender>
            {/* Partner Default Credential Example Line -- type not provided intentionally to show it will be assumed as partner default*/}
            <p>address6@example.com,123,12345,</p>

          </UniConditionalRender>
          {/* Partner Specific CSV Template */}
          <UniConditionalRender visible={this.props.partnerCsvExampleTemplate} >
            {this.props.partnerCsvExampleTemplate}
          </UniConditionalRender>

        </div>

        <p><UniLocalize translate="_defaultCSVTypeIfNotProvided" /> <strong><UniLocalize translate={ECredentialType[this.props.partnerDefaultCredentialType]} /></strong></p>

        <h4><UniLocalize translate="credentialCsvFile" /></h4>

        <UniFileUpload
          placeholderKey="file"
          handleUpdate={this._handleCSVFileChange} />

        <UniConditionalRender visible={this.state.insufficientDealerCredits || this.state.insufficientOrgCredits} >
          <p className="warn-message">
            <span>
              <UniIcon
                className="inline-icon"
                name="warning"
                size="sm" />
            </span>
            <span className="inline-message">
              <UniLocalize translate="insufficientCredits" />
            </span>
          </p>
        </UniConditionalRender>

        {/* step actions */}
        <UniOverlapGroup foldEarly={true}>
          <UniOverlapButton
            handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex + 1)}
            textKey="goNext"
            icon="navigateNext"
            disabled={!this.props.bulkUploadValid || this.state.insufficientDealerCredits || this.state.insufficientOrgCredits} />
          <UniOverlapButton
            handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
            textKey="goPrevious"
            icon="navigateBefore"
            secondary={true}
            disabled={this.props.currentStepIndex === 0} />
          <UniOverlapButton
            handleClick={this._clearAndToggleCreateCredentialModal}
            textKey="cancel"
            icon="close"
            secondary={true} />
        </UniOverlapGroup>
      </>
    );

    // only evaluate if we're not in the middle of a save
    const allSelectedBulkCredsValid = this.props.loading ? true : Array.from(this.props.selectedBulkCredentials.values()).every((selectedCred: IBulkCredentialC): boolean => {
      return (Array.from(selectedCred.config.requiredFields?.()!.values()) as string[]).every((requiredField: string): boolean => {
        const valid = !!(selectedCred.parts as any)[requiredField]?.valid;
        return valid;
      }) && !!selectedCred.parts.type!.valid;
    });
    const bulkUploadReview = (
      <>

        <div className="review">
          {/* Show the table for selecting credentials to save if there are any remaining */}
          <UniConditionalRender visible={this.props.remainingBulkCredentials.length > 0}>

            <h4><UniLocalize translate={this.props.successfulBulkCredentials.length > 0 ? 'credentialsToAdd' : 'remainingCredentialsToAdd'} /></h4>
            <p>{this.props.selectedBulkCredentials.size} of {this.props.remainingBulkCredentials.length}</p>

            {/* selectable list of organizations to make user a limited admin of */}
            <UniTable
              selectable={true}
              selectDisabled={this.props.loading}
              selectedItems={this.props.selectedBulkCredentials}
              searchable={false}
              handleUpdate={noop}
              handleRowItemSelectToggle={this._toggleSelectedBulkCredential}
              evalSelectableRowDisabled={() => false}
              createButtonTextKey="credentials"
              data={this.props.remainingBulkCredentials}
              columnConfig={columns}
              actionsConfig={actions}
              showLoader={false}
              preventDynamicColumnHiding={true}
              footerStyle={EUniTable_FooterStyle.none} />
          </UniConditionalRender>

          {/* If all the credentials from the CSV were successful, show a final success message to the user */}
          <UniConditionalRender visible={this.props.remainingBulkCredentials.length === 0}>
            <p><UniLocalize translate="_allCredentialsAdded" /></p>
          </UniConditionalRender>

          <UniConditionalRender visible={this.props.successfulBulkCredentials.length > 0}>
            {/* display for the credentials already added */}
            <UniChips
              labelKey="credentialSuccessfullyAdded"
              collection={this.props.successfulBulkCredentials.reduce((credMap: Map<string, any>, cred: IBulkCredentialC, currIndex: number) => {
                return credMap.set(cred.id, { nameKey: cred.parts.email!.value });
              }, new Map())}
              disabled={true}
              maxCount={7}
              handleRemoveChip={noop} />
          </UniConditionalRender>
        </div>

        {/* step actions */}
        <UniOverlapGroup foldEarly={true}>
          {/*
            Show SAVE button when not all are saved, DONE button if all are saved
            this was added to alleviate confusion around completed bulk uploads
            Reusing the button and swapping out the functions/text/icons...
            ...due to how the overlap groups positions based on number of elements
          */}
          <UniOverlapButton
            id="bulk-upload-save-done-reused-overlap-btn"
            textKey={this.props.remainingBulkCredentials.length === 0 ? "done" : "save"}
            handleClick={() => this.props.remainingBulkCredentials.length === 0 ? this._clearAndToggleCreateCredentialModal() : this._createBulkCredentials()}
            icon={this.props.remainingBulkCredentials.length === 0 ? "done" : "save"}
            disabled={this.props.remainingBulkCredentials.length === 0 ? false : !this.props.bulkUploadValid || !allSelectedBulkCredsValid || this.props.selectedBulkCredentials.size === 0 || !this.props.dealerHasAvailableCredits || this.props.loading}
            showLoader={!!this.props.loading} />
          <UniOverlapButton
            handleClick={() => this.props.changeWorkflowStep(this.props.currentStepIndex - 1)}
            textKey="goPrevious"
            icon="navigateBefore"
            secondary={true}
            disabled={this.props.currentStepIndex === 0 || this.props.loading}
            tooltipPosition="right" />
          <UniOverlapButton
            handleClick={this._clearAndToggleCreateCredentialModal}
            textKey="cancel"
            icon="close"
            disabled={this.props.loading}
            secondary={true} />
        </UniOverlapGroup>

      </>
    );
    return (
      <section className='credential-create-container'>
        <UniWorkflow
          inModal
          titleKey={this.props.bulkUploadMode ? 'bulkCredentialUpload' : 'issueNewCredential'}
          titleIcon="vpnKey"
          size={this.props.bulkUploadMode && this.props.currentStepIndex > 0 ? 'widest' : 'wider'}
          handleClose={this.props.toggleCreateCredentialModal}>

          <UniLoader
            hidden={!this.props.loading}
            type="linear"
            percentageComplete={this.props.bulkUploadMode ? this.props.bulkCredentialsSaveJobPercentageComplete : undefined}
            secondaryPercentageComplete={this.props.bulkUploadMode ? this.props.bulkCredentialsSaveJobPercentageInflight : undefined} />

          <UniSteps
            steps={this.steps}
            activeStepIndex={this.props.currentStepIndex}
            allStepsUnlocked={false}
            handleStepChange={(val: number) => this.props.changeWorkflowStep(val)} />

          {/* First Step */}
          <UniConditionalRender visible={this.props.currentStepIndex === 0}>

            <UniConditionalRender visible={!this.props.preventBulkUpload} >
              <UniToggle
                size="md"
                options={[
                  { value: false, nameKey: 'singleCredential' },
                  { value: true, nameKey: 'bulkCredentialUpload' }
                ]}
                value={this.props.bulkUploadMode}
                handleUpdate={this._handleBulkModeChange} />
            </UniConditionalRender>

            <UniConditionalRender visible={!this.props.bulkUploadMode}>
              {singleCredentialForm}
            </UniConditionalRender>

            <UniConditionalRender visible={this.props.bulkUploadMode}>
              {bulkUploadForm}
            </UniConditionalRender>

          </UniConditionalRender>
          {/* End First Step */}

          {/* Step 2 - Review */}
          <UniConditionalRender visible={this.props.currentStepIndex === 1}>

            <UniConditionalRender visible={!this.props.bulkUploadMode}>
              {singleCredentialReview}
            </UniConditionalRender>

            <UniConditionalRender visible={this.props.bulkUploadMode}>
              {bulkUploadReview}
            </UniConditionalRender>

          </UniConditionalRender>
          {/* End step 2 */}

        </UniWorkflow>
      </section>
    )
  }
}

function mapStateToProps(state: any, ownProps: IProps) {
  return {
    dealerHasAvailableCredits: state.dealer.dealerData.availableCredentials > 0,
    dealerAvailableCredits: state.dealer.dealerData.availableCredentials,
    dealerContactPhoneNumber: state.dealer.dealerData.phoneNumber,
    dealerUnallocatedCreditsAvailable: state.dealer.dealerData.availableCredentials - state.dealer.dealerData.unclaimedAllocatedCredentials,
    orgDetails: state.orgDetails.origOrg,    

    loading: state.newCredential.loading,
    currentStepIndex: state.newCredential.workflowStepIndex,
    // single
    credsMatchingEmail: state.newCredential.single.matching.email,
    credsMatchingCardData: state.newCredential.single.matching.cardData,
    credsMatchingDuplicatesInvited: state.newCredential.single.matching.duplicatesInvited,
    credsMatchingDuplicates: state.newCredential.single.matching.duplicates,
    newSingleCred: state.newCredential.single,
    type: state.newCredential.single.type,
    email: state.newCredential.single.email,
    facilityCode: state.newCredential.single.facility,
    cardNumber: state.newCredential.single.cardNumber,
    credentialDataFormat: state.newCredential.single.credentialDataFormat,
    credentialDataB64: state.newCredential.single.credentialDataB64,
    credentialDataHex: state.newCredential.single.credentialDataHex,
    credentialDataBin: state.newCredential.single.credentialDataBin,
    credentialDataBitLength: state.newCredential.single.credentialDataBitLength,
    isInstaller: state.newCredential.single.isInstaller,
    // bulk
    bulkCsvColumnOrder: state.newCredential.bulk.bulkCsvColumnOrder,
    bulkUploadMode: state.newCredential.bulk.bulkMode,
    bulkUploadValid: state.newCredential.bulk.bulkValid,
    bulkCredentials: state.newCredential.bulk.bulkCredentials,
    remainingBulkCredentials: state.newCredential.bulk.remainingBulkCredentials,
    selectedBulkCredentials: state.newCredential.bulk.selectedBulkCredentials,
    numSelectedBulkCredentials: state.newCredential.bulk.selectedBulkCredentials?.size,
    successfulBulkCredentials: state.newCredential.bulk.successfulBulkCredentials,
    bulkCredentialsSaveJobPercentageComplete: calcLoaderPercentageComplete(state.newCredential.bulk.jobReqsOutstanding, state.newCredential.bulk.jobReqsTotal),
    bulkCredentialsSaveJobPercentageInflight: calcLoaderPercentageComplete(state.newCredential.bulk.jobReqsQueued, state.newCredential.bulk.jobReqsTotal),

    // permissions
    permissionToCreateCredential: canI(EOperationCodesC.CreateCredential, state.dealer.dealerData.id, ownProps.match?.params?.organizationId),
    // subscription
    subCredCountRelevant: s10n(ES10nSettings.CredCountRelevant),
  };
}

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => {
  return bindActionCreators({
    createNewCredential: attemptCreateCredential,
    createBulkCredentialsInBatch: attemptCreateCredentialsInBulk,
    getOrgDetails: attemptRetrieveOrgDetails,
    reissueCredential: attemptReissueCredential.bind(null, ownProps.partnerCredImpls),
    updateCredentialListForCurrentOrg: attemptRetrieveCredentialsList,
    updateNewCredential: handleNewCredentialChange,
    updateBulkCredential: handleBulkUpdate,
    clearNewCredentialForm: wipeNewCredentialFormData,
    changeWorkflowStep: updateNewCredentialWorkflowStep,
    toggleCreateCredentialModal,
    showAlertNotice: addAlert,
    checkForSimilarCredsInOrg,
    dismissAllAlerts,

  }, dispatch)
}

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(CredentialCreateContainer)
  ), { componentName : 'CredentialCreate' }
)