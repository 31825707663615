import {
  CredentialC,
  Editable
} from '@unikey/unikey-commons/release/comm'

import {
  api,
  credentialActions,
  inputInitializer,
  IBulkCredentialC,
  getPaginatedTableMetaChanges,
  populateInitialReduxPaginatedListState,
} from '../../internal'


const initialCredentialsState = populateInitialReduxPaginatedListState(['email'], 'forward');
export const credentials = (state = initialCredentialsState, action: any) => {
  switch (action.type) {
    case credentialActions.GET_CREDENTIALS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case credentialActions.GET_CREDENTIALS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        data: action.value,
        queryParams: Object.assign({}, state.queryParams, { sort: action.value.sort })
      }
    case credentialActions.GET_CREDENTIALS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        data: initialCredentialsState.data
      }
    case credentialActions.UPDATE_CREDENTIAL_QUERY_PARAMS:
      return {
        ...state,
        queryParams: Object.assign({}, state.queryParams, action.queryParams)
      }
    case credentialActions.UPDATE_CREDENTIALS_TABLE_META:
      const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
      return {
        ...state,
        ...stateToUpdate
      }
    default:
      return state
  }
}


const initialCredentialDetailsState = {
  loading: true,
  credentialData: {},
  credentialDevices: [],
  ...inputInitializer('', ['copyToEmail'])
};

export const credentialDetails = (state = initialCredentialDetailsState, action: any) => {
  switch (action.type) {
    case credentialActions.GET_SINGLE_CREDENTIAL_REQUEST:
      return {
        ...state,
        loading: action.pending
      }
    case credentialActions.GET_SINGLE_CREDENTIAL_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        credentialData: action.value,
        credentialDevices: action.value.devices || [],
        copyToEmail: { value: action.value.email, valid: true }
      }
    case credentialActions.GET_SINGLE_CREDENTIAL_FAILURE:
      return {
        ...state,
        ...inputInitializer('', ['copyToEmail']),
        loading: action.pending,
        credentialData: initialCredentialDetailsState.credentialData,
        credentialDevices: initialCredentialDetailsState.credentialDevices,
      }

    case credentialActions.SINGLE_CRED_ACTION_REQUEST:
      return {
        ...state,
        loading: action.pending
      }
    case credentialActions.SINGLE_CRED_ACTION_SUCCESS:
    case credentialActions.SINGLE_CRED_ACTION_FAILURE:
      return {
        ...state,
        loading: action.pending
      }

    case credentialActions.REVOKE_DEVICE_REQUEST:
      return {
        ...state,
        loading: action.pending,
      }
    case credentialActions.REVOKE_DEVICE_SUCCESS:
      return {
        ...state,
        loading: action.pending,
      }
    case credentialActions.REVOKE_DEVICE_FAILURE:
      return {
        ...state,
        loading: action.pending,
      }

    case credentialActions.HANDLE_COPY_TO_EMAIL_CHANGE:
      return {
        ...state,
        copyToEmail: action.email
      }
    default:
      return state
  }
}

var numOutstanding;
const freshCredentialFields = inputInitializer('', ['email', 'type', 'facility', 'cardNumber', 'credentialDataB64', 'credentialDataHex', 'credentialDataBin', 'credentialDataFormat', 'credentialDataBitLength', 'label']);
function initialMatchingCardsState() {
  return {
    email: ([] as CredentialC[]),
    cardData: ([] as CredentialC[]),
    duplicates: ([] as CredentialC[]),
    duplicatesInvited: ([] as CredentialC[])
  }
}
const initialNewCredentialState = {
  modalOpen: false,
  loading: false,
  workflowStepIndex: 0,
  single: ({
    ...freshCredentialFields,
    matching: initialMatchingCardsState(),
    credentialDataFormat: new Editable<string>({ value: 'hex' }),
    credentialDataHex: new Editable<string>({ value: '0x', valid: false }), // filling field with `0x...` intially.
    isInstaller: false
  } as any),
  bulk: {
    bulkCsvColumnOrder: {},
    bulkValid: false,
    bulkMode: false,
    bulkLines: [],
    bulkFile: undefined,
    bulkCredentials: [],
    selectedBulkCredentials: new Map<string, IBulkCredentialC>(),
    successfulBulkCredentials: [],
    remainingBulkCredentials: [],
    numWavesQueued: 0,
    waveSize: 1,
    jobReqsTotal: 1,
    jobReqsQueued: 0,
    jobReqsOutstanding: 0

  }
};

export const newCredential = (state = initialNewCredentialState, action: any) => {
  switch (action.type) {
    case credentialActions.TOGGLE_CREATE_CREDENTIAL_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen
      }
    case credentialActions.CREATE_CREDENTIAL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case credentialActions.CREATE_CREDENTIAL_SUCCESS:
      numOutstanding = api.pendingReqs.getNum(credentialActions.CREATE_CREDENTIAL_REQUEST);
      return {
        ...state,
        loading: numOutstanding > 0,
      }
    case credentialActions.CREATE_CREDENTIAL_FAILURE:
      numOutstanding = api.pendingReqs.getNum(credentialActions.CREATE_CREDENTIAL_REQUEST);
      return {
        ...state,
        // Dont modify `data: { ... }` here since it will reset the credential screen.
        // We instead want to give the user the chance to update and resave
        loading: numOutstanding > 0,
      }
    case credentialActions.CREATE_CREDENTIAL_CLEAR:
      return {
        ...state,
        workflowStepIndex: 0,
        bulk: {
          ...state.bulk,
          bulkValid: false,
          bulkCsvColumnOrder: {},
          selectedBulkCredentials: new Map<string, IBulkCredentialC>(),
          successfulBulkCredentials: [],
          remainingBulkCredentials: [],
        },
        single: ({
          ...freshCredentialFields,
          credentialDataFormat: new Editable<string>({ value: 'hex' }),
          credentialDataHex: new Editable<string>({ value: '0x', valid: false }), // filling field with `0x...` intially.
          matching: initialMatchingCardsState(),
          isInstaller: false
        } as any)
      }
    case credentialActions.UPDATE_NEW_CREDENTIAL_WORKFLOW_STEP:
      return {
        ...state,
        workflowStepIndex: action.stepTo
      }
    case credentialActions.HANDLE_NEW_CREDENTIAL_UPDATE:
      return {
        ...state,
        single: {
          matching: state.single.matching,
          email: action.formParts.email ? action.formParts.email : state.single.email,
          cardNumber: action.formParts.cardNumber ? action.formParts.cardNumber : state.single.cardNumber,
          facility: action.formParts.facility ? action.formParts.facility : state.single.facility,
          credentialDataFormat: action.formParts.credentialDataFormat ? action.formParts.credentialDataFormat : state.single.credentialDataFormat,
          credentialDataB64: action.formParts.credentialDataB64 ? action.formParts.credentialDataB64 : state.single.credentialDataB64,
          credentialDataHex: action.formParts.credentialDataHex ? action.formParts.credentialDataHex : state.single.credentialDataHex,
          credentialDataBin: action.formParts.credentialDataBin ? action.formParts.credentialDataBin : state.single.credentialDataBin,
          credentialDataBitLength: action.formParts.credentialDataBitLength ? action.formParts.credentialDataBitLength : state.single.credentialDataBitLength,
          isInstaller: action.isInstaller !== undefined ? action.isInstaller : state.single.isInstaller,
          type: action.formParts.type ? action.formParts.type : state.single.type
        }
      }
    case credentialActions.SET_MATCHING_CREDS_FOR_REVIEW:
      return {
        ...state,
        single: {
          ...state.single,
          matching: {
            email: action.email,
            cardData: action.cardData,
            duplicates: action.duplicates,
            duplicatesInvited: action.duplicatesInvited
          }
        }
      }
    case credentialActions.HANDLE_BULK_UPDATE:
      return {
        ...state,
        bulk: {
          ...state.bulk,
          bulkCsvColumnOrder: action.bulkCsvColumnOrder !== undefined ? action.bulkCsvColumnOrder : state.bulk.bulkCsvColumnOrder,
          bulkMode: action.bulkMode !== undefined ? action.bulkMode : state.bulk.bulkMode,
          bulkFile: action.bulkFile ? action.bulkFile : state.bulk.bulkFile,
          bulkLines: action.bulkCsvLines ? action.bulkCsvLines : state.bulk.bulkLines,
          bulkCredentials: action.bulkCsvCredentials !== undefined ? action.bulkCsvCredentials : state.bulk.bulkCredentials,
          bulkValid: action.bulkValid !== undefined ? action.bulkValid : state.bulk.bulkValid,
          selectedBulkCredentials: action.bulkCredentialsSelected !== undefined ? action.bulkCredentialsSelected : state.bulk.selectedBulkCredentials,
          successfulBulkCredentials: action.bulkCredentialsSuccessful !== undefined ? action.bulkCredentialsSuccessful : state.bulk.successfulBulkCredentials,
          remainingBulkCredentials: action.bulkCredentialsRemaining !== undefined ? action.bulkCredentialsRemaining : state.bulk.remainingBulkCredentials,
        }
      }
    case credentialActions.BULK_CREDENTIAL_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case credentialActions.BULK_CREDENTIAL_CREATE_SUCCESS:
      return {
        ...state,
        bulk: {
          ...state.bulk,
          selectedBulkCredentials: action.updatedSelectedCreds !== undefined ? action.updatedSelectedCreds : state.bulk.selectedBulkCredentials,
          successfulBulkCredentials: action.updatedSuccessfulCreds !== undefined ? action.updatedSuccessfulCreds : state.bulk.successfulBulkCredentials,
          remainingBulkCredentials: action.updatedRemainingCreds !== undefined ? action.updatedRemainingCreds : state.bulk.remainingBulkCredentials,
        }
      }
    case credentialActions.BULK_CREDENTIAL_CREATE_FAILURE:
      return {
        ...state,
        bulk: {
          ...state.bulk,
          remainingBulkCredentials: action.updatedRemainingCreds !== undefined ? action.updatedRemainingCreds : state.bulk.remainingBulkCredentials,
        }
      }

    case credentialActions.BULK_CREDENTIAL_WAVE_UPDATE:
      return {
        ...state,
        bulk: {
          ...state.bulk,
          numWavesQueued: action.wavesRemaining !== undefined ? action.wavesRemaining : state.bulk.numWavesQueued,
          selectedBulkCredentials: action.selected !== undefined ? action.selected : state.bulk.selectedBulkCredentials,
          successfulBulkCredentials: action.successful !== undefined ? action.successful : state.bulk.successfulBulkCredentials,
          remainingBulkCredentials: action.remaining !== undefined ? action.remaining : state.bulk.remainingBulkCredentials,
        }
      }
    case credentialActions.UPDATE_BULK_CREDENTIAL_CREATE_JOB_STATUS:
      const ubccjsWaveSize = action.waveSize !== undefined ? action.waveSize : state.bulk.waveSize;
      const ubccjsWavesQueued = action.numWavesQueued !== undefined ? action.numWavesQueued : state.bulk.numWavesQueued;
      return {
        ...state,
        loading: action.loading !== undefined ? action.loading : state.loading,
        bulk: {
          ...state.bulk,
          waveSize: ubccjsWaveSize,
          numWavesQueued: ubccjsWavesQueued,
          jobReqsTotal: (action.numOutstandingBulkReqs > state.bulk.jobReqsTotal) ? action.numOutstandingBulkReqs : state.bulk.jobReqsTotal,
          jobReqsOutstanding: action.numOutstandingBulkReqs !== undefined ? action.numOutstandingBulkReqs : state.bulk.jobReqsOutstanding,
          jobReqsQueued: ubccjsWaveSize * ubccjsWavesQueued
        }
      }

    default:
      return state
  }
}
