import {
  OrganizationC,
  Editable
} from '@unikey/unikey-commons/release/comm';

import {
  api,
  orgActions,
  portalActions,
  inputInitializer,
  getPaginatedTableMetaChanges,
  populateInitialReduxPaginatedListState,
} from '../../internal'

const initialOrgState = populateInitialReduxPaginatedListState(['name'], 'forward');
export const organizations = (state = initialOrgState, action: any) => {
  switch (action.type) {
    case orgActions.GET_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case orgActions.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        data: action.value,
        queryParams: Object.assign({}, state.queryParams, { sort: action.value.sort })
      }
    case orgActions.GET_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        data: initialOrgState.data
      }
      case orgActions.UPDATE_ORGANIZATIONS_QUERY_PARAMS:
        return {
          ...state,
          queryParams: Object.assign({}, state.queryParams, action.queryParams)
        }
      case orgActions.UPDATE_ORGANIZATIONS_TABLE_META:
        const stateToUpdate = getPaginatedTableMetaChanges(action, state.paginationSummary);
        return {
          ...state,
          ...stateToUpdate
        }
      case portalActions.DEALER_CONTEXT_SET: 
        return {
          ...initialOrgState
        }
      default:
        return state
      }
  }

  const initialCreateOrgState = {
    loading: false,
    newOrg: ({
        unlimitedCredits: true,
        ...inputInitializer('', ['name', 'creditAllocation'])
      } as any),
      modalOpen: false,
    };
  export const createOrg = (state = initialCreateOrgState, action: any) => {
    switch (action.type) {
    case orgActions.HANDLE_NEW_ORG_CHANGE:
      return {
        ...state,
        newOrg: {
          unlimitedCredits: action.unlimitedCredits !== undefined ? action.unlimitedCredits === true : state.newOrg.unlimitedCredits,
          name: action.name ? action.name : state.newOrg.name,
          creditAllocation: action.creditAllocation ? action.creditAllocation : state.newOrg.creditAllocation,
        }
      }
    case orgActions.CREATE_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case orgActions.CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        modalOpen: false,
        newOrg: {
          ...inputInitializer('', ['name', 'creditAllocation']),
          unlimitedCredits: true,
        }
      }
    case orgActions.CREATE_ORGANIZATION_FAILURE:
      return {
        ...state,
        loading: action.pending
      }
    case orgActions.TOGGLE_CREATE_ORG_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen
      }
    default:
      return state
  }
}

const initialOrgDetailsState = {
  loading: true,
  orgData: ({
    ...inputInitializer('', ['name', 'status']),
    id: '',
  } as any),
  origOrg: {},
  originalName: '',
  orgReaders: {},
  editCredits: {
    ...inputInitializer('', ['claimed', 'allocated']),
    ...inputInitializer(true, ['isLimited'])
  }
};

const setEditCreditsFieldsBasedOnOrgResult = (orgResult: OrganizationC) => {
  const isLimited: boolean = orgResult.creditsAllocated !== null;

  return {
    claimed: new Editable<number>({ value: orgResult.creditsClaimed }),
    isLimited: new Editable<boolean>({ value: isLimited }),
    allocated: new Editable<number>({ value: isLimited ? orgResult.creditsAllocated : NaN })
  }
}

const setEditOrgFieldsBasedOnOrgResult = (orgResult: OrganizationC) => {
  return {
    id: orgResult.id,
    name: new Editable<string>({ value: orgResult.name }),      
    status: new Editable<number>({ value: orgResult.status! })
  }
}

export const orgDetails = (state = initialOrgDetailsState, action: any) => {
  switch (action.type) {
    case orgActions.GET_ORG_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case orgActions.GET_ORG_DETAILS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        originalName: action.value.name,
        origOrg: action.value,
        orgData: setEditOrgFieldsBasedOnOrgResult(action.value),
        editCredits: setEditCreditsFieldsBasedOnOrgResult(action.value)
      }
    case orgActions.GET_ORG_DETAILS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        origOrg: {},
        orgData: setEditOrgFieldsBasedOnOrgResult(({} as OrganizationC)),
      }
    case orgActions.UPDATE_ORG_DETAILS_REQUEST:
      return {
        ...state,
        loading: action.pending,
      }
    case orgActions.UPDATE_ORG_DETAILS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        orgData: setEditOrgFieldsBasedOnOrgResult(action.value)
      }
    case orgActions.UPDATE_ORG_DETAILS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        orgData: setEditOrgFieldsBasedOnOrgResult(action.value)
      }
    case orgActions.HANDLE_ORG_DETAILS_CHANGE:
      return {
        ...state,
        orgData: {
          id: state.orgData.id,
          name: action.name ? action.name : state.orgData.name,
          status: action.status ? action.status : state.orgData.status
        }
      }
    case orgActions.GET_ORG_READERS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case orgActions.GET_ORG_READERS_FAILURE:
      return {
        ...state,
        loading: api.pendingReqs.getNum(orgActions.GET_ORG_READERS_REQUEST) > 0,
        orgData: {}
      }
    case orgActions.GET_ORG_READERS_SUCCESS:
      return {
        ...state,
        loading: api.pendingReqs.getNum(orgActions.GET_ORG_READERS_REQUEST) > 0,
        orgReaders: action.readersResult
      }
    case orgActions.UPDATE_ORG_CREDITS_REQUEST:
      return {
        ...state,
        loading: action.pending,
      }
    case orgActions.UPDATE_ORG_CREDITS_SUCCESS:
      return {
        ...state,
        loading: action.pending,
        editCredits: setEditCreditsFieldsBasedOnOrgResult(action.value)
      }
    case orgActions.UPDATE_ORG_CREDITS_FAILURE:
      return {
        ...state,
        loading: action.pending,
        editCredits: setEditCreditsFieldsBasedOnOrgResult(action.value)
      }
    case orgActions.HANDLE_ORG_CREDITS_CHANGE:
      return {
        ...state,
        editCredits: {
          claimed: state.editCredits.claimed, // cant change here
          isLimited: action.isLimited !== undefined ? action.isLimited : state.editCredits.isLimited,
          allocated: action.numAllocated ? action.numAllocated : state.editCredits.allocated,
        }
      }
    case portalActions.DEALER_CONTEXT_SET: 
      return {
        ...initialOrgDetailsState
      }
    default:
      return state
  }
}

