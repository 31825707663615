import {  
  ApiReduxAction,
} from '@unikey/unikey-commons/release/comm'

import {
  identityApi,
  IExposeRedux,
} from '../../internal'

export enum userActions {
  TOGGLE_CHANGE_PASSWORD_WORKFLOW = 'TOGGLE_CHANGE_PASSWORD_WORKFLOW',
  TOGGLE_ADD_PASSWORD_WORKFLOW = 'TOGGLE_ADD_PASSWORD_WORKFLOW',

  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  ADD_PASSWORD_EMAIL_REQUEST = 'ADD_PASSWORD_EMAIL_REQUEST',
  ADD_PASSWORD_EMAIL_SUCCESS = 'ADD_PASSWORD_EMAIL_SUCCESS',
  ADD_PASSWORD_EMAIL_FAILURE = 'ADD_PASSWORD_EMAIL_FAILURE',
  
  RESET_PASSWORD_DIRECT_REQUEST = 'RESET_PASSWORD_DIRECT_REQUEST',
  RESET_PASSWORD_DIRECT_SUCCESS = 'RESET_PASSWORD_DIRECT_SUCCESS',
  RESET_PASSWORD_DIRECT_FAILURE = 'RESET_PASSWORD_DIRECT_FAILURE'
}

export const toggleChangePasswordWorkflow = () => {
  return {
    type: userActions.TOGGLE_CHANGE_PASSWORD_WORKFLOW
  }
}

export const toggleAddPasswordWorkflow = () => {
  return {
    type: userActions.TOGGLE_ADD_PASSWORD_WORKFLOW
  }
}

const requestAddPasswordEmail = new ApiReduxAction(identityApi.acct, {
  request: { type: userActions.ADD_PASSWORD_EMAIL_REQUEST },
  success: {
    type: userActions.ADD_PASSWORD_EMAIL_SUCCESS,
    title: 'emailSent',
    message: 'checkEmailForInstructions',
    duration: 16000
  },
  failure: {
    type: userActions.ADD_PASSWORD_EMAIL_FAILURE,
    title: 'failedToSendEmail'
  }
}, (dux: IExposeRedux) => {
  return identityApi.acct.changePasswordIndirect.bind(identityApi.acct);
});
export const attemptRequestAddPasswordEmail = requestAddPasswordEmail.go;

export interface IChangePasswordActionParams {
  newPass: string,
  currPass: string
}
const resetPasswordDirect = new ApiReduxAction<IChangePasswordActionParams>(identityApi.acct, {
  request: { type: userActions.RESET_PASSWORD_DIRECT_REQUEST },
  success: {
    type: userActions.RESET_PASSWORD_DIRECT_SUCCESS,
    title: 'success',
    message: 'newPasswordSet'
  },
  failure: {
    type: userActions.RESET_PASSWORD_DIRECT_FAILURE,
    title: 'failedToUpdatePassword'
  }
}, (dux: IExposeRedux, { newPass, currPass }) => {
  return identityApi.acct.changePasswordDirect.bind(identityApi.acct, currPass, newPass);
});
export const attemptResetPasswordDirect = resetPasswordDirect.go;

