import { Dispatch } from 'redux'
import { push, getAction } from 'connected-react-router'
import bluebird from 'bluebird'

import {
  CredentialC,
  ECredentialStatus,
  ICredentialC,
  ICapabilityC,
  Editable,
  IMultiInputUpdate,
  IPaginationQueryBuilderParamsC,
  IUniTable_UpdatePaginationSummary,
  IPaginatedResponseC,
  ICredentialRequestC,
  ICredentialMatchesC,
  IPartnerCredImpls,
  IPartnerCredentialFields,
  EMessageType,
  ApiReduxAction,
} from '@unikey/unikey-commons/release/comm'

import {
  api,
  addAlert,
  redirectToLogin,
  setupJobTrackingFor,
  checkJobStatusOnInterval,
  chunk,
  IExposeRedux,
  portalRedirect,
  oops404Key
} from '../../internal'


export interface IGenericCredPartsFromCSV {
  email: Editable<string>,
  facilityCode: Editable<string>,
  cardNumber: Editable<string>,
  type: Editable<string>,
  credentialDataB64: Editable<string>,
  credentialDataHex: Editable<string>,
  credentialDataBitLength: Editable<string>,
  isInstaller: Editable<string>,
}

// TODO: not needeed now that we can bulk create any type of credential.
export interface IBulkCredentialC {
  id: string,
  parts: Partial<IGenericCredPartsFromCSV>
  config: Partial<IPartnerCredentialFields>,
  failed?: boolean,
  saved?: boolean,
  message?: string,
  built?: ICredentialRequestC,
  v10nTrigger?: boolean,
}

export enum credentialActions {
  GET_CREDENTIALS_REQUEST = 'GET_CREDENTIALS_REQUEST',
  GET_CREDENTIALS_SUCCESS = 'GET_CREDENTIALS_SUCCESS',
  GET_CREDENTIALS_FAILURE = 'GET_CREDENTIALS_FAIL',

  GET_SINGLE_CREDENTIAL_REQUEST = 'GET_SINGLE_CREDENTIAL_REQUEST',
  GET_SINGLE_CREDENTIAL_SUCCESS = 'GET_SINGLE_CREDENTIAL_SUCCESS',
  GET_SINGLE_CREDENTIAL_FAILURE = 'GET_SINGLE_CREDENTIAL_FAILURE',

  CREATE_CREDENTIAL_REQUEST = 'CREATE_CREDENTIAL_REQUEST',
  CREATE_CREDENTIAL_SUCCESS = 'CREATE_CREDENTIAL_SUCCESS',
  CREATE_CREDENTIAL_FAILURE = 'CREATE_CREDENTIAL_FAILURE',

  CREATE_CREDENTIAL_CLEAR = 'CREATE_CREDENTIAL_CLEAR',

  HANDLE_COPY_TO_EMAIL_CHANGE = 'HANDLE_COPY_TO_EMAIL_CHANGE',

  HANDLE_NEW_CREDENTIAL_UPDATE = 'HANDLE_NEW_CREDENTIAL_UPDATE',
  UPDATE_NEW_CREDENTIAL_WORKFLOW_STEP = 'UPDATE_NEW_CREDENTIAL_WORKFLOW_STEP',

  UPDATE_CREDENTIAL_QUERY_PARAMS = 'UPDATE_CREDENTIAL_QUERY_PARAMS',
  UPDATE_CREDENTIALS_TABLE_META = 'UPDATE_CREDENTIALS_TABLE_META',

  HANDLE_BULK_UPDATE = 'HANDLE_BULK_UPDATE',
  TOGGLE_CREATE_CREDENTIAL_MODAL = 'TOGGLE_CREATE_CREDENTIAL_MODAL',

  UPLOAD_CREDENTIAL_BULK_REQUEST = 'UPLOAD_CREDENTIAL_BULK_REQUEST',
  UPLOAD_CREDENTIAL_BULK_SUCCESS = 'UPLOAD_CREDENTIAL_BULK_SUCCESS',
  UPLOAD_CREDENTIAL_BULK_FAILURE = 'UPLOAD_CREDENTIAL_BULK_FAILURE',

  BULK_CREDENTIAL_CREATE_REQUEST = 'BULK_CREDENTIAL_CREATE_REQUEST',
  BULK_CREDENTIAL_CREATE_SUCCESS = 'BULK_CREDENTIAL_CREATE_SUCCESS',
  BULK_CREDENTIAL_CREATE_FAILURE = 'BULK_CREDENTIAL_CREATE_FAILURE',

  BULK_CREDENTIAL_WAVE_UPDATE = 'BULK_CREDENTIAL_WAVE_UPDATE',
  UPDATE_BULK_CREDENTIAL_CREATE_JOB_STATUS = 'UPDATE_BULK_CREDENTIAL_CREATE_JOB_STATUS',

  SET_MATCHING_CREDS_FOR_REVIEW = 'SET_MATCHING_CREDS_FOR_REVIEW',

  REVOKE_DEVICE_REQUEST = 'REVOKE_DEVICE_REQUEST',
  REVOKE_DEVICE_SUCCESS = 'REVOKE_DEVICE_SUCCESS',
  REVOKE_DEVICE_FAILURE = 'REVOKE_DEVICE_FAIL',

  SINGLE_CRED_ACTION_REQUEST = 'SINGLE_CRED_ACTION_REQUEST',
  SINGLE_CRED_ACTION_SUCCESS = 'SINGLE_CRED_ACTION_SUCCESS',
  SINGLE_CRED_ACTION_FAILURE = 'SINGLE_CRED_ACTION_FAILURE',
}

// Credentials
export interface IGetPartnerCredsActionParams {
  orgId: string,
  partnerCredImpls: IPartnerCredImpls
}

const getOrganizationCredentialsList = new ApiReduxAction<IGetPartnerCredsActionParams>(api.orgz, {
  request: { type: credentialActions.GET_CREDENTIALS_REQUEST },
  success: { type: credentialActions.GET_CREDENTIALS_SUCCESS },
  failure: {
    type: credentialActions.GET_CREDENTIALS_FAILURE,
    title: 'getCredentialsFail',
  },
  tableMetaUpdate: {
    type: credentialActions.UPDATE_CREDENTIALS_TABLE_META
  }
}, (dux: IExposeRedux, { orgId, partnerCredImpls }) => {
  const params = dux.getState().credentials.queryParams;
  return api.orgz.getOrgCredentials.bind(api.orgz, orgId, params, partnerCredImpls);
});
export const attemptRetrieveCredentialsList = getOrganizationCredentialsList.go;

export function updateCredentialListQueryParams(queryParams: IPaginationQueryBuilderParamsC) {
  return {
    type: credentialActions.UPDATE_CREDENTIAL_QUERY_PARAMS,
    queryParams
  }
}

export function updateCredentialsTableMeta(pagination: IUniTable_UpdatePaginationSummary) {
  return {
    type: credentialActions.UPDATE_CREDENTIALS_TABLE_META,
    ...pagination
  }
}

// GET SINGLE CRED
export interface IGetPartnerCredsByIdActionParams {
  credId: string,
  partnerCredImpls: IPartnerCredImpls
}
const getCredentialByIdAction = new ApiReduxAction<IGetPartnerCredsByIdActionParams>(api.cred, {
  request: { type: credentialActions.GET_SINGLE_CREDENTIAL_REQUEST },
  success: { type: credentialActions.GET_SINGLE_CREDENTIAL_SUCCESS },
  failure: {
    type: credentialActions.GET_SINGLE_CREDENTIAL_FAILURE,
    title: 'getCredentialFail',
  },
  handleErrorCodes: {
    404: (dux: IExposeRedux, err: any) => {
      portalRedirect(oops404Key);
      // not the error we were hoping to handle, so return an 
      // empty object to tell the action handler to handle as normal
      return { preventAlert: true };
    }
  }
}, (dux: IExposeRedux, { credId, partnerCredImpls }) => {
  return api.cred.getCredential.bind(api.cred, credId, partnerCredImpls);
});
export const attemptRetrieveCredentialById = getCredentialByIdAction.go;


// CREATE CREDENTIAL
export function wipeNewCredentialFormData() {
  return {
    type: credentialActions.CREATE_CREDENTIAL_CLEAR
  }
}
export function attemptCreateCredential(credentialToSave: ICredentialRequestC): any {
  return async (dispatch: Dispatch<any>, getState: any): Promise<any> => {
    dispatch(createCredentialRequest())
    const tracking = setupJobTrackingFor(credentialActions.CREATE_CREDENTIAL_REQUEST);
    try {
      const credentialResult = await api.cred.createCredential(credentialToSave, tracking);
      dispatch(createCredentialSuccess(credentialResult))
      dispatch(addAlert({
        id: Date.now(),
        titleKey: 'createdCredential',
        type: EMessageType.success,
        messageKeys: ['newCredentialSuccessfullyCreated']
      }));
      return credentialResult;
    } catch (err: any) {
      if (err.status === 400) {
        if (err.sub_status === 'insufficient_dealer_credits') {
          dispatch(addAlert({
            id: Date.now(),
            titleKey: 'createCredentialFail',
            type: EMessageType.error,
            messageKeys: ['_createCredentialInsufficientDealerCredits'],
          }));
        } else if (err.sub_status === 'no_coupon') {
          dispatch(addAlert({
            id: Date.now(),
            titleKey: 'createCredentialFail',
            type: EMessageType.error,
            messageKeys: ['_createCredentialNoCouponRedeemed'],
          }));
        } else {
          // catch-all error
          dispatch(addAlert({
            id: Date.now(),
            titleKey: 'createCredentialFail',
            type: EMessageType.error,
            messageKeys: [err.message || err]
          }));
        }
      } else if (err.status === 401) {
        dispatch(redirectToLogin())
      } else if (err.status === 409) {
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'createCredentialFail',
          type: EMessageType.error,
          messageKeys: ['_createCredential409Message'],
        }));
      } else {
        // catch-all error
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'createCredentialFail',
          type: EMessageType.error,
          messageKeys: [err.message || err]
        }));
      }
      dispatch(createCredentialFailure())
      return Promise.reject(err);
    }
  }
}

export function createCredentialRequest() {
  return {
    type: credentialActions.CREATE_CREDENTIAL_REQUEST
  }
}

export function createCredentialSuccess(credentialResult: CredentialC) {
  return {
    type: credentialActions.CREATE_CREDENTIAL_SUCCESS,
    credentialResult
  }
}

export function createCredentialFailure() {
  return {
    type: credentialActions.CREATE_CREDENTIAL_FAILURE,
  }
}

export function updateNewCredentialWorkflowStep(stepTo: number) {
  return {
    type: credentialActions.UPDATE_NEW_CREDENTIAL_WORKFLOW_STEP,
    stepTo
  };
}

// change
export function handleNewCredentialChange(formParts: IMultiInputUpdate, isInstaller?: boolean) {
  return {
    type: credentialActions.HANDLE_NEW_CREDENTIAL_UPDATE,
    formParts,
    isInstaller
  };
}


// BULK create credentials
export function attemptCreateCredentialsInBulk(credentialsToSave: IBulkCredentialC[]): any {
  return (dispatch: Dispatch<any>, getState: any): any => {

    // chunk the bulk request into "waves" so that not all the requests go out at once
    const waveSize = 1;
    const wavesOfCredentialsToSave: IBulkCredentialC[][] = chunk(credentialsToSave, waveSize);

    dispatch(startBulkCredentialRequest(wavesOfCredentialsToSave.length, waveSize));
    const stopJobStatusCheck = checkJobStatusOnInterval(dispatch.bind(null, peekBulkCredentialJobStatus()), 500);

    // outside bluebird.mapSeries is to iterate through the request waves,
    // inner bluebird.map is to do each request in each wave
    return bluebird.mapSeries(wavesOfCredentialsToSave, (credentialsInWaveToSave: IBulkCredentialC[], waveIndex: number) => {
      dispatch(bulkCredentialRequestWaveUpdate({
        wavesRemaining: wavesOfCredentialsToSave.length - (waveIndex + 1)
      }));

      return bluebird.map(credentialsInWaveToSave, (currToSave: IBulkCredentialC, indexInWave: number) => {
        const tracking = setupJobTrackingFor(credentialActions.BULK_CREDENTIAL_CREATE_REQUEST);
        dispatch(bulkCreateSingleCredentialRequest())
        return api.cred.createCredential(currToSave.built!, tracking).then((credentialResult: CredentialC) => {
          currToSave.failed = false;
          return Promise.resolve(currToSave);
        }, (err: any) => {
          if (err.status === 401) {
            dispatch(redirectToLogin())
          } else if (err.status === 409) {
            currToSave.message = '_createCredential409Message';
          } else {
            currToSave.message = err.message;
          }
          currToSave.failed = true;
          // resolving since we marked for the error already
          // and dont want to break out of the bluebird.map on a failure
          return Promise.resolve(currToSave);
        });
      }).then((credentialsAttempted: IBulkCredentialC[]): any => {
        // reconcile lists after wave
        const waveState = getState().newCredential.bulk;

        // reconcile the changes for each credential in the wave here so that we dont trigger
        // the component's render method so many times that the page performace suffers
        const upadatesAfterWave = credentialsAttempted.reduce((waveSummary: Partial<IBulkCredsWaveUpdate>, currCredential: IBulkCredentialC): Partial<IBulkCredsWaveUpdate> => {
          if (currCredential.failed) {
            // this credential was a FAILURE
            // mark the failure as unsuccessful and update the list
            const indexToRemove = getIndexOfCredInList(waveSummary.remaining!, currCredential);
            waveSummary.remaining!.splice(indexToRemove, 1, currCredential);
            // add the updated credential to the selected list
            waveSummary.selected!.set(currCredential.id, currCredential);

          } else {
            // this credential was saved SUCCESSfully
            // add this cred to the success list
            waveSummary.successful!.push(currCredential);

            // remove the cred from the list of remaining
            const indexToRemove = getIndexOfCredInList(waveSummary.remaining!, currCredential);
            waveSummary.remaining!.splice(indexToRemove, 1);

            // remove the successful credential from the selected group
            waveSummary.selected!.delete(currCredential.id);
          }
          return waveSummary;
        }, {
          selected: waveState.selectedBulkCredentials,
          successful: waveState.successfulBulkCredentials,
          remaining: waveState.remainingBulkCredentials,
        });

        // send the changes to the reducer to update the wave's results
        dispatch(bulkCredentialRequestWaveUpdate(upadatesAfterWave));
        return Promise.resolve();
      });
    }).finally(() => {
      const bulkStateAtFinally = getState().newCredential.bulk;
      if (bulkStateAtFinally.remainingBulkCredentials.length === 0) {
        // all in csv were successful
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'bulkCredentialsAdded',
          type: EMessageType.success,
          messageKeys: ['allCSVCredentialsWereAdded'],
        }));
      } else if (bulkStateAtFinally.selectedBulkCredentials.size === 0) {
        // message to report all selected were successful
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'bulkCredentialsAdded',
          type: EMessageType.success,
          messageKeys: ['allSelectedCredentialsWereAdded'],
        }));
      } else if (bulkStateAtFinally.successfulBulkCredentials.length === 0) {
        // all failed first time
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'bulkCredentialsFailed',
          type: EMessageType.error,
          messageKeys: ['bulkCompletelyFailed'],
        }));
      } else {
        // partially successful/failure
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'bulkCredentialsFailed',
          type: EMessageType.warn,
          messageKeys: ['bulkPartiallyFailed'],
        }));
      }
      stopJobStatusCheck();
      dispatch(finishBulkCredentialRequest());
    });
  }
}

export function bulkCreateSingleCredentialRequest() {
  return {
    type: credentialActions.BULK_CREDENTIAL_CREATE_REQUEST
  }
}

export interface IBulkCredsWaveUpdate {
  wavesRemaining: number,
  selected: Map<string, IBulkCredentialC>,
  successful: IBulkCredentialC[],
  remaining: IBulkCredentialC[]
}
export function bulkCredentialRequestWaveUpdate(waveUpdate: Partial<IBulkCredsWaveUpdate>) {
  return {
    type: credentialActions.BULK_CREDENTIAL_WAVE_UPDATE,
    ...waveUpdate
  }
}

export function peekBulkCredentialJobStatus() {
  return (dispatch: Dispatch<any>, getState: any): any => {
    const currBulk = getState().newCredential.bulk;
    const numFromWavesNotSent: number = currBulk.numWavesQueued * currBulk.waveSize;

    const numOutstandingBulkReqs = api.pendingReqs.getNum(credentialActions.BULK_CREDENTIAL_CREATE_REQUEST) + numFromWavesNotSent;
    return dispatch({
      type: credentialActions.UPDATE_BULK_CREDENTIAL_CREATE_JOB_STATUS,
      loading: true,
      numOutstandingBulkReqs,
    });
  }
}

export function startBulkCredentialRequest(numWaves: number, waveSize: number) {
  const numOutstandingBulkReqs = waveSize * numWaves;
  return {
    type: credentialActions.UPDATE_BULK_CREDENTIAL_CREATE_JOB_STATUS,
    loading: true,
    numOutstandingBulkReqs,
    numWavesQueued: numWaves,
    waveSize,
  }
}

export function finishBulkCredentialRequest() {
  const numOutstandingBulkReqs = api.pendingReqs.getNum(credentialActions.BULK_CREDENTIAL_CREATE_REQUEST);
  return {
    type: credentialActions.UPDATE_BULK_CREDENTIAL_CREATE_JOB_STATUS,
    loading: false,
    numOutstandingBulkReqs
  }
}

export function singleCredentialActionRequest() {
  return {
    type: credentialActions.SINGLE_CRED_ACTION_REQUEST,
  }
}

export function singleCredentialActionSuccess() {
  return {
    type: credentialActions.SINGLE_CRED_ACTION_SUCCESS
  }
}

export function singleCredentialActionFailure() {
  return {
    type: credentialActions.SINGLE_CRED_ACTION_FAILURE
  }
}

// revoke cred
const revokeCredentialByIdAction = new ApiReduxAction<string>(api.cred, {
  request: { type: credentialActions.SINGLE_CRED_ACTION_REQUEST },
  success: {
    type: credentialActions.SINGLE_CRED_ACTION_SUCCESS,
    title: 'revokedCredential',
    message: 'credentialHasBeenSuccessfullyRevoked'
  },
  failure: {
    type: credentialActions.SINGLE_CRED_ACTION_FAILURE,
    title: 'revokedCredentialFail',
  }
}, (dux: IExposeRedux, credId: string) => {
  return api.cred.revokeCredential.bind(api.cred, credId);
});
export const attemptRevokeCredential = revokeCredentialByIdAction.go;


// reissue cred
const reissueCredential = new ApiReduxAction<string>(api.cred, {
  request: { type: credentialActions.SINGLE_CRED_ACTION_REQUEST },
  success: {
    type: credentialActions.SINGLE_CRED_ACTION_SUCCESS,
    title: 'reissuedCredential',
    message: 'credentialInvitationEmailOnItsWay'
  },
  failure: {
    type: credentialActions.SINGLE_CRED_ACTION_FAILURE,
    title: 'reissueCredentialFail',
  }
}, (dux: IExposeRedux, credId: string) => {
  return api.cred.reissueCredential.bind(api.cred, credId);
});
export const attemptReissueCredential = reissueCredential.go;


export function handleCopyCredToEmailChange(email: Editable<string>) {
  return {
    type: credentialActions.HANDLE_COPY_TO_EMAIL_CHANGE,
    email
  };
}

// copy cred
export interface ICopyCredentialActionParams {
  credId: string,
  email?: string
}
const copyCredentialAction = new ApiReduxAction<ICopyCredentialActionParams>(api.cred, {
  request: { type: credentialActions.SINGLE_CRED_ACTION_REQUEST },
  success: {
    type: credentialActions.SINGLE_CRED_ACTION_SUCCESS,
    title: 'copiedCredential',
    message: 'credentialInvitationEmailOnItsWay'
  },
  failure: {
    type: credentialActions.SINGLE_CRED_ACTION_FAILURE,
    title: 'copyCredentialFail',
  }
}, (dux: IExposeRedux, { credId, email }) => {
  return api.cred.copyCredential.bind(api.cred, credId, email);
});
export const attemptCopyCredential = copyCredentialAction.go;

// disable cred
const disableCredentialAction = new ApiReduxAction<string>(api.cred, {
  request: { type: credentialActions.SINGLE_CRED_ACTION_REQUEST },
  success: {
    type: credentialActions.SINGLE_CRED_ACTION_SUCCESS,
    title: 'disabledCredential',
    message: 'credentialHasBeenSuccessfullyDisabled'
  },
  failure: {
    type: credentialActions.SINGLE_CRED_ACTION_FAILURE,
    title: 'disableCredentialFail',
  }
}, (dux: IExposeRedux, credId) => {
  return api.cred.disableCredential.bind(api.cred, credId);
});
export const attemptDisableCredential = disableCredentialAction.go;


// enable cred
const enableCredentialAction = new ApiReduxAction<string>(api.cred, {
  request: { type: credentialActions.SINGLE_CRED_ACTION_REQUEST },
  success: {
    type: credentialActions.SINGLE_CRED_ACTION_SUCCESS,
    title: 'enabledCredential',
    message: 'credentialHasBeenSuccessfullyEnabled'
  },
  failure: {
    type: credentialActions.SINGLE_CRED_ACTION_FAILURE,
    title: 'enableCredentialFail',
  }
}, (dux: IExposeRedux, credId: string) => {
  return api.cred.enableCredential.bind(api.cred, credId);
});
export const attemptEnableCredential = enableCredentialAction.go;

// delete cred
const deleteCredentialAction = new ApiReduxAction<string>(api.cred, {
  request: { type: credentialActions.SINGLE_CRED_ACTION_REQUEST },
  success: {
    type: credentialActions.SINGLE_CRED_ACTION_SUCCESS,
    title: 'deletedCredential',
    message: 'credentialPermanentlyDeleted'
  },
  failure: {
    type: credentialActions.SINGLE_CRED_ACTION_FAILURE,
    title: 'deletedCredentialFail',
  }
}, (dux: IExposeRedux, credId: string) => {
  return api.cred.deleteCredential.bind(api.cred, credId);
});
export const attemptDeleteCredential = deleteCredentialAction.go;


// update cred
const updateCredentialAction = new ApiReduxAction<CredentialC>(api.cred, {
  request: { type: credentialActions.SINGLE_CRED_ACTION_REQUEST },
  success: {
    type: credentialActions.SINGLE_CRED_ACTION_SUCCESS,
    title: 'updatedCredential',
    message: 'credentialHasBeenSuccessfullyUpdated'
  },
  failure: {
    type: credentialActions.SINGLE_CRED_ACTION_FAILURE,
    title: 'updateCredentialFail',
  }
}, (dux: IExposeRedux, existingCredential) => {
  const isCurrentlyInstaller: boolean = existingCredential.isInstaller || existingCredential.label === 'installer' ? true : false;
  const update: Partial<ICredentialRequestC> = {
    capabilities: (isCurrentlyInstaller ? ['card_data'] : ['pair', 'settings', 'software_update', 'card_data']).map((cap: string): ICapabilityC => ({ type: cap })),
    label: isCurrentlyInstaller ? 'user' : 'installer'
  };
  return api.cred.updateCredential.bind(api.cred, existingCredential.id, update);
});
export const attemptToggleCredentialPermissions = updateCredentialAction.go;

export interface IHandleBulkUpdateActionParams {
  bulkMode?: boolean,
  bulkFile?: any,
  bulkCsvLines?: string[],
  bulkCsvCredentials?: IBulkCredentialC[],
  bulkValid?: boolean,
  bulkCsvFailureReason?: string,
  bulkCsvNumSuccessful?: number
  bulkCsvNumErrored?: number,
  bulkCredentialsSelected?: Map<string, IBulkCredentialC>,
  bulkCredentialsSuccessful?: IBulkCredentialC[],
  bulkCredentialsRemaining?: IBulkCredentialC[],
  bulkCsvColumnOrder?: any,
}
// change bulk section of credential create
export function handleBulkUpdate(update: IHandleBulkUpdateActionParams) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    if (update.bulkCsvFailureReason) {
      dispatch(addAlert({
        id: Date.now(),
        titleKey: 'credentialCsvFile',
        type: EMessageType.error,
        messageKeys: [update.bulkCsvFailureReason],
        duration: 16000
      }))
    }
    dispatch({
      type: credentialActions.HANDLE_BULK_UPDATE,
      ...update
    });
  };
}

export function toggleCreateCredentialModal() {
  return {
    type: credentialActions.TOGGLE_CREATE_CREDENTIAL_MODAL,
  }
}

function getIndexOfCredInList(credArr: IBulkCredentialC[], cred: IBulkCredentialC): number {
  var foundIndex = 0;
  // array.some to exit as soon as we find the cred we're looking for
  credArr.some((currCred: IBulkCredentialC, index: number): boolean => {
    if (currCred.id === cred.id) {
      foundIndex = index;
      return true;
    }
    return false;
  });
  return foundIndex;
}

export function checkForSimilarCredsInOrg(orgId: string, credToCompare: ICredentialRequestC) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    return api.cred.checkForSimilarCredential(orgId, credToCompare).then((data: ICredentialMatchesC) => {
      dispatch(setSimilarCredentials(data))
    })
  };
}

function removeAnyAsDuplicates(credArray: CredentialC[], dupes: CredentialC[]) {
  return credArray.filter(currCred => {
    // if this does not match any of the creds marked as duplicates
    // then keep it in this array, otherwise it should be filtered out.
    return !dupes.some((dupeCred) => dupeCred.id === currCred.id);
  });
}

export function setSimilarCredentials(matching: ICredentialMatchesC) {
  // get a set of the credential ids from the matching email list
  const matchingEmailCredIds: Set<string> = matching.emailMatches.reduce((list: Set<string>, c: CredentialC) => {
    list.add(c.id);
    return list;
  }, new Set<string>());

  const dupes: CredentialC[] = [];
  const dupesPending: CredentialC[] = [];

  matching.cardDataMatches.forEach((currC: CredentialC) => {
    if (matchingEmailCredIds.has(currC.id)) {
      // this cred matches exactly-ish
      dupes.push(currC);

      // this cred was never accepted
      if (currC.status === ECredentialStatus.invited) {
        dupesPending.push(currC);
      }
    }
  });

  return {
    type: credentialActions.SET_MATCHING_CREDS_FOR_REVIEW,
    email: removeAnyAsDuplicates(matching.emailMatches, dupes),
    cardData: removeAnyAsDuplicates(matching.cardDataMatches, dupes),
    duplicates: dupes,
    duplicatesInvited: dupesPending
  }
}

// revoke device
export interface IRevokeDeviceActionParams {
  credId: string,
  deviceId: string
}
const revokeDeviceAction = new ApiReduxAction<IRevokeDeviceActionParams>(api.cred, {
  request: { type: credentialActions.REVOKE_DEVICE_REQUEST },
  success: {
    type: credentialActions.REVOKE_DEVICE_SUCCESS,
    title: 'deletedCredential',
    message: 'credentialPermanentlyDeleted'
  },
  failure: {
    type: credentialActions.REVOKE_DEVICE_FAILURE,
    title: 'deletedCredentialFail',
  }
}, (dux: IExposeRedux, {credId, deviceId }) => {
  return api.cred.revokeCredentialOnDevice.bind(api.cred, credId, deviceId);
});
export const attemptRevokeDevice = revokeDeviceAction.go;
