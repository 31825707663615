import { Dispatch } from 'redux'
import { push } from 'connected-react-router'

import {
  IUniChips_Chip,
  ApiReduxAction,
  Editable,
  ReaderC,
  IReaderC,
  IPaginationQueryBuilderParamsC,
  IUniTable_UpdatePaginationSummary,
  IUpdateReaderBodyC,
  IPaginatedResponseC,
  IReaderToEnrollRequestC,
  IMultiInputUpdate,
  IBulkReaderEnrollmentResponseC
} from '@unikey/unikey-commons/release/comm'

import {
  api,
  addAlert,
  redirectToLogin,
  setupJobTrackingFor,
  IExposeRedux,
  portalRedirect,
  oops404Key
} from '../../internal'

export enum readerActions {
  GET_READERS_REQUEST = 'GET_READERS_REQUEST',
  GET_READERS_SUCCESS = 'GET_READERS_SUCCESS',
  GET_READERS_FAILURE = 'GET_READERS_FAIL',

  GET_READER_DETAILS_REQUEST = 'GET_READER_DETAILS_REQUEST',
  GET_READER_DETAILS_SUCCESS = 'GET_READER_DETAILS_SUCCESS',
  GET_READER_DETAILS_FAILURE = 'GET_READER_DETAILS_FAILURE',

  HANDLE_READER_DETAILS_CHANGE = 'HANDLE_READER_DETAILS_CHANGE',

  UPDATE_READER_DETAILS_REQUEST = 'UPDATE_READER_DETAILS_REQUEST',
  UPDATE_READER_DETAILS_SUCCESS = 'UPDATE_READER_DETAILS_SUCCESS',
  UPDATE_READER_DETAILS_FAILURE = 'UPDATE_READER_DETAILS_FAILURE',

  UPDATE_READER_QUERY_PARAMS = 'UPDATE_READER_QUERY_PARAMS',
  UPDATE_READERS_TABLE_META = 'UPDATE_READERS_TABLE_META',

  DELETE_READER_REQUEST = 'DELETE_READER_REQUEST',
  DELETE_READER_SUCCESS = 'DELETE_READER_SUCCESS',
  DELETE_READER_FAILURE = 'DELETE_READER_FAILURE',

  TOGGLE_ENROLL_READERS_MODAL = 'TOGGLE_ENROLL_READERS_MODAL',
  UPDATE_ENROLL_READERS_WORKFLOW_STEP = 'UPDATE_ENROLL_READERS_WORKFLOW_STEP',

  GET_READER_ENROLLMENT_ELIGIBILITY_REQUEST = 'GET_READER_ENROLLMENT_ELIGIBILITY_REQUEST',
  GET_READER_ENROLLMENT_ELIGIBILITY_SUCCESS = 'GET_READER_ENROLLMENT_ELIGIBILITY_SUCCESS',
  GET_READER_ENROLLMENT_ELIGIBILITY_FAILURE = 'GET_READER_ENROLLMENT_ELIGIBILITY_FAILURE',

  HANDLE_SERIAL_NUMBER_CHANGE = 'HANDLE_SERIAL_NUMBER_CHANGE',
  UPDATE_SERIAL_NUMBER_ENROLLMENT_MAPS = 'UPDATE_SERIAL_NUMBER_ENROLLMENT_MAPS',
  CLEAR_SERIAL_NUMBER_ENROLLMENT_MAPS = 'CLEAR_SERIAL_NUMBER_ENROLLMENT_MAPS',

  ENROLL_READERS_IN_ORG_REQUEST = 'ENROLL_READERS_IN_ORG_REQUEST',
  ENROLL_READERS_IN_ORG_SUCCESS = 'ENROLL_READERS_IN_ORG_SUCCESS',
  ENROLL_READERS_IN_ORG_FAILURE = 'ENROLL_READERS_IN_ORG_FAILURE',
}

// Readers
const getOrganizationReaderList = new ApiReduxAction<string>(api.orgz, {
  request: { type: readerActions.GET_READERS_REQUEST },
  success: { type: readerActions.GET_READERS_SUCCESS },
  failure: {
    type: readerActions.GET_READERS_FAILURE,
    title: 'getReadersFail',
  },
  tableMetaUpdate: {
    type: readerActions.UPDATE_READERS_TABLE_META
  }
}, (dux: IExposeRedux, orgId) => {
  const params = dux.getState().readers.queryParams;
  return api.orgz.getOrgReaders.bind(api.orgz, orgId, params);
});
export const attemptRetrieveReadersList = getOrganizationReaderList.go;

export function updateReaderListQueryParams(queryParams: IPaginationQueryBuilderParamsC) {
  return {
    type: readerActions.UPDATE_READER_QUERY_PARAMS,
    queryParams
  }
}

export function updateReadersTableMeta(pagination: IUniTable_UpdatePaginationSummary) {
  return {
    type: readerActions.UPDATE_READERS_TABLE_META,
    ...pagination
  }
}

// reader enrollment 2.0
export interface IEligibiltyResult {
  serialNumber: string,
  reasonKey?: string
}

export function attemptCheckReaderEligibility(serialNumber: string): any {
  return (dispatch: Dispatch<any>, getState: any): any => {
    dispatch(checkReaderEnrollmentEligibilityRequest())
    const tracking = setupJobTrackingFor(readerActions.GET_READER_ENROLLMENT_ELIGIBILITY_REQUEST);
    return api.read.readerEligibleForEnrollment(serialNumber, tracking).then(() => {
      dispatch(checkReaderEnrollmentEligibilitySuccess())
      return { serialNumber };
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(checkReaderEnrollmentEligibilityFailure())
      }
      return Promise.reject({ serialNumber , reasonKey: err.reason });
    })
  }
}

export function checkReaderEnrollmentEligibilityRequest() {
  return {
    type: readerActions.GET_READER_ENROLLMENT_ELIGIBILITY_REQUEST
  }
}

export function checkReaderEnrollmentEligibilitySuccess() {
  return {
    type: readerActions.GET_READER_ENROLLMENT_ELIGIBILITY_SUCCESS,
  }
}

export function checkReaderEnrollmentEligibilityFailure() {
  return {
    type: readerActions.GET_READER_ENROLLMENT_ELIGIBILITY_FAILURE,
  }
}

export function handleSerialNumberChange(serialNumber: Editable<string>) {
  return {
    type: readerActions.HANDLE_SERIAL_NUMBER_CHANGE,
    serialNumber
  };
}

export function updateSerialNumberEnrollmentMaps(
    enrollable: Map<string, IUniChips_Chip<string>>, 
    unenrollable: Map<string, IUniChips_Chip<string>>) {
  return {
    type: readerActions.UPDATE_SERIAL_NUMBER_ENROLLMENT_MAPS,
    unenrollable,
    enrollable
  };
}

export function clearSerialNumberEnrollmentMaps() {
  return {
    type: readerActions.CLEAR_SERIAL_NUMBER_ENROLLMENT_MAPS,
  };
}

export function toggleEnrollReadersModal() {
  return {
    type: readerActions.TOGGLE_ENROLL_READERS_MODAL,
  }
}

export function updateEnrollReaderWorkflowStep(stepTo: number) {
  return {
    type: readerActions.UPDATE_ENROLL_READERS_WORKFLOW_STEP,
    stepTo
  }
}

export function attemptEnrollReadersInOrg(orgId: string, readers: any[]): any {
  return (dispatch: Dispatch<any>, getState: any): any => {
    // get the username and password from the current state
    dispatch(enrollReadersInOrgRequest())
    const tracking = setupJobTrackingFor(readerActions.ENROLL_READERS_IN_ORG_REQUEST);
    const readersToEnroll: IReaderToEnrollRequestC[] = readers.map((r): IReaderToEnrollRequestC => ({ serial_number: r.serialNumber, new_name: r.readerName.value }));
    return api.read.enrollReadersInOrg(orgId, readersToEnroll, tracking).then((successesAndFailures: IBulkReaderEnrollmentResponseC) => {
      dispatch(enrollReadersInOrgSuccess())
      return successesAndFailures;
    }, (err: any) => {
      if (err.status === 401) {
        dispatch(redirectToLogin())
      } else {
        dispatch(enrollReadersInOrgFailure())
      }
      return;
    })
  }
}

export function enrollReadersInOrgRequest() {
  return {
    type: readerActions.ENROLL_READERS_IN_ORG_REQUEST
  }
}

export function enrollReadersInOrgSuccess() {
  return {
    type: readerActions.ENROLL_READERS_IN_ORG_SUCCESS,
  }
}

export function enrollReadersInOrgFailure() {
  return {
    type: readerActions.ENROLL_READERS_IN_ORG_FAILURE,
  }
}

// READER DETAILS
const getReaderDetailsAction = new ApiReduxAction<string>(api.read, {
  request: { type: readerActions.GET_READER_DETAILS_REQUEST },
  success: { type: readerActions.GET_READER_DETAILS_SUCCESS },
  failure: {
    type: readerActions.GET_READER_DETAILS_FAILURE,
    title: 'getReaderDetailsFail',
  },
  handleErrorCodes: {
    404: (dux: IExposeRedux, err: any) => {
      portalRedirect(oops404Key);
      return { };
    },
  }
}, (dux: IExposeRedux, readerId) => {
  return api.read.getReader.bind(api.read, readerId);
});
export const attemptRetrieveReaderDetails = getReaderDetailsAction.go;

// UPDATE READER
const updateReaderAction = new ApiReduxAction<string>(api.read, {
  request: { type: readerActions.UPDATE_READER_DETAILS_REQUEST },
  success: {
    type: readerActions.UPDATE_READER_DETAILS_SUCCESS,
    title: 'updateReaderDetailsSuccess',
    message: 'readerDetailsChanged'
  },
  failure: {
    type: readerActions.UPDATE_READER_DETAILS_FAILURE,
    title: 'updateReaderFail',
  }
}, (dux: IExposeRedux, readerId) => {
  const newReaderName = dux.getState().readerDetails.editData.name.value;
    const readerUpdateBody: IUpdateReaderBodyC = {
      name: newReaderName
    };
  return api.read.updateReader.bind(api.read, readerId, readerUpdateBody);
});
export const attemptUpdateReaderDetails = updateReaderAction.go;

export function handleReaderDetailsChange(changes: IMultiInputUpdate) {
  return {
    type: readerActions.HANDLE_READER_DETAILS_CHANGE,
    ...changes
  };
}

// DELETE READER
const deleteReaderAction = new ApiReduxAction<string>(api.read, {
  request: { type: readerActions.DELETE_READER_REQUEST },
  success: {
    type: readerActions.DELETE_READER_SUCCESS,
    title: 'removeReaderSuccess',
    message: 'readerRemoved'
  },
  failure: {
    type: readerActions.DELETE_READER_FAILURE,
    title: 'removeReaderFail',
  }
}, (dux: IExposeRedux, readerId) => {
  return api.read.deleteReader.bind(api.read, readerId);
});
export const attemptDeleteReader = deleteReaderAction.go;