import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'

import {
  UniKeyVal,
  UniOverlapButton,
  UniOverlapGroup,
  UniConditionalRender,
  UniLocalize,
  IDealerC,
  DealerC,
  AuthUserC,
  S10nModelC,
  Editable,
  IMultiInputUpdate,
  IDealerSkinC,
  IUniKeyVal_FieldConfig,
  EOperationCodesC,
  ES10nSettings,
  IDynamicSkinConfig,
} from '@unikey/unikey-commons/release/comm';

import partnerConfig from '@alias-current-partner-customizations';

import {
  api,
  attemptUpdateDealerSkin,
  attemptRetrieveDealerDetails,
  toggleDealerCreditsModal,
  attemptUpdateDealerDetails,
  handleDealerDetailsChange,
  canI,
  s10n,
  firstTruthyOf,
  PartnerCustomizations, IPartnerCustomizations,
  DealerEditCreditsContainer,
  updateDealerSkinForm,
  getExtensionFromFileName
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  dealerData: DealerC,
  skinLoading: boolean,
  skinPrimaryColor: Editable<string>,
  skinSecondaryColor: Editable<string>,
  skinLogo: any,
  skinLogoCleared: boolean,
  skinDarkModeLogo: any,
  skinDarkModeLogoCleared: boolean,
  skinSaving: boolean,
  loading: boolean,
  permissionToRedeemCredits: boolean,
  permissionToUpdateDealer: boolean,
  permissionToViewDealerCredits: boolean,
  
  subDisplayCredCount: boolean,
  subDisplayCouponRedemption: boolean,
  
  dealerSkinningOffered?: boolean,
  dealerSkinOverrides?: IDynamicSkinConfig,

  getDealerDetails(): void,
  updateDealerDetails(): Promise<any>,
  updateDealerSkin(config: IDealerSkinC): Promise<any>,
  handleSkinChange(changes: IMultiInputUpdate): void,
  handleDealerChange(changes: IMultiInputUpdate): void,
  toggleDealerCreditsModal(): void,
}

class DealerDetailsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    if (this.props.activeDealerId) {
      this.props.getDealerDetails();
    }
  }

  _saveSkin = () => {
    return this.props.updateDealerSkin({
      logo: this.props.skinLogo?.value,
      dark_mode_logo: this.props.skinDarkModeLogo?.value,
      primary_color: this.props.skinPrimaryColor.value,
      secondary_color: this.props.skinSecondaryColor.value,
      // if the current_logo_urls are set, the api will re-upload them.
      // passinig undefined here means to use the logo above (either a file or '' to remove the logo)
      current_logo_url: !this.props.skinLogo?.dirty ? this.props.dealerSkinOverrides.logo : undefined,
      current_dark_mode_logo_url: !this.props.skinDarkModeLogo?.dirty ? this.props.dealerSkinOverrides.logoDM : undefined,
    })
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const dealerS10nModelName = S10nModelC.getNameKeyFromModelType(this.props.dealerData.subscriptionModel!);
    const addressLines: IUniKeyVal_FieldConfig[] = this.props.dealerData.address! && this.props.dealerData.address!.address_lines ? this.props.dealerData.address!.address_lines.sort((addA: any, addB: any): number => {
      // before displaying, we need to order the freeform address lines based on the contents of address_line_type.
      const getOrderFromLineTypeRegex: RegExp = /(\d+)/g;
      const matchAddA = (addA.address_line_type || '').match(getOrderFromLineTypeRegex);
      const matchAddB = (addB.address_line_type || '').match(getOrderFromLineTypeRegex);
      const addAOrder = Array.isArray(matchAddA) ? matchAddA[0] : 0;
      const addBOrder = Array.isArray(matchAddB) ? matchAddB[0] : 0;
      return Number(addAOrder) - Number(addBOrder);
    }).map((aLine: any, index: number) => {
      return {
        keyName: `streetAddressL${index + 1}`,
        value: aLine.address,
        type: 'string',
        preventTranslate: true,
        
        handleUpdate: (addressChange: Editable) => this.props.handleDealerChange({ [`streetAddressL${index + 1}`]: addressChange }),
      };
    }) : [];

    return (
      <section className='dealer-deatails-container'>

        <Row>
          <Col>
            <h3 className="dealer-details-title">{this.props.dealerData.name ? this.props.dealerData.name?.value : <UniLocalize translate="dealerDetails" />}</h3>
          </Col>
        </Row>

        <Row>
          <Col sm={24} md={12} lg={8}>
            <UniKeyVal
              label="dealer-subscription-model"
              stacked={true}
              showLoader={!this.props.dealerData.id}
              preventEdit={true}
              fields={[
                {
                  keyName: 'subscriptionModel',
                  value: dealerS10nModelName,
                  type: 'string',
                  keyInfo: {
                    icon: 'infoOutline',
                    textKeys: ['activeSubscriptionModel', dealerS10nModelName, `_${dealerS10nModelName}Explanation`],
                  }
                }
              ]} />
          </Col>

          <UniConditionalRender visible={this.props.permissionToViewDealerCredits}>
            <UniConditionalRender visible={!this.props.subDisplayCredCount}>
              <Col sm={24} md={12} lg={8}>
                <UniKeyVal
                  label="dealer-issued-credentials"
                  stacked={true}
                  showLoader={!this.props.dealerData.id}
                  preventEdit={true}
                  allowForOverlapAlignment={['md']}
                  fields={[
                    {
                      keyName: 'credentialsIssued',
                      value: '' + this.props.dealerData.issuedCredentials,
                      type: 'string',
                      keyInfo: {
                        icon: 'infoOutline',
                        textKeys: ['credentialsIssued', '_dealerCredentialsIssuedExplanation'],
                      }
                    }
                  ]} />
              </Col>
            </UniConditionalRender>

            <UniConditionalRender visible={this.props.subDisplayCredCount}>
              <Col sm={24} md={24} lg={16}>
                <UniKeyVal
                  label="dealer-credit-summary"
                  stacked={true}
                  showLoader={!this.props.dealerData.id}
                  primaryStateButtonSet={[
                    {
                      textKey: 'getMoreCredits',
                      icon: 'add',
                      clickHandler: () => { this.props.toggleDealerCreditsModal(); return Promise.resolve() },
                      // disabled: !this.props.permissionToRedeemCredits
                    }
                  ]}
                  secondaryStateButtonSet={[]}
                  preventEdit={!(this.props.permissionToRedeemCredits && this.props.subDisplayCouponRedemption)}
                  fields={[
                    {
                      keyName: 'credentialCreditSummary',
                      type: 'gauge',
                      value: '' + this.props.dealerData.issuedCredentials,
                      gaugeOpts: {
                        nameKey: 'credentialCreditSummary',
                        stacked: false,
                        showKey: true,
                        max: (this.props.dealerData.issuedCredentials || 0) + (this.props.dealerData.availableCredentials || 0),
                        preventReorder: true,
                        size: 'lg',
                        vals: [
                          {
                            nameKey: 'credentialsIssued',
                            value: this.props.dealerData.issuedCredentials || 0,
                            descriptionKeys: ['_dealerCredentialsIssuedExplanation'],
                            theme: 'secondary'
                          },
                          {
                            nameKey: 'allocatedCredits',
                            value: this.props.dealerData.unclaimedAllocatedCredentials || 0,
                            descriptionKeys: ['_allocatedCreditsExplanation'],
                            theme: 'primary'
                          },
                          {
                            nameKey: 'unallocatedCredits',
                            value: (this.props.dealerData.availableCredentials || 0) - (this.props.dealerData.unclaimedAllocatedCredentials || 0),
                            descriptionKeys: ['_unallocatedCreditsExplanation'],
                            theme: 'default'
                          }
                        ]
                      }

                    }
                  ]
                  } />
              </Col>
            </UniConditionalRender>

          </UniConditionalRender>
        </Row>

        <Row>
          <Col>
            <h3 className="organization-contact-title"><UniLocalize translate="dealerContactInfo" /></h3>
          </Col>
        </Row>
        <UniKeyVal
          label="dealer-address"
          showLoader={!this.props.dealerData.id}
          saveClick={this.props.updateDealerDetails}
          preventEdit={!this.props.permissionToUpdateDealer}
          isSaving={this.props.loading}
          fields={[
            {
              keyName: 'name',
              value: '' + this.props.dealerData.name?.value,
              preventTranslate: true,
              type: 'string',
              disabled: true
            },
            ...addressLines,
            {
              keyName: 'postalCode',
              value: this.props.dealerData.address! && this.props.dealerData.address!.postal_code,
              handleUpdate: (postalCodeChange: Editable) => this.props.handleDealerChange({ postalCode: postalCodeChange }),
              type: 'string',
              preventTranslate: true
            },
            {
              keyName: 'administrativeArea',
              value: this.props.dealerData.address! && this.props.dealerData.address!.administrative_area,
              handleUpdate: (administrativeAreaChange: Editable) => this.props.handleDealerChange({ administrativeArea: administrativeAreaChange }),
              type: 'string',
              preventTranslate: true
            },
            {
              keyName: 'country',
              value: this.props.dealerData.address! && this.props.dealerData.address!.country,
              type: 'string',
              disabled: true,
            },
            {
              keyName: 'phoneNumber',
              value: '' + this.props.dealerData.phoneNumber?.value,
              preventTranslate: true,
              type: 'string',
              handleUpdate: (phoneChange: Editable) => this.props.handleDealerChange({ phoneNumber: phoneChange }),
            },
          ]} />

          <UniConditionalRender visible={this.props.permissionToUpdateDealer && this.props.dealerSkinningOffered}>
            <Row>
              <Col>
                <h3 className="dealer-skin"><UniLocalize translate="dealerTheming" /></h3>
              </Col>
            </Row>
            <UniKeyVal
              label="dealer-skin-details"
              stacked={true}
              showLoader={this.props.skinLoading}
              saveClick={this._saveSkin}
              preventSave={!this.props.skinLogo?.valid || !this.props.skinDarkModeLogo?.valid}
              isSaving={this.props.skinSaving}
              preventEdit={false}
              allowForOverlapAlignment={['md']}
              fields={[
                {
                  keyName: 'primaryColor',
                  value: this.props.skinPrimaryColor?.value || '',
                  handleUpdate: (colorChange: Editable) => this.props.handleSkinChange({ primaryColor: colorChange }),
                  type: 'color'
                },
                {
                  keyName: 'secondaryColor',
                  value: this.props.skinSecondaryColor?.value || '',
                  handleUpdate: (colorChange: Editable) => this.props.handleSkinChange({ secondaryColor: colorChange }),
                  type: 'color'
                },
                {
                  keyName: 'lightModeLogo',
                  keyInfo: {
                    textKeys: ['_explainLogo', '_logoImageRequirements']
                  },
                  value: this.props.skinLogoCleared ? partnerConfig.assets.logoOnBackground : firstTruthyOf(this.props.skinLogo?.value, this.props.dealerSkinOverrides.logo, partnerConfig.assets.logoOnBackground),
                  handleUpdate: (logoChange: Editable<File>) => { this.props.handleSkinChange({ logo: logoChange }) },
                  type: 'imageUpload'
                },
                {
                  keyName: 'darkModeLogo',
                  keyInfo: {
                    textKeys: ['_explainDarkModeLogo', '_logoImageRequirements']
                  },
                  value: this.props.skinDarkModeLogoCleared ? partnerConfig.assets.logoOnDark : firstTruthyOf(this.props.skinDarkModeLogo?.value, this.props.dealerSkinOverrides.logoDM, partnerConfig.assets.logoOnDark),
                  handleUpdate: (logoChange: Editable<File>) => { this.props.handleSkinChange({ darkModeLogo: logoChange })},
                  type: 'imageUpload'
                }
            ]} />
          </UniConditionalRender>

      </section>
    )
  }
}

function mapStateToProps(state: any, ownProps: IProps) {
  return {
    dealerData: state.dealer.dealerData,
    loading: state.dealer.loading,
    dealerSkinOverrides: state.dealerSkin.skin,

    // skin
    skinPrimaryColor: state.dealerSkin.form.primaryColor,
    skinSecondaryColor: state.dealerSkin.form.secondaryColor,
    skinLoading: state.dealerSkin.loading,
    skinLogo: state.dealerSkin.form.logo,
    skinLogoCleared: (state.dealerSkin.form.logo?.value === '' && state.dealerSkin.form.logo?.dirty),
    skinDarkModeLogo: state.dealerSkin.form.darkModeLogo,
    skinDarkModeLogoCleared: (state.dealerSkin.form.darkModeLogo?.value === '' && state.dealerSkin.form.darkModeLogo?.dirty),
    skinSaving: state.dealerSkin.saving,
    // permissions
    permissionToRedeemCredits: canI(EOperationCodesC.RedeemCredits, state.dealer.dealerData.id),
    permissionToViewDealerCredits: canI(EOperationCodesC.UpdateDealer, state.dealer.dealerData.id),
    permissionToUpdateDealer: canI(EOperationCodesC.UpdateDealer, state.dealer.dealerData.id),
    // subscription
    availableSubscriptonModels: state.subscriptionInfo.availableModels,
    subDisplayCouponRedemption: s10n(ES10nSettings.CouponRedemption),
    subDisplayCredCount: s10n(ES10nSettings.CredCountRelevant)
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getDealerDetails: attemptRetrieveDealerDetails,
  handleDealerChange: handleDealerDetailsChange,
  updateDealerDetails: attemptUpdateDealerDetails,
  updateDealerSkin: attemptUpdateDealerSkin,
  handleSkinChange: updateDealerSkinForm,
  toggleDealerCreditsModal,
}, dispatch)

export default PartnerCustomizations(connect(mapStateToProps, mapDispatchToProps)(injectIntl(DealerDetailsContainer)), { componentName: 'DealerDetails' })
