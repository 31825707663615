import {
  IMultiInputUpdate,
  ITrackedRequest,
  Editable,
  IUniTable_UpdatePaginationSummary,
  IUniTable_PaginationSummary
} from '@unikey/unikey-commons/release/comm'

import {
  initialPageSize
} from '../internal'


export const inputInitializer = (initialValue: any, fieldNames: string[]): IMultiInputUpdate => {
  return fieldNames.reduce((form: IMultiInputUpdate, field: string) => {
    form[field] = new Editable({ value: initialValue, valid: false });
    return form;
  }, {});
}

export const setupJobTrackingFor = (jobName?: string): Partial<ITrackedRequest> => {
  const tracked: Partial<ITrackedRequest> = {};
  if (jobName !== undefined) {
    tracked.job = jobName;
  }
  return tracked;
}

// returns a method to stop the interval call
export const checkJobStatusOnInterval = (dispatchAction: any, msInterval: number = 200): (() => void) => {
  const intervalRef = setInterval(() => {
    dispatchAction();
  }, msInterval);

  return clearInterval.bind(null, intervalRef);
};


export const getPaginatedTableMetaChanges = (latest: IUniTable_UpdatePaginationSummary, prevSummary: IUniTable_PaginationSummary) => {
  const updates: any = {};
  if (latest?.filters) {
    updates.tableFilters = latest?.filters;
  }
  if (latest?.sorts) {
    updates.tableSorts = latest?.sorts;
  }
  if (Number.isInteger(Number(latest?.currPage)) || Number.isInteger(Number(latest?.pageSize)) || Number.isInteger(Number(latest?.totalCount)) || Number.isInteger(Number(latest?.pageCount))) {
    const paginationToUpdate: any = {};
    if (Number.isInteger(Number(latest?.currPage))) {
      paginationToUpdate.currPage = latest?.currPage;
    }
    if (Number.isInteger(Number(latest?.pageSize))) {
      paginationToUpdate.pageSize = latest?.pageSize;
    }
    if (Number.isInteger(Number(latest?.totalCount))) {
      paginationToUpdate.totalCount = latest?.totalCount;
      paginationToUpdate.pageCount = Math.ceil((latest?.totalCount || 0) / (latest?.pageSize || prevSummary.pageSize));
    }
    updates.paginationSummary = Object.assign({}, prevSummary, paginationToUpdate);
  }
  return updates;
}

export type TSortDirection = 'reverse' | 'forward';
export const populateInitialReduxPaginatedListState = (sortColumns: string[], sortDir: TSortDirection = 'reverse', pageSize?: number) => {
  return {
    loading: false,
    data: {
      models: []
    },
    activeColumn: sortColumns[0],
    modalOpen: false,
    queryParams: {
      limit: pageSize ?? initialPageSize,
      sorts: sortColumns.map(sortCol => ({
        fieldName: sortCol,
        reverse: sortDir === 'reverse'
      }))
    },
    tableFilters: [],
    tableSorts: sortColumns?.map(sortCol => ({
      field: sortCol,
      direction: sortDir === 'reverse' ? 'asc' : 'desc'
    })),
    paginationSummary: {
      pageSize: pageSize ?? initialPageSize,
      currPage: 1, // default page,
      pageCount: pageSize ?? initialPageSize, 
      totalCount: 0,
    }
  }
}