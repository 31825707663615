import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client'
import { setConfiguration } from 'react-grid-system';

import { DealerC } from '@unikey/unikey-commons/release/comm';

import {
  api,
  store,
  navConfig, ENavPages,
  portalRedirect,
  oidcAuthority,
  oidcClientId,
  oidcClientSecret,
  oidcRedirectUri,
  oidcScope,
  mockAuth,
  buildOidcAcrValues,
  devLogger,
  decodeJWT,
  logoutKey,
  verifyLandingKey,
  setActivePortalDealer,
  getCurrentBaseDomain
} from '../../internal';

setConfiguration({ 
  maxScreenClass: 'xxxl',
  breakpoints: [576, 768, 992, 1200, 1660, 2410],
  containerWidths: [540, 740, 960, 1140, 1580, 1840],
  gridColumns: 24
});

export const userManager = createUserManager({
  authority: oidcAuthority,
  client_id: oidcClientId,
  client_secret: oidcClientSecret,
  redirect_uri: oidcRedirectUri,
  accessTokenExpiringNotificationTime: 30, // 30 seconds till expiration
  automaticSilentRenew: !mockAuth,
  silentRequestTimeout: 4000,
  scope: oidcScope,
  response_type: 'code',
  prompt: 'login',
  // revokeAccessTokenOnSignout: true,
  staleStateAge: 600,
  stateStore: new WebStorageStateStore({ store: window.localStorage }),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  // when true this attempts to fetch the user data  from a prefigured 
  // route in identity server after the authentication happens. 
  // currently failing since we don thave the separate route setup.
  // we want to request from tumbler anyways. 
  loadUserInfo: false,
  acr_values: buildOidcAcrValues(),
});

export const handleRefreshError = (reason: any) => {
  console.warn(JSON.stringify(reason));
  portalRedirect(logoutKey);
}

export const requestRefreshToken = async (forceRenew: boolean = false, setUserInStore?: (u:any, canCreateDealer: boolean) => void) => {
  // if we are forcing a renew, or we are inside 
  // the portal flows,then attempt to renew the token
  if (forceRenew || /\#\/portal/.test(window.location.hash)) {
    try {
      const refreshed = await userManager.signinSilent();
      await handleNewOrRefreshedToken(refreshed, setUserInStore);
    } catch (err) {
      handleRefreshError(err);
    }
  }
}

export const handleNewOrRefreshedToken = async (user: any, setUserInStore?: (u:any, canCreateDealer: boolean) => void): Promise<string> => {

  const decoded: any = decodeJWT(user.access_token);
  const userId = decoded?.sub || user.profile?.sub;
  const accountVerified = decoded?.account_verified;
  const loginMethod = decoded?.idp || user.profile?.idp;
  const loginIdpUserId = decoded?.idp_sub || user.profile?.idp_sub;
  const deviceId = decoded?.device_id || user.profile?.device_id;
  const canCreateDealer = !!decoded?.create_dealer;
  
  // sets the accessToken and loads user data
  await api.setAccessToken(user.access_token, userId, deviceId, user.refresh_token, loginMethod, loginIdpUserId);
  api.setCreateDealer(userId, canCreateDealer);
  setUserInStore?.(user, canCreateDealer);
  
  if (!accountVerified || accountVerified === 'false') {
    // if the user's account is not yet verified, send them directly to the request-new-verification page.
    portalRedirect(verifyLandingKey);
    return userId;
  }

  const authUser = await api.getAuthUser();
  // if the auth user does not have a dealer association, send them to dealer creation
  if (!authUser.getDealer()?.id) {
    const cacheContinueToValue = decodeURIComponent(api.cacheImpl.get('continueTo'));
    // only send to dealer creation if the user is not intending to view a dealer invitation
    const currentlyNotOnInvitesPage = !/\#\/invites/.test(window.location.hash);
    const cacheNotPointingToInvitesPage = !/\/invites/.test(cacheContinueToValue);
    if (currentlyNotOnInvitesPage) {

      if (cacheNotPointingToInvitesPage) {
        portalRedirect(navConfig.get(ENavPages.dealerCreate)!.linkTo());
        return userId;
      } else {
        // not on the invites page and the cache says to go there, navigate to the invite page.
        const goToLoc = `${getCurrentBaseDomain()}#${cacheContinueToValue}`;
        window.location.href = goToLoc;
      }
    }
  }
  const dealer = authUser.getPreferredDealer(api.cacheImpl);
  if (dealer) {
    store.dispatch(setActivePortalDealer(dealer));
  }

  return userId;
};


// load the new jwt and refresh tokens each time they are refreshed
userManager.events.addUserLoaded(handleNewOrRefreshedToken);

userManager.events.addSilentRenewError((err: any) => {
  devLogger('userManager - silent renew token error');
  
  // will redirect to login
  handleRefreshError(err);

  console.warn(JSON.stringify(err));
  return err;
});

userManager.events.addAccessTokenExpired((err: any) => {
  devLogger('token - the access token has expired');
  console.warn(JSON.stringify(err));
  
  // if the token is expired use our helper to request a new token 
  // using the refresh token handle any error appropriately
  requestRefreshToken();
});

if (!mockAuth) {
  // refresh the token right away on page load or page refresh
  setTimeout(requestRefreshToken, 600);
}


// --- other events supported ---
// userLoaded: Raised when a user session has been established (or re-established).
// userUnloaded: Raised when a user session has been terminated.
// accessTokenExpiring: Raised prior to the access token expiring.
// accessTokenExpired: Raised after the access token has expired.
// silentRenewError: Raised when the automatic silent renew has failed.
// userSignedIn [1.9.0]: Raised when the user is signed in.
// userSignedOut [1.1.0]: Raised when the user's sign-in status at the OP has changed.
// userSessionChanged: Raised 