import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniTable,
  UniGauge,
  UniConditionalRender,
  UniLocalize,
  IUniTable_Column,
  IPaginatedResponseC,
  IPaginatedParamsC,
  IOrganizationC,
  OrganizationC,
  IUniTable_Sort,
  IUniTable_Filter,
  IUniTable_Action,
  IUniTable_UpdatePaginationSummary,
  IUniTable_PaginationSummary,
  IUniConfirm_Config,
  IPaginationQueryBuilderParamsC,
  EOperationCodesC,
  ES10nSettings,
} from '@unikey/unikey-commons/release/comm';

import {
  portalRedirect,
  OrganizationCreateContainer,
  attemptRetrieveOrganizations,
  attemptDeleteOrg,
  toggleCreateOrgModal,
  updateOrgListQueryParams,
  buildTableUpdateFunc, TTableUpdateFunc,
  updateOrganizationsTableMeta,
  openConfirmModal,
  closeConfirmModal,
  getTableSortDirection, canI, s10n,
  PartnerCustomizations, IPartnerCustomizations, navConfig, ENavPages
} from '../internal'

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  organizations: OrganizationC[],
  activeDelerId: string,
  remainingDealerCredits: number,  
  orgListQueryParams: IPaginationQueryBuilderParamsC,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  paginationSummary: IUniTable_PaginationSummary,
  listLoading: boolean,
  history: any,
  permissionToCreateOrg: boolean,
  permissionToViewDealerCredits: boolean,
  subCredCountRelevant: boolean,
  permissionToDeleteOrg(orgId: string): boolean,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateQueryParams?(params: IPaginationQueryBuilderParamsC): void,
  toggleModal(): void,
  getOrgs(): Promise<void>,
  deleteOrg(orgId: string): Promise<void>,
  openDeleteOrgConfirmDialog(dialogConfig: IUniConfirm_Config): void,
  closeConfirmModal(): void,
}

class OrganizationListContainer extends Component<IProps> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);
    
    this._updateTable = buildTableUpdateFunc(
      props.getOrgs,
      props.updateTableMeta,
      props.updateQueryParams
    )
  }

  componentDidMount() {
    this.props.getOrgs();
  }

  _deleteOrgAndRefreshList(orgId: string) {
    this.props.deleteOrg(orgId).then(this.componentDidMount.bind(this));
  }

  _buildColumnsAndActions() {
    const columns = new Map<string, IUniTable_Column>()
      .set('name', {
        nameKey: 'name',
        isSortable: true,
        sortFields: ['name'],
        isPrimaryFilter: true,
        size: 12
      })
      .set('creditSummary', {
        nameKey: 'creditSummary',
        isSortable: false,
        isFilterable: false,
        size: !!this.props.subCredCountRelevant ? 5 : 0,
        template: (rowItem: OrganizationC, rowIndex: number) => (
          <>
            {/* limited org credits display */}
            <UniConditionalRender visible={rowItem.creditsAllocated !== null || (rowItem.creditsAllocated === null && this.props.permissionToViewDealerCredits)}>
              <UniGauge
                id={`organizationCreditAllocation-gauge-${rowIndex}`}
                className="table-row-gauge"
                key={rowIndex}
                nameKey="organizationCreditAllocation"
                size="sm"
                preventReorder={true}
                stacked={true}
                max={rowItem.creditsAllocated === null ? (this.props.remainingDealerCredits + (rowItem.creditsClaimed || 0)) : rowItem.creditsAllocated!}
                vals={[
                  {
                    nameKey: 'organizationCreditsConsumed',
                    value: rowItem.creditsClaimed!,
                    theme: 'secondary'
                  }
                ]}
              />
            </UniConditionalRender>
            {/* unlimited org credits display */}
            <UniConditionalRender visible={rowItem.creditsAllocated === null && !this.props.permissionToViewDealerCredits}>
              <i><UniLocalize translate="unlimited" /></i>
            </UniConditionalRender>
          </>

        )
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        isFilterable: false,
        size: 6
      });

    const actions = new Map<string, IUniTable_Action>();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: OrganizationC) => portalRedirect(navConfig.get(ENavPages.organizationDetails)!.linkTo([rowItem.id])),
      evalDisabled: (rowItem: OrganizationC) => false,
      evalVisible: (rowItem: OrganizationC) => true,
    }).set('viewReaders', {
      nameKey: 'viewReaders',
      icon: 'settingsRemote',
      func: (rowItem: OrganizationC) => portalRedirect(navConfig.get(ENavPages.readers)!.linkTo([rowItem.id])),
      evalDisabled: (rowItem: OrganizationC) => false,
      evalVisible: (rowItem: OrganizationC) => true
    }).set('viewCredentials', {
      nameKey: 'viewCredentials',
      icon: 'vpnKey',
      func: (rowItem: OrganizationC) => portalRedirect(navConfig.get(ENavPages.credentials)!.linkTo([rowItem.id])),
      evalDisabled: (rowItem: OrganizationC) => false,
      evalVisible: (rowItem: OrganizationC) => true
    }).set('removeOrg', {
      nameKey: 'removeOrg',
      icon: 'delete',
      theme: 'fail',
      func: (rowItem: OrganizationC) => this.props.openDeleteOrgConfirmDialog({
        titleKey: 'removeOrg',
        messageKeys: ['youAreAboutToRemoveThisOrganization', rowItem.name],
        confirmTextKey: 'remove',
        cancelHandler: this.props.closeConfirmModal,
        confirmHandler: () => {
          this._deleteOrgAndRefreshList(rowItem.id)
          this.props.closeConfirmModal()
        }
      }),
      evalDisabled: (rowItem: OrganizationC) => false,
      evalVisible: (rowItem: OrganizationC) => this.props.permissionToDeleteOrg(rowItem.id)
    });

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();
    return (
      <section className='orgList-container'>
        <UniTable
          searchable={true}
          titleKey="organizationsList"
          createButtonTextKey="organization"
          handleUpdate={this._updateTable}
          handleCreateClick={this.props.permissionToCreateOrg ? this.props.toggleModal : undefined}
          data={this.props.organizations}
          paginationSummary={this.props.paginationSummary}
          columnConfig={columns}
          actionsConfig={actions}
          activeSorts={this.props.appliedSorts}
          activeFilters={this.props.appliedFilters}
          showLoader={this.props.listLoading} />

      </section>
    )
  }
}

function mapStateToProps(state: any) {
  const activeDealerId = state.portal.activeDealer?.id;
  return {
    activeDealerId,
    organizations: state.organizations.data.models,
    appliedFilters: state.organizations.tableFilters,
    appliedSorts: state.organizations.tableSorts,
    paginationSummary: state.organizations.paginationSummary,
    listLoading: state.organizations.loading,
    remainingDealerCredits: state.dealer.dealerData.unallocatedCredentials,
    orgListQueryParams: state.organizations.queryParams,
    // permissions
    permissionToCreateOrg: canI(EOperationCodesC.CreateOrg, activeDealerId),
    permissionToDeleteOrg: canI.bind(null, EOperationCodesC.DeleteOrg, activeDealerId),
    permissionToViewDealerCredits: canI(EOperationCodesC.UpdateDealer, activeDealerId),
    subCredCountRelevant: s10n(ES10nSettings.CredCountRelevant)
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  toggleModal: toggleCreateOrgModal,
  getOrgs: attemptRetrieveOrganizations,
  updateQueryParams: updateOrgListQueryParams,
  updateTableMeta: updateOrganizationsTableMeta,
  deleteOrg: attemptDeleteOrg,
  openDeleteOrgConfirmDialog: openConfirmModal,
  closeConfirmModal,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(OrganizationListContainer)
  ), { componentName: 'OrganizationList' })
