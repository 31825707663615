import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl'
import { Container, Row, Col } from 'react-grid-system'

import {
  darkThemeName,
  UniImg,
  UniWorkflow,
  UniInput,
  UniOverlapGroup,
  UniOverlapButton,
  UniLocalize,
  UniConditionalRender,
  InviteC,
  Editable,
  EInviteStatusC,

  notBlankV10n, commPasswordRequirementsV10n, passwordMatchV10n,
} from '@unikey/unikey-commons/release/comm';

import partnerConfig from '@alias-current-partner-customizations';
import {
  FooterContainer,
  attemptAcceptAdminInviteAsNewUser,
  updateAdminRegistrationForm,
  attemptRetrieveAdminInviteByToken,
  PartnerCustomizations, IPartnerCustomizations,

  partnerKey,
  IAcceptInviteActionParams
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  firstName: Editable<string>,
  lastName: Editable<string>,
  password: Editable<string>,
  passwordConfirm: Editable<string>,
  firstNameValid: boolean,
  lastNameValid: boolean,
  passwordValid: boolean,
  passwordConfirmValid: boolean,
  loading: boolean,
  inviteData: InviteC,
  validCaptcha: boolean,
  darkModeTheme: string,
  handleRegisterClick(params: IAcceptInviteActionParams): void,
  handlePasswordChange(change: Editable): void,
  handlePasswordConfirmChange(change: Editable): void,
  handleFirstNameChange(change: Editable): void,
  handleLastNameChange(change: Editable): void,
  getInvitationDetails(token: string): void,
}

class AdminRegisterForm extends Component<IProps> {

  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getInvitationDetails(decodeURIComponent(this.props.match.params.inviteToken));
  }

  _acceptInvite = () => {
    this.props.handleRegisterClick({
      token: this.props.match.params.inviteToken,
      dealerId: this.props.inviteData.dealer!.id!
    })
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const imgClassName = classNames('partner-logo', {
      [partnerKey]: true
    });

    const formValid: boolean = this.props.inviteData.status < 3 && this.props.firstNameValid && this.props.lastNameValid && this.props.passwordValid && this.props.passwordConfirmValid;
    return (
      <section className='access-container'>
        <section className='admin-register-container'>
          {/* access flow should conform to max width restrictions for massive screens */}
          <Container>
            <UniImg textKey="partner logo" className={imgClassName} src={this.props.darkModeTheme === darkThemeName ? partnerConfig.assets.logoOnDark : partnerConfig.assets.logoOnBackground} />
            <UniWorkflow
              titleKey="acceptAdminInvite"
              titleIcon="accountCircle"
              size="wider">
              <UniConditionalRender visible={this.props.inviteData.status < 3} >
                <p><UniLocalize translate="_adminRegisterMessage" /></p>
              </UniConditionalRender>

              <UniConditionalRender visible={this.props.inviteData.status >= 3} >
                <p><UniLocalize translate="_adminInviteInvalidMessage" /></p>
              </UniConditionalRender>

              {/* Ok to not use editable since this is disabled always */}
              <UniInput
                value={this.props.inviteData.inviterEmail}
                labelKey="invitedBy"
                placeholderKey="email"
                disabled={true} />
              <UniInput
                value={this.props.inviteData.inviteeEmail}
                labelKey="email"
                placeholderKey="email"
                disabled={true} />

              <UniConditionalRender visible={!!this.props.inviteData.dealer?.name}>
                <UniInput
                  value={this.props.inviteData.dealer?.name?.value}
                  labelKey="dealerName"
                  placeholderKey="dealerName"
                  disabled={true} />
              </UniConditionalRender>

              <UniConditionalRender visible={this.props.inviteData.status < 3}>
                <UniInput
                  editable={this.props.firstName}
                  labelKey="firstName"
                  placeholderKey="firstName"
                  required={true}
                  handleUpdate={this.props.handleFirstNameChange}
                  validations={[notBlankV10n]} />
                <UniInput
                  editable={this.props.lastName}
                  labelKey="lastName"
                  placeholderKey="lastName"
                  required={true}
                  handleUpdate={this.props.handleLastNameChange}
                  validations={[notBlankV10n]} />
                <UniInput
                  type="password"
                  labelKey="password"
                  editable={this.props.password}
                  placeholderKey="password"
                  preventAutofill={true}
                  handleUpdate={this.props.handlePasswordChange}
                  validations={[commPasswordRequirementsV10n]} />

                {/* There may be an issue here around matching the password -- if so, will need to switch to validations as a Map() object instead */}
                <UniInput
                  type="password"
                  labelKey="confirmPassword"
                  editable={this.props.passwordConfirm}
                  placeholderKey="matchingPassword"
                  preventAutofill={true}
                  handleUpdate={this.props.handlePasswordConfirmChange}
                  validations={[passwordMatchV10n(this.props.password.value)]} />

                <UniOverlapGroup foldEarly={true}>
                  <UniOverlapButton
                    handleClick={this._acceptInvite}
                    textKey="register"
                    icon="forward"
                    showLoader={!!this.props.loading}
                    disabled={!formValid} />
                </UniOverlapGroup>

              </UniConditionalRender>
              <div className="below-workflow">
                <a className="link-left" href="/#/login"><UniLocalize translate="login" /></a>
                <a className="link-right" href="/#/forgot"><UniLocalize translate="forgotPassword" /></a>
              </div>
            </UniWorkflow>
          </Container>
        </section>
        <FooterContainer />
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    firstName: state.adminRegisterForm.firstName,
    firstNameValid: state.adminRegisterForm.firstName.valid,
    lastName: state.adminRegisterForm.lastName,
    lastNameValid: state.adminRegisterForm.lastName.valid,
    password: state.adminRegisterForm.pass,
    passwordValid: state.adminRegisterForm.pass.valid,
    passwordConfirm: state.adminRegisterForm.passConfirm,
    passwordConfirmValid: state.adminRegisterForm.passConfirm.valid,
    inviteData: state.inviteDetails.inviteData,
    loading: state.adminRegisterForm.loading,
    darkModeTheme: state.portal.darkModeTheme
  };
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getInvitationDetails: attemptRetrieveAdminInviteByToken,
  handleRegisterClick: attemptAcceptAdminInviteAsNewUser,
  handlePasswordChange: (change: Editable) => updateAdminRegistrationForm({ pass: change }),
  handlePasswordConfirmChange: (change: Editable) => updateAdminRegistrationForm({ passConfirm: change }),
  handleFirstNameChange: (change: Editable) => updateAdminRegistrationForm({ firstName: change }),
  handleLastNameChange: (change: Editable) => updateAdminRegistrationForm({ lastName: change })
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AdminRegisterForm, { forwardRef: true }))
