import { Dispatch } from 'redux'
import { push } from 'connected-react-router'

import {
  UserC,
  AuthUserC,
  Editable,
  IMultiInputUpdate,
  IChangePasswordRequestC,
  PermissionMapC,
  EMessageType,
  noop,
  ApiReduxAction,
} from '@unikey/unikey-commons/release/comm'

import {
  api,
  identityApi,
  IExposeRedux,
  addAlert,
  redirectToLogin,
  setupJobTrackingFor,
  clearApplicationState,
  portalRedirect,
  loginKey,
  verifyLandingKey
} from '../../internal'

export enum authActions {

  UPDATE_LOGIN_FORM = 'UPDATE_USERNAME',

  // LOGIN_REQUEST = 'LOGIN_REQUEST',
  // LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  // LOGIN_FAILURE = 'LOGIN_FAILURE',

  LOGOUT_REQUEST = 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE',
  
  UPDATE_CAN_USER_CREATE_DELAER = 'UPDATE_CAN_USER_CREATE_DELAER',
  
  GET_MY_USER_REQUEST = 'GET_MY_USER_REQUEST',
  GET_MY_USER_SUCCESS = 'GET_MY_USER_SUCCESS',
  GET_MY_USER_FAILURE = 'GET_MY_USER_FAILURE',

  PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE',

  UPDATE_FORGOT_FORM = 'UPDATE_FORGOT_FORM',

  GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST',
  GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS',
  GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE',

  MODIFY_AUTH_USER = 'MODIFY_AUTH_USER',

  UPDATE_AUTH_USER_REQUEST = 'UPDATE_AUTH_USER_REQUEST',
  UPDATE_AUTH_USER_SUCCESS = 'UPDATE_AUTH_USER_SUCCESS',
  UPDATE_AUTH_USER_FAILURE = 'UPDATE_AUTH_USER_FAILURE',

  PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST',
  PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS',
  PASSWORD_CHANGE_FAILURE = 'PASSWORD_CHANGE_FAILURE',

  UPDATE_PASSWORD_FORM = 'UPDATE_PASSWORD_FORM',
  
  GET_IDENTITY_AUTH_ACCOUNT_REQUEST = 'GET_IDENTITY_AUTH_ACCOUNT_REQUEST',
  GET_IDENTITY_AUTH_ACCOUNT_SUCCESS = 'GET_IDENTITY_AUTH_ACCOUNT_SUCCESS',
  GET_IDENTITY_AUTH_ACCOUNT_FAILURE = 'GET_IDENTITY_AUTH_ACCOUNT_FAILURE',

  GET_LINKED_ACCOUNTS_REQUEST = 'GET_LINKED_ACCOUNTS_REQUEST',
  GET_LINKED_ACCOUNTS_SUCCESS = 'GET_LINKED_ACCOUNTS_SUCCESS',
  GET_LINKED_ACCOUNTS_FAILURE = 'GET_LINKED_ACCOUNTS_FAILURE',

  UNLINK_ACCOUNT_REQUEST = 'UNLINK_ACCOUNT_REQUEST',
  UNLINK_ACCOUNT_SUCCESS = 'UNLINK_ACCOUNT_SUCCESS',
  UNLINK_ACCOUNT_FAILURE = 'UNLINK_ACCOUNT_FAILURE',
}

export function updateCanUserCreateDealer(canCreate: boolean) {
  return {
    type: authActions.UPDATE_CAN_USER_CREATE_DELAER,
    canCreateDealer: canCreate
  }
}

// Auth User Details
const getAuthUser = new ApiReduxAction<boolean>(api.getAuthUser, {
  request: { type: authActions.GET_CURRENT_USER_REQUEST },
  success: { type: authActions.GET_CURRENT_USER_SUCCESS },
  failure: {
    type: authActions.GET_CURRENT_USER_FAILURE,
    title: 'getCurrentUserFailure',
    after: (dux: IExposeRedux, errCode: number, subStatus: string) => {
      if (errCode === 403) {
        portalRedirect(verifyLandingKey);
      } else {
        portalRedirect(loginKey);
      }
    }
  }
}, (dux: IExposeRedux, ignoreCache) => {
  return api.getAuthUser.bind(api, ignoreCache);
});
export const attemptRetrieveAuthUser = getAuthUser.go;



// Auth User Details
const getAuthUserOptionalSilent = new ApiReduxAction(api.getAuthUser, {
  request: { type: authActions.GET_CURRENT_USER_REQUEST },
  success: { type: authActions.GET_CURRENT_USER_SUCCESS },
  failure: { type: authActions.GET_CURRENT_USER_FAILURE, },
  handleErrorCodes: {
    401: (dux: IExposeRedux, err: any) => {
      dux.dispatch({ type: authActions.GET_CURRENT_USER_FAILURE });
      return { preventAlert: true, resolveWithSuccess: true };
    },
    404: (dux: IExposeRedux, err: any) => {
      dux.dispatch({ type: authActions.GET_CURRENT_USER_FAILURE });
      return { preventAlert: true, resolveWithSuccess: true };
    }
  },
}, (dux: IExposeRedux, ignoreCache?: boolean) => {
  return api.getAuthUser.bind(api, ignoreCache);
});
export const attemptRetrieveAuthUserOptional = getAuthUserOptionalSilent.go;


export function updateLoginForm(formParts: IMultiInputUpdate) {
  return {
    type: authActions.UPDATE_LOGIN_FORM,
    formParts
  }
}

export function checkValidAuthenticationOrRedirect(): any {  
  return async (dispatch: Dispatch<any>, getState: any): any => {
    try {
      const foundToken = api.getAccessToken();
      if (foundToken) {
        // TODO: check for account verified
        // await api.getAuthUser();
        // dispatch(setActorTypeFromAccessTokenOrRedirect());
        return true;
      } 
      else {
        return false;
      }
    } catch (err) {
      dispatch(redirectToLogin())
      return false;
    }
  }
}

export function attemptLogoutRequest(preventOidcRedirect: boolean = false) {
  return (dispatch: Dispatch<any>, getState: any): any => {
    api.clearAuthValues();
    if (!preventOidcRedirect) {
      // oidc handles logout, so we just need to clear our own 
      // application state and cache then redirect the user
      dispatch(clearApplicationState())
      dispatch(redirectToLogin({ preventContinue: true }));
    }
  }
}

// request password reset email
const passwordResetThroughEmail = new ApiReduxAction(identityApi.acct, {
  request: { type: authActions.PASSWORD_RESET_REQUEST },
  success: {
    type: authActions.PASSWORD_RESET_SUCCESS,
    title: 'emailSent',
    message: '_sentPasswordResetSuccessMessage',
    duration: 24000
  },
  failure: {
    type: authActions.PASSWORD_RESET_FAILURE,
    title: 'passwordResetFailure'
  },
}, (dux: IExposeRedux) => {
  return identityApi.acct.changePasswordIndirect.bind(identityApi.acct);
});
export const attemptPasswordReset = passwordResetThroughEmail.go;


/// BELOW IS LEGACY STUFF NEEDED UNTIL NO MORE LEGACY 
// FORGOT/CHANGE PASSWORD LINKS EXIST IN THE WILD
// May need to keep around longer until the related apis are fully deprecated
export function updateForgotForm(emailChange: Editable) {
  return {
    type: authActions.UPDATE_FORGOT_FORM,
    emailChange
  }
}

export function attemptChangePassword(): any {
  return (dispatch: Dispatch<any>, getState: any) => {
    const passForm = getState().forgotForm;
    const changeConfig: IChangePasswordRequestC = {
      token: passForm.token.value,
      password: passForm.confirmPassword.value,
      password_confirmation: passForm.confirmPassword.value
    };

    dispatch(passwordChangeRequest())
    const tracking = setupJobTrackingFor(authActions.PASSWORD_CHANGE_REQUEST);
    return api.user.changeUserPassword(changeConfig, tracking)
      .then(() => {
        dispatch(passwordChangeSuccess());
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'passwordChangeSuccess',
          type: EMessageType.success,
          messageKeys: ['passwordChangedSuccessfully', 'redirectingToLogin'],
          duration: 3000
        }));
        setTimeout(() => dispatch(redirectToLogin({ preventContinue: true })), 2000)
        return;
      }, (err: any) => {
        dispatch(addAlert({
          id: Date.now(),
          titleKey: 'passwordChangeFailure',
          type: EMessageType.error,
          messageKeys: [err.message || err]
        }));
        dispatch(passwordChangeFailure());
      });
  }
}

export function passwordChangeRequest() {
  return {
    type: authActions.PASSWORD_CHANGE_REQUEST
  }
}

export function passwordChangeSuccess() {
  return {
    type: authActions.PASSWORD_CHANGE_SUCCESS
  }
}

export function passwordChangeFailure() {
  return {
    type: authActions.PASSWORD_CHANGE_FAILURE
  }
}

export function updatePasswordForm(formChanges: IMultiInputUpdate) {
  return {
    type: authActions.UPDATE_PASSWORD_FORM,
    formChanges
  }
}


export function handleModifyAuthUser(user: UserC) {
  user.updater++;
  return {
    type: authActions.MODIFY_AUTH_USER,
    user
  }
}

// const updateCurrentUserAction = new ApiReduxAction<'profile' | 'email' | 'password'>(api.user, {
//   request: { type: authActions.UPDATE_AUTH_USER_REQUEST },
//   success: { type: authActions.UPDATE_AUTH_USER_SUCCESS },
//   failure: {
//     type: authActions.UPDATE_AUTH_USER_FAILURE,
//     title: 'updateCurrentUserFailure'
//   },
//   checkNumPendingReqs: api.pendingReqs.getNum
// }, (dux: IExposeRedux, updateType) => {
//   const userData = dux.getState().authenticatedUser;
//   const update = userData.currentUser.forUpdateApi(updateType);
//   return api.user.updateUser.bind(api.user, userData.currentUser.id, update);
// });
// export const attemptUpdateCurrentUser = updateCurrentUserAction.go;


// linked accounts
const getLinkedAccountsAction = new ApiReduxAction(identityApi.acct, {
  request: { type: authActions.GET_LINKED_ACCOUNTS_REQUEST },
  success: { type: authActions.GET_LINKED_ACCOUNTS_SUCCESS },
  failure: {
    type: authActions.GET_LINKED_ACCOUNTS_FAILURE,
    title: 'getLinkedAccountsFailure'
  },
}, (dux: IExposeRedux) => {
  return identityApi.acct.getLinkedAccounts.bind(identityApi.acct);
});
export const attemptGetLinkedAccounts = getLinkedAccountsAction.go;

// get identity login info
const getIdentityAuthInfoAction = new ApiReduxAction(identityApi.acct, {
  request: { type: authActions.GET_IDENTITY_AUTH_ACCOUNT_REQUEST },
  success: { type: authActions.GET_IDENTITY_AUTH_ACCOUNT_SUCCESS },
  failure: {
    type: authActions.GET_IDENTITY_AUTH_ACCOUNT_FAILURE,
    title: 'getIdentityAuthInfoFailure'
  }
}, (dux: IExposeRedux) => {
  return identityApi.acct.getAuthInfo.bind(identityApi.acct);
});
export const attemptGetIdentityAccount = getIdentityAuthInfoAction.go;


export interface IUnlinkAccountActionParams {
  idpUserId: string,
  idpName: string
}
const unlinkAccountAction = new ApiReduxAction<IUnlinkAccountActionParams>(identityApi.acct, {
  request: { type: authActions.UNLINK_ACCOUNT_REQUEST },
  success: { type: authActions.UNLINK_ACCOUNT_SUCCESS },
  failure: {
    type: authActions.UNLINK_ACCOUNT_FAILURE,
    title: 'unlinkAccountFailure'
  }
}, (dux: IExposeRedux, { idpUserId, idpName }) => {

  return identityApi.acct.unlinkAccount.bind(identityApi.acct, idpUserId, idpName);
});
export const attemptUnlinkAccount = unlinkAccountAction.go;


