import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';


import {
  UniTable,
  UniConditionalRender,
  UniIcon,
  IUniTable_Column,
  IPaginatedResponseC,
  IPaginationQueryBuilderParamsC,
  IReaderC,
  ReaderC,
  OrganizationC,
  IUniConfirm_Config,
  IUniTable_UpdatePaginationSummary,
  IUniTable_Filter,
  IUniTable_Sort,
  IUniTable_Action,
  IUniTable_PaginationSummary,
  EOperationCodesC
} from '@unikey/unikey-commons/release/comm';

import {
  ReaderEnrollContainer,
  buildTableUpdateFunc, TTableUpdateFunc,
  getTableSortDirection, getTableParamsFromUpdate,
  PartnerCustomizations, IPartnerCustomizations,
  attemptRetrieveOrgDetails,
  updateReaderListQueryParams,
  attemptRetrieveReadersList,
  updateReadersTableMeta,
  attemptDeleteReader,
  toggleEnrollReadersModal,
  openConfirmModal,
  closeConfirmModal,
  canI,
  navConfig, ENavPages
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  readerData: IPaginatedResponseC<IReaderC, ReaderC>,
  readerListQueryParams: IPaginationQueryBuilderParamsC,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  paginationSummary: IUniTable_PaginationSummary,
  listLoading: boolean,
  hideEnrollment2: boolean,

  orgDetails: OrganizationC,
  permissionToDeleteReader: boolean,
  permissionToViewIndividualReader: boolean,

  getOrgDetails(orgId: string): Promise<void>,
  updateQueryParams?(params: IPaginationQueryBuilderParamsC): void,
  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  getOrgReaders(orgId: string): Promise<void>,
  deleteReader(readerId: string): Promise<void>,
  openDeleteReaderConfirmDialog(dialogConfig: IUniConfirm_Config): void,
  closeConfirmModal(): void,
  toggleEnrollModal(): void,
}

class ReaderListContainer extends Component<IProps> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);

    this._updateTable = buildTableUpdateFunc(
      props.getOrgReaders.bind(this, this.props.match.params.organizationId),
      props.updateTableMeta,
      props.updateQueryParams
    )
  }

  componentDidMount() {
    this.props.getOrgDetails(this.props.match.params.organizationId);
    this.props.getOrgReaders(this.props.match.params.organizationId);
  }

  _deleteReaderAndRefreshList = (readerId: string) => {
    this.props.deleteReader(readerId).then(this._refreshReadersList);
  }

  _refreshReadersList = () => {
    this.props.getOrgReaders(this.props.match.params.organizationId);
  }

  _openEnrollDialog = () => {
    this.props.toggleEnrollModal();
  }

  _buildColumnsAndActions() {
    const columns = new Map<string, IUniTable_Column>()
      .set('name', {
        nameKey: 'name',
        isSortable: true,
        sortFields: ['name'],
        isPrimaryFilter: true,
        size: 3
      })
      .set('serialNumber', {
        nameKey: 'serialNumber',
        isSortable: false,
        size: 2,
        evalItalicized: (rowItem: ReaderC) => !rowItem.serialNumber
      })
      .set('firmwareVersion', {
        nameKey: 'version',
        isSortable: false,
        size: 2
      })
      .set('upgradeAvailable', {
        nameKey: 'status',
        isSortable: false,
        size: 1,
        template: (rowItem: ReaderC) => (
          <UniIcon
            size="sm"
            tooltipPosition="top"
            tooltipTextKeys={rowItem.upgradeAvailable ? ['firmwareUpgradeAvailable'] : undefined}
            tooltipBoundary="scrollParent"
            name={rowItem.upgradeAvailable ? 'warning' : 'check'} />
        )
      })
      .set('availableUpgradeVersion', {
        nameKey: 'upgradeVersion',
        isSortable: false,
        size: 2
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        isFilterable: false,
        size: 2
      });

    const actions = new Map<string, IUniTable_Action>()
      .set('view', {
        nameKey: 'view',
        icon: 'removeRedEye',
        isDefaultAction: true,
        func: (rowItem: ReaderC) => this.props.history.push(navConfig.get(ENavPages.readerDetails)!.linkTo([this.props.match.params.organizationId, rowItem.id])!),
        evalDisabled: (rowItem: ReaderC) => false,
        evalVisible: (rowItem: ReaderC) => this.props.permissionToViewIndividualReader
      })
      .set('removeReader', {
        nameKey: 'removeReader',
        icon: 'delete',
        func: (rowItem: ReaderC) => this.props.openDeleteReaderConfirmDialog({
          titleKey: 'removeReader',
          messageKeys: ['youAreAboutToDeleteThisReader', rowItem.name],
          confirmTextKey: 'delete',
          cancelHandler: this.props.closeConfirmModal,
          confirmHandler: () => {
            this._deleteReaderAndRefreshList(rowItem.id)
            this.props.closeConfirmModal()
          }
        }),
        evalDisabled: (rowItem: ReaderC) => false,
        evalVisible: (rowItem: ReaderC) => this.props.permissionToDeleteReader
      });

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();

    return (
      <>
        <section className="readerList-container">
          <UniTable
            searchable={true}
            titleKey="readersList"
            postTitleKey={this.props.orgDetails.name ? ` - ${this.props.orgDetails.name}` : ''}
            createButtonTextKey={!this.props.hideEnrollment2 ? 'enrollReaders' : undefined}
            handleCreateClick={!this.props.hideEnrollment2 ? this._openEnrollDialog : undefined}
            handleUpdate={this._updateTable}
            data={this.props.readerData.models ?? []}
            columnConfig={columns}
            actionsConfig={actions}
            activeSorts={this.props.appliedSorts}
            activeFilters={this.props.appliedFilters}
            paginationSummary={this.props.paginationSummary}
            showLoader={this.props.listLoading} />
        </section>
      </>
    )
  }
}

function mapStateToProps(state: any, ownProps: IProps) {
  return {
    readerData: state.readers.data,
    readerListQueryParams: state.readers.queryParams,
    appliedFilters: state.readers.tableFilters,
    appliedSorts: state.readers.tableSorts,
    paginationSummary: state.readers.paginationSummary,
    listLoading: state.readers.loading,
    orgDetails: state.orgDetails.origOrg,
    // permissions
    permissionToDeleteReader: canI(EOperationCodesC.DeleteReader, state.dealer.dealerData.id, ownProps.match.params.organizationId),
    permissionToViewIndividualReader: canI(EOperationCodesC.ReadReader, state.dealer.dealerData.id, ownProps.match.params.organizationId)
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  toggleEnrollModal: toggleEnrollReadersModal,
  getOrgDetails: attemptRetrieveOrgDetails,
  getOrgReaders: attemptRetrieveReadersList,
  updateQueryParams: updateReaderListQueryParams,
  updateTableMeta: updateReadersTableMeta,

  deleteReader: attemptDeleteReader,
  openDeleteReaderConfirmDialog: openConfirmModal,
  closeConfirmModal,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(ReaderListContainer)
  ), { componentName: 'ReaderList' })
