import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-grid-system'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  UniTable,
  UniConditionalRender,
  IUniTable_Column,
  EOperationCodesC,
  ES10nSettings,
  IPartnerCredImpls,
  IUniConfirm_Config,
  EUniTable_FooterStyle,
  Device,
} from '@unikey/unikey-commons/release/comm';

import {
  PartnerCustomizations, IPartnerCustomizations,
  openConfirmModal,
  closeConfirmModal,
  attemptRevokeDevice,
  attemptRetrieveCredentialById,
  s10n, canI,
  portalRedirect,
  navConfig, ENavPages
} from '../internal';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  partnerCredImpls: IPartnerCredImpls,
  devices: Device[],
  loading: boolean,
  openRevokeDeviceConfirmDialog(dialogConfig: IUniConfirm_Config): void,
  closeConfirmModal(): void,
  revokeDevice(deviceId: string): Promise<void>,
  refetchCredential(): Promise<void>
}

class DeviceListContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  _revokeDeviceAndRefreshList = (deviceId: string) => {
    this.props.revokeDevice(deviceId).then(() => {
      this.props.refetchCredential();
    })
  }

  _buildColumnsAndActions() {
    const columns = new Map<string, IUniTable_Column>()
      .set('deviceType', {
        nameKey: 'deviceType',
        isSortable: false,
        size: 4,
      })
      .set('make', {
        nameKey: 'make',
        isSortable: false,
        size: 4,
      })
      .set('model', {
        nameKey: 'model',
        isSortable: false,
        size: 4,
      })
      .set('os', {
        nameKey: 'os',
        isSortable: false,
        size: 2,
      })
      .set('osVersion', {
        nameKey: 'osVersion',
        isSortable: false,
        size: 4
      })
      .set('lastRedeemedOn', {
        nameKey: 'redeemedOn',
        isSortable: false,
        size: 4,
        type: 'date'
      })
      .set('actions', {
        nameKey: 'actions',
        isSortable: false,
        size: 2,
        collapsed: true
      });

    const actions = new Map();
    actions.set('view', {
      nameKey: 'view',
      icon: 'removeRedEye',
      isDefaultAction: true,
      func: (rowItem: Device) => portalRedirect(navConfig.get(ENavPages.deviceDetails)!.linkTo([this.props.match.params.organizationId, this.props.match.params.credentialId, rowItem.id])!),
    })

    actions.set('revokeDevice', {
      nameKey: 'revokeDevice',
      icon: 'delete',
      func: (device: Device) => this.props.openRevokeDeviceConfirmDialog({
        titleKey: 'revokeDevice',
        messageKeys: ['youAreAboutToRevokeTheCredentialFromThisDevice', `${device.deviceType} ${device.make ? device.make : ''} ${device.model ? device.model : ''}`],
        confirmTextKey: 'revoke',
        cancelHandler: this.props.closeConfirmModal,
        confirmHandler: () => {
          this._revokeDeviceAndRefreshList(device.id)
          this.props.closeConfirmModal()
        }
      })
    });

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }
    const { columns, actions } = this._buildColumnsAndActions();

    return (
      <section className='deviceList-container'>
        <Row>
          <Col>
            <UniTable
              searchable={false}
              titleKey="devicesWithThisCredential"
              createButtonTextKey="device"
              data={this.props.devices}
              columnConfig={columns}
              actionsConfig={actions}
              showLoader={this.props.loading}
              footerStyle={EUniTable_FooterStyle.none} />
          </Col>
        </Row>

      </section>
    )
  }
}

function mapStateToProps(state: any, ownProps: IProps) {
  return {
    loading: state.credentialDetails.loading,
    devices: state.credentialDetails.credentialData?.devices || []
  }
}

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => bindActionCreators({
  closeConfirmModal,
  openRevokeDeviceConfirmDialog: openConfirmModal,
  revokeDevice: attemptRevokeDevice.bind(null, ownProps.match?.params?.credentialId),
  refetchCredential: attemptRetrieveCredentialById.bind(null, ownProps.partnerCredImpls, ownProps.match?.params?.credentialId),
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(DeviceListContainer)
  ), { componentName: 'DeviceList' })
