import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import {
  emptyGuid,
  UniSteps,
  UniTable,
  UniToggle,
  UniLoader,
  UniLocalize,
  UniWorkflow,
  UniOverlapGroup,
  UniOverlapButton,
  UniConditionalRender,
  IUniTable_Column,
  IPaginationQueryBuilderParamsC,
  IUniTable_Filter,
  IUniTable_Sort,
  IUniTable_PaginationSummary,
  IUniTable_UpdatePaginationSummary,
  OrganizationC
} from '@unikey/unikey-commons/release/comm';

import {
  calcLoaderPercentageComplete,
  togglePermissionManagerModal,
  handleAdminPermissionsChange,
  restoreInitialAdminPermissionsManager,
  attemptUpdateAdminPermissions,
  attemptUpdateAdminInvitationPermissions,
  attemptRetrieveAdminScope,
  attemptRetrieveAdminInvitationScope,
  attemptRetrieveOrganizations,
  updateOrgListQueryParams,
  updateOrganizationsTableMeta,
  buildTableUpdateFunc, TTableUpdateFunc,
  PartnerCustomizations, IPartnerCustomizations,
  getTableSortDirection, getTableParamsFromUpdate
} from '../internal';

import partnerConfig from '@alias-current-partner-customizations';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  history: any,
  loading?: boolean,
  saving?: boolean,
  permissionChangeJobPercentageComplete: number,
  isLimitedAdmin: boolean,
  currAdminId: string,
  currAdminInvitationId: string,
  limitedOrgs: Map<string, OrganizationC>,
  numLimitedOrgs: number,

  // stuff for the organizations list
  organizations: OrganizationC[],
  orgListQueryParams: IPaginationQueryBuilderParamsC,
  appliedFilters: IUniTable_Filter[],
  appliedSorts: IUniTable_Sort[],
  paginationSummary: IUniTable_PaginationSummary,
  listLoading: boolean,

  refreshAdminScope(adminId: string): void,
  refreshAdminInvitationScope(invitationId: string): void,
  togglePermissionManagerModal(): void,
  saveAdminPermissions(): Promise<void>,
  saveInvitationPermissions(): Promise<void>,
  updateAdminPermissions(limited?: boolean, orgMap?: Map<string, OrganizationC>): void,
  clearPermissionsChanges(): void,

  updateTableMeta(metaSummary: IUniTable_UpdatePaginationSummary): void,
  updateQueryParams?(params: IPaginationQueryBuilderParamsC): void,
  getOrgs(): Promise<void>,
}

class DealerEditCreditsContainer extends Component<IProps> {
  _updateTable: TTableUpdateFunc; 

  constructor(props: IProps) {
    super(props);
    
    this._updateTable = buildTableUpdateFunc(
      props.getOrgs,
      props.updateTableMeta,
      props.updateQueryParams
    )
  }

  componentDidMount() {
    this.props.getOrgs();
  }

  _savePermissionChangesAndReloadUser = () => {
    const viewingPendingInviteWithoutPrecreatedUser = this.props.currAdminId === emptyGuid;
    var savePermissionsOnAdminOrInviteRequest: Promise<void>;

    if (viewingPendingInviteWithoutPrecreatedUser) {
      savePermissionsOnAdminOrInviteRequest = this.props.saveInvitationPermissions()
    } else {
      savePermissionsOnAdminOrInviteRequest = this.props.saveAdminPermissions()
    }

    savePermissionsOnAdminOrInviteRequest.then(() => {
      // if we saved successfully, then close and clear
      this.props.togglePermissionManagerModal();
      this.props.clearPermissionsChanges();
      
      if (viewingPendingInviteWithoutPrecreatedUser) {
        this.props.refreshAdminInvitationScope(this.props.currAdminInvitationId);
      } else {
        this.props.refreshAdminScope(this.props.currAdminId);
      }
    });
  }

  _handleLimitedChange = (isLimited: boolean) => {
    this.props.updateAdminPermissions(isLimited);
  }

  _toggleSelectedOrg = (org: OrganizationC): any => {
    if (this.props.limitedOrgs.has(org.id)) {
      this.props.limitedOrgs.delete(org.id);
    } else {
      this.props.limitedOrgs.set(org.id, org);
    }
    this.props.updateAdminPermissions(undefined, this.props.limitedOrgs);
  }

  _buildColumnsAndActions() {
    const columns = new Map<string, IUniTable_Column>()
      .set('name', {
        nameKey: 'name',
        isSortable: true,
        sortFields: ['name'],
        size: 20
      });

    const actions = new Map();

    return { columns, actions };
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    const { columns, actions } = this._buildColumnsAndActions();

    return (
      <section className='admin-manage-permissions-container'>
        <UniWorkflow
          titleKey="manageAdminPermissions"
          size="widest"
          inModal={true}
          handleClose={this.props.togglePermissionManagerModal}>
          <UniLoader
            hidden={!this.props.saving}
            type='linear'
            percentageComplete={this.props.permissionChangeJobPercentageComplete} />
          <h3><UniLocalize translate="setPermissionScope" /></h3>

          <UniToggle
            size="sm"
            options={[
              {
                value: false,
                nameKey: 'fullAdmin'
              },
              {
                value: true,
                nameKey: 'limitedAdmin'
              }
            ]}
            value={this.props.isLimitedAdmin}
            handleUpdate={this._handleLimitedChange} />

          <UniConditionalRender visible={!this.props.isLimitedAdmin} >
            <p><UniLocalize translate="_adminInviteFullAdminExplination" /></p>
          </UniConditionalRender>

          <UniConditionalRender visible={this.props.isLimitedAdmin} >
            <p><UniLocalize translate="_adminInviteLimitedAdminExplination" /></p>
            {/* <UniChips
                    labelKey="asLimitedAdminOfTheFollowingOrganizations"
                    collection={this.props.limitedOrgs}
                    handleRemoveChip={this._toggleSelectedOrg}/> */}

            {/* selectable list of organizations to make user a limited admin of */}
            <UniTable
              selectable={true}
              searchable={true}
              titleKey="limitedOrgs"
              createButtonTextKey="organization"
              selectedItems={this.props.limitedOrgs}
              handleRowItemSelectToggle={this._toggleSelectedOrg}
              handleUpdate={this._updateTable}
              data={this.props.organizations}
              paginationSummary={this.props.paginationSummary}
              columnConfig={columns}
              actionsConfig={actions}
              activeSorts={this.props.appliedSorts}
              activeFilters={this.props.appliedFilters}
              showLoader={this.props.listLoading} />
          </UniConditionalRender>

          <UniOverlapGroup foldEarly={true}>
            <UniOverlapButton
              handleClick={this._savePermissionChangesAndReloadUser}
              textKey="save"
              icon="save"
              showLoader={this.props.saving}
              disabled={this.props.isLimitedAdmin && this.props.limitedOrgs.size === 0}
              tooltipPosition="right" />
            <UniOverlapButton
              handleClick={() => { this.props.togglePermissionManagerModal(); this.props.clearPermissionsChanges() }}
              textKey="cancel"
              icon="close"
              secondary={true}
              tooltipPosition="right" />
          </UniOverlapGroup>

        </UniWorkflow>
      </section>
    )
  }
}

function mapStateToProps(state: any) {
  return {
    currAdminId: state.adminDetails.adminData.id,
    currAdminInvitationId: state.adminDetails.adminData.invitationId,
    isLimitedAdmin: state.adminDetails.permissionsEditor.isLimited,
    limitedOrgs: state.adminDetails.permissionsEditor.limitedOrgs,
    numLimitedOrgs: state.adminDetails.permissionsEditor.limitedOrgs?.size, // needed to trigger this component to re-render as the map size changes
    saving: state.adminDetails.permissionsEditor.loading,
    permissionChangeJobPercentageComplete: calcLoaderPercentageComplete(state.adminDetails.permissionsEditor.jobReqsOutstanding, state.adminDetails.permissionsEditor.jobReqsTotal),

    // stuff for the list of organizations
    organizations: state.organizations.data.models,
    orgListQueryParams: state.organizations.queryParams,
    appliedFilters: state.organizations.tableFilters,
    appliedSorts: state.organizations.tableSorts,
    paginationSummary: state.organizations.paginationSummary,
    listLoading: state.organizations.loading,
  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  togglePermissionManagerModal,
  updateAdminPermissions: handleAdminPermissionsChange,
  saveAdminPermissions: attemptUpdateAdminPermissions,
  saveInvitationPermissions: attemptUpdateAdminInvitationPermissions,
  refreshAdminScope: attemptRetrieveAdminScope,
  refreshAdminInvitationScope: attemptRetrieveAdminInvitationScope,
  clearPermissionsChanges: () => dispatch(restoreInitialAdminPermissionsManager()),
  // stuff for the list of organizations
  getOrgs: attemptRetrieveOrganizations,
  updateQueryParams: updateOrgListQueryParams,
  updateTableMeta: updateOrganizationsTableMeta,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(DealerEditCreditsContainer)
  ), { componentName: 'AdminPermissions' })
