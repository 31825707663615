import {
  OrganizationC,
  IPaginatedResponseC,
  IPaginationQueryBuilderParamsC,
  IOrganizationC,
  IUniTable_UpdatePaginationSummary,
  IOrgCreditAllocationBodyC,
  IMultiInputUpdate,
  IUpdateOrgBodyC,
  ApiReduxAction,
} from '@unikey/unikey-commons/release/comm'

import {
  api,
  IExposeRedux,
  portalRedirect,
  oops404Key
} from '../../internal'

export enum orgActions {


  GET_ORGANIZATIONS_REQUEST = 'GET_ORGANIZATIONS_REQUEST',
  GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS',
  GET_ORGANIZATIONS_FAILURE = 'GET_ORGANIZATIONS_FAIL',

  SET_ACTIVE_ORG = 'SET_ACTIVE_ORG',

  GET_ORG_DETAILS_REQUEST = 'GET_ORG_DETAILS_REQUEST',
  GET_ORG_DETAILS_SUCCESS = 'GET_ORG_DETAILS_SUCCESS',
  GET_ORG_DETAILS_FAILURE = 'GET_ORG_DETAILS_FAILURE',

  HANDLE_ORG_DETAILS_CHANGE = 'HANDLE_ORG_DETAILS_CHANGE',
  UPDATE_ORG_DETAILS_REQUEST = 'UPDATE_ORG_DETAILS_REQUEST',
  UPDATE_ORG_DETAILS_SUCCESS = 'UPDATE_ORG_DETAILS_SUCCESS',
  UPDATE_ORG_DETAILS_FAILURE = 'UPDATE_ORG_DETAILS_FAILURE',

  GET_ORG_READERS_REQUEST = 'GET_ORG_READERS_REQUEST',
  GET_ORG_READERS_SUCCESS = 'GET_ORG_READERS_SUCCESS',
  GET_ORG_READERS_FAILURE = 'GET_ORG_READERS_FAILURE',
  UPDATE_ORG_READERS_TABLE_META = 'UPDATE_ORG_READERS_TABLE_META',

  CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST',
  CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS',
  CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAIL',

  TOGGLE_CREATE_ORG_MODAL = 'TOGGLE_CREATE_ORG_MODAL',

  UPDATE_ORGANIZATIONS_QUERY_PARAMS = 'UPDATE_ORGANIZATIONS_QUERY_PARAMS',
  UPDATE_ORGANIZATIONS_TABLE_META = 'UPDATE_ORGANIZATIONS_TABLE_META',

  HANDLE_NEW_ORG_CHANGE = 'HANDLE_NEW_ORG_CHANGE',

  UPDATE_ORG_CREDITS_REQUEST = 'UPDATE_ORG_CREDITS_REQUEST',
  UPDATE_ORG_CREDITS_SUCCESS = 'UPDATE_ORG_CREDITS_SUCCESS',
  UPDATE_ORG_CREDITS_FAILURE = 'UPDATE_ORG_CREDITS_FAILURE',
  HANDLE_ORG_CREDITS_CHANGE = 'HANDLE_ORG_CREDITS_CHANGE',

  DELETE_ORG_REQUEST = 'DELETE_ORG_REQUEST',
  DELETE_ORG_SUCCESS = 'DELETE_ORG_SUCCESS',
  DELETE_ORG_FAILURE = 'DELETE_ORG_FAILURE',
}

// ORGS
const getOrganizationList = new ApiReduxAction(api.orgz, {
  request: { type: orgActions.GET_ORGANIZATIONS_REQUEST },
  success: { type: orgActions.GET_ORGANIZATIONS_SUCCESS },
  failure: {
    type: orgActions.GET_ORGANIZATIONS_FAILURE,
    title: 'getOrgsFail',
  },
  tableMetaUpdate: {
    type: orgActions.UPDATE_ORGANIZATIONS_TABLE_META
  }
}, (dux: IExposeRedux) => {
  const state = dux.getState();
  const params = state.organizations.queryParams;
  const dealerId: string = state.portal.activeDealer?.id;
  return api.orgz.getAllDealerOrgs.bind(api.orgz, dealerId, params);
});
export const attemptRetrieveOrganizations = getOrganizationList.go;

export function updateOrganizationsTableMeta(metaUpdate: IUniTable_UpdatePaginationSummary) {
  return {
    type: orgActions.UPDATE_ORGANIZATIONS_TABLE_META,
    ...metaUpdate
  }
}

export function updateOrgListQueryParams(queryParams: IPaginationQueryBuilderParamsC) {
  return {
    type: orgActions.UPDATE_ORGANIZATIONS_QUERY_PARAMS,
    queryParams
  }
}

// ORG DETAILS
const getOrganizationDetailsActon = new ApiReduxAction<string>(api.orgz, {
  request: { type: orgActions.GET_ORG_DETAILS_REQUEST },
  success: { type: orgActions.GET_ORG_DETAILS_SUCCESS },
  failure: {
    type: orgActions.GET_ORG_DETAILS_FAILURE,
    title: 'getOrgDetailsFail',
  },
  handleErrorCodes: {
    404: (dux: IExposeRedux, err: any) => {
      portalRedirect(oops404Key);
      // not the error we were hoping to handle, so return an 
      // empty object to tell the action handler to handle as normal
      return { preventAlert: true };
    },
  },
}, (dux: IExposeRedux, orgId) => {
  return api.orgz.getOrg.bind(api.orgz, orgId);
});
export const attemptRetrieveOrgDetails = getOrganizationDetailsActon.go;

// DELETE ORG
const deleteOrgAction = new ApiReduxAction<string>(api.orgz, {
  request: { type: orgActions.DELETE_ORG_REQUEST },
  success: {
    type: orgActions.DELETE_ORG_SUCCESS,
    title: 'deletedOrg',
    message: 'orgDeletedSuccessfully'
  },
  failure: {
    type: orgActions.DELETE_ORG_FAILURE,
    title: 'deleteOrgFail',
  }
}, (dux: IExposeRedux, orgId: string) => {
  return api.orgz.deleteOrg.bind(api.orgz, orgId);
});
export const attemptDeleteOrg = deleteOrgAction.go;

export function handleCreateOrgChange(changes: IMultiInputUpdate, isUnlimited?: boolean) {
  return {
    type: orgActions.HANDLE_NEW_ORG_CHANGE,
    ...changes,
    unlimitedCredits: isUnlimited
  };
}

export function toggleCreateOrgModal() {
  return {
    type: orgActions.TOGGLE_CREATE_ORG_MODAL
  }
}

// CREATE ORG
const createOrgAction = new ApiReduxAction<string>(api.orgz, {
  request: { type: orgActions.CREATE_ORGANIZATION_REQUEST },
  success: {
    type: orgActions.CREATE_ORGANIZATION_SUCCESS,
    title: 'createdOrg',
    message: 'orgCreatedSuccessfully'
  },
  failure: {
    type: orgActions.CREATE_ORGANIZATION_FAILURE,
    title: 'createOrgFail',
  }
}, (dux: IExposeRedux, orgName) => {
  const state = dux.getState();
  const dealerId: string = state.portal.activeDealer?.id;
  const newOrg: any = state.createOrg.newOrg;
  const credits: number = newOrg.unlimitedCredits ? undefined : newOrg.creditAllocation.value;
  return api.orgz.createOrganization.bind(api.orgz, dealerId, orgName, credits);
});
export const attemptCreateNewOrg = createOrgAction.go;

// UPDATE ORG CREDITS
const updateOrgCreditAllocationAction = new ApiReduxAction<string>(api.orgz, {
  request: { type: orgActions.UPDATE_ORG_CREDITS_REQUEST },
  success: {
    type: orgActions.UPDATE_ORG_CREDITS_SUCCESS,
    title: 'updateOrgCreditsSuccess',
    message: 'creditAllocationChanged'
  },
  failure: {
    type: orgActions.UPDATE_ORG_CREDITS_FAILURE,
    title: 'updateOrgCreditsFail',
  }
}, (dux: IExposeRedux, orgId) => {
  
  const creditState = dux.getState().orgDetails.editCredits;
  const creditsUpdateBody: IOrgCreditAllocationBodyC = {
    credits_allocated: creditState.isLimited.value ? (creditState.allocated.value || creditState.claimed.value) : null
  };
  return api.orgz.updateOrgCredits.bind(api.orgz, orgId, creditsUpdateBody);
});
export const attemptUpdateOrgCredits = updateOrgCreditAllocationAction.go;

export function handleOrgCreditsChange(changes: IMultiInputUpdate) {
  return {
    type: orgActions.HANDLE_ORG_CREDITS_CHANGE,
    ...changes
  };
}

// UPDATE ORG DETAILS
const updateOrgAction = new ApiReduxAction<string>(api.orgz, {
  request: { type: orgActions.UPDATE_ORG_DETAILS_REQUEST },
  success: {
    type: orgActions.UPDATE_ORG_DETAILS_SUCCESS,
    title: 'updateOrgDetailsSuccess',
    message: 'orgDetailsChanged'
  },
  failure: {
    type: orgActions.UPDATE_ORG_DETAILS_FAILURE,
    title: 'updateOrgFail',
  }
}, (dux: IExposeRedux, orgId) => {
  const orgsOriginalName = dux.getState().orgDetails.originalName;
  const orgEditState = dux.getState().orgDetails.orgData;
  const orgDetailsBody: IUpdateOrgBodyC = {
    name: orgEditState.name.value,
    status: orgEditState.status.value
  };
  if (orgsOriginalName === orgDetailsBody.name) {
    delete orgDetailsBody.name;
  }
  return api.orgz.updateOrg.bind(api.orgz, orgId, orgDetailsBody);
});
export const attemptUpdateOrgDetails = updateOrgAction.go;

export function handleOrgDetailsChange(changes: IMultiInputUpdate) {
  return {
    type: orgActions.HANDLE_ORG_DETAILS_CHANGE,
    ...changes
  };
}
