import { Dispatch } from 'redux'

import {  
  EMessageType,
  IMultiInputUpdate,
  ApiReduxAction,
} from '@unikey/unikey-commons/release/identity'

import {
  navConfig, ENavPages,
  loginKey,
  identityApi,
  IExposeRedux,
  portalRedirect,
} from '../../internal'

export enum tokenActions {
  UPDATE_SET_PASSWORD_TOKEN_FORM = 'UPDATE_SET_PASSWORD_TOKEN_FORM',

  USER_TOKEN_ACTION_REQUEST = 'USER_TOKEN_ACTION_REQUEST',
  USER_TOKEN_ACTION_SUCCESS = 'USER_TOKEN_ACTION_SUCCESS',
  USER_TOKEN_ACTION_FAILURE = 'USER_TOKEN_ACTION_FAILURE',
}

export interface IConfirmationActionParams {
  token: string,
  newEmail?: string,
  newPass?: string,
  reason?: string
}

const setPasswordWithToken = new ApiReduxAction<IConfirmationActionParams>(identityApi.acct, {
  request: { type: tokenActions.USER_TOKEN_ACTION_REQUEST },
  success: {
    type: tokenActions.USER_TOKEN_ACTION_SUCCESS,
    title: 'success',
    message: 'newPasswordSet',
    after: (dux: IExposeRedux) => {
      setTimeout(() => {
        portalRedirect(loginKey)
      }, 1000)
    }
  },
  failure: {
    type: tokenActions.USER_TOKEN_ACTION_FAILURE,
    title: 'passwordChangeFailure'
  }
}, (dux: IExposeRedux, { token, newPass }) => {
  return identityApi.acct.applyNewPasswordWithToken.bind(identityApi.acct, { token, newPass });
});
export const attemptSetPasswordWithToken = setPasswordWithToken.go;

export const updateSetPasswordTokenForm = (formChanges: IMultiInputUpdate) => {
  return {
    type: tokenActions.UPDATE_SET_PASSWORD_TOKEN_FORM,
    formChanges
  }
}

const verifyAccountWithToken = new ApiReduxAction<IConfirmationActionParams>(identityApi.acct, {
  request: { type: tokenActions.USER_TOKEN_ACTION_REQUEST },
  success: {
    type: tokenActions.USER_TOKEN_ACTION_SUCCESS,
    title: 'success',
    message: 'emailVerified',
    duration: 12000 
  },
  failure: {
    type: tokenActions.USER_TOKEN_ACTION_FAILURE,
    title: 'verifyEmailFail'
  }
}, (dux: IExposeRedux, { token }) => {
  return identityApi.acct.applyEmailVerifyWithToken.bind(identityApi.acct, { token });
});
export const attemptVerifyAccountWithToken = verifyAccountWithToken.go;
