import React, { Component, ReactNode } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import classNames from 'classnames';
import { Container, Row, Col } from 'react-grid-system'

import {
  UniKeyVal,
  UniLocalize,
  IUniConfirm_Config,
  UniConditionalRender,
  UserC,
} from '@unikey/unikey-commons/release/comm';

import {
  ExternalIdentityT,
  AccountID
} from '@unikey/unikey-commons/release/identity';

import {
  api,
  AddPasswordEmailRequestContainer,
  ChangePasswordContainer,
  IUnlinkAccountActionParams,
  attemptRetrieveAuthUser,
  attemptUnlinkAccount,
  attemptGetLinkedAccounts,
  attemptGetIdentityAccount,
  toggleChangePasswordWorkflow,
  toggleAddPasswordWorkflow,
  openConfirmModal,
  closeConfirmModal,
  redirectToLogout,
  PartnerCustomizations, IPartnerCustomizations,
  noop
} from '../internal'

import partnerConfig from '@alias-current-partner-customizations';

interface IProps extends WrappedComponentProps, IPartnerCustomizations {
  match: any,
  history: any,
  accountLinks: ExternalIdentityT[],
  accountLinksLoading: boolean,
  authUserId: string,
  authUserEmail: string,
  currentAuthIdpUser?: string,
  authUserHasPassword?: boolean,
  passwordUsedForCurrentSession?: boolean,
  getCurrentUser(): Promise<UserC>,
  getAuthInfo(): Promise<AccountID>,
  getLinkedAccounts(): Promise<ExternalIdentityT[]>,
  unlinkAccount(params: IUnlinkAccountActionParams): Promise<void>,
  toggleChangePasswordWorkflow(): void,
  toggleChangeEmailWorkflow(): void,
  toggleAddPasswordWorkflow(): void,
  openConfirmDialog(dialogConfig: IUniConfirm_Config): void,
  closeConfirmModal(): void,
  redirectToLogout(ignoreCache?: boolean): void,
}

class MyLinkedAccountsContainer extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }

  componentDidMount() {
    this.props.getCurrentUser().then(() => {
      this.props.getAuthInfo();
      this.props.getLinkedAccounts();
    })
  }

  _getTitleImgForUnlink = (provider: string): string => {
    if (provider === 'google') {
      return partnerConfig.assets.googleLogo;
    } else if (provider === 'apple') {
      return partnerConfig.assets.appleLogo;
    } else {
      return partnerConfig.assets.bgs.support2;
    }
  }

  render() {
    if (this.props.render) {
      return this.props.render();
    }

    return (
      <section className='linkedAccounts-container'>

        <Row justify="between" >
          <Col>
            <h3>
              <UniLocalize translate="signinOptions" />
            </h3>
          </Col>
        </Row>

        <Row>

          {/* password sign-in */}
          <Col xs={24} md={20} lg={18} xl={16}>
            <UniKeyVal
              key={`userhaspass-${this.props.authUserHasPassword}`}
              showLoader={this.props.authUserHasPassword === undefined}
              className="signin-option password"
              label="myAccountLink-localPassword"
              primaryStateButtonSet={
                this.props.authUserHasPassword ? [
                  {
                    textKey: 'changePassword',
                    icon: 'edit',
                    clickHandler: this.props.toggleChangePasswordWorkflow
                  }
                ] : [
                  {
                    textKey: 'setPassword',
                    icon: 'add',
                    clickHandler: this.props.toggleAddPasswordWorkflow
                  }
                ]
              }
              fields={[
                {
                  keyName: 'passwordSignin',
                  value: this.props.authUserHasPassword ? '********' : 'noPasswordSet',
                  type: 'string',
                  disabled: true,
                },
                {
                  keyName: `_emptyString`,
                  value: 'currentSigninMethod',
                  type: 'template',
                  template: (<div className="key-val-item-active-highlight"><UniLocalize translate="currentSigninMethod" /></div>),
                  disabled: true,
                  hidden: !this.props.passwordUsedForCurrentSession
                },
              ]} />
          </Col>
        </Row>

        <Row justify="between" >
          <Col>
            <h3>
              <UniLocalize translate="externalSigninOptions" />
            </h3>
          </Col>
        </Row>

        <Row>
          {this.props.accountLinks.map((acctLink: ExternalIdentityT, index: number) => (
            <>
              <Col xs={24} md={20} lg={18} xl={16} key={index}>
                <UniKeyVal
                  key={`link-${index}-${this.props.currentAuthIdpUser}`}
                  label="myAccountLink"
                  className={`signin-option ${acctLink?.providerName}`}
                  primaryStateButtonSet={[
                    {
                      className: 'disable-account-link-btn',
                      textKey: 'unlinkAccount',
                      theme: 'fail',
                      icon: 'close',
                      clickHandler: () => this.props.openConfirmDialog({
                        titleKey: 'unlinkAccount',
                        titleImgSrc: this._getTitleImgForUnlink(acctLink?.providerName),
                        titleIcon: 'link',
                        messageKeys: [
                          'youAreAboutToUnlinkThisAccount',
                          `${acctLink.email} (${acctLink.providerName})`,
                          (acctLink.providerUserId === this.props.currentAuthIdpUser ? '_warningUnlinkActiveLink' : ''),
                          'areYouSure'
                        ],
                        confirmTextKey: 'unlink',
                        cancelHandler: this.props.closeConfirmModal,
                        confirmHandler: () => {
                          return this.props.unlinkAccount({
                            idpUserId: acctLink?.providerUserId,
                            idpName: acctLink?.providerName
                          }).then(() => {
                            this.props.closeConfirmModal();
                            // log them out if they just disabled their current sign-in option
                            if (acctLink.providerUserId === this.props.currentAuthIdpUser) {
                              this.props.redirectToLogout(true);
                            } else {
                              this.componentDidMount();
                            }
                          })
                        }
                      })
                    }
                  ]}
                  fields={[
                    {
                      keyName: `${acctLink?.providerName}Signin`,
                      value: acctLink?.email ?? '',
                      type: 'string',
                      disabled: true,
                    },
                    {
                      keyName: `_emptyString`,
                      value: 'currentSigninMethod',
                      type: 'template',
                      template: (<div className="key-val-item-active-highlight"><UniLocalize translate="currentSigninMethod" /></div>),
                      disabled: true,
                      hidden: acctLink.providerUserId !== this.props.currentAuthIdpUser
                    }
                  ]} />
              </Col>
            </>
          ))}
        </Row>


        {/* No linked accounts found */}
        <UniConditionalRender visible={!this.props.accountLinksLoading && this.props.accountLinks.length === 0}>
          <Row>
            <Col xs={24}>
              <UniKeyVal
                key={`empty-linked-accounts-list`}
                label="no-linked-identities"
                preventEdit={true}
                allowForOverlapAlignment={['lg', 'xl', 'xxl']}
                stacked
                fields={[
                  {
                    keyName: 'noExternalAccountLinksFound',
                    value: '_explainNoLinkedAccountsFound',
                    type: 'string'
                  },

                  {
                    keyName: '_emptyString',
                    value: '_explainLinkedAccountsBenefit',
                    type: 'string'
                  },
                  {
                    keyName: '_emptyString',
                    value: '_explainAccountLinkHowTo',
                    type: 'string'
                  }
                ]} />

            </Col>
          </Row>
        </UniConditionalRender>

      </section >
    )
  }
}

function mapStateToProps(state: any, props: IProps) {
  const idpUserId = api?.authIdpUserId;
  return {
    authUserId: state.authenticatedUser?.currentUser?.id,
    currentAuthIdpUser: idpUserId,
    passwordUsedForCurrentSession: api?.activeLoginType === 'local',
    authUserEmail: state.identity?.authIdentity?.acct?.username,
    authUserHasPassword: state.identity?.authIdentity.acct?.hasPassword,
    accountLinks: state.identity.linkedAccounts?.identities,
    accountLinksLoading: state.identity.linkedAccounts?.loading,

  }
}

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  getCurrentUser: attemptRetrieveAuthUser,
  unlinkAccount: attemptUnlinkAccount,
  getLinkedAccounts: attemptGetLinkedAccounts,
  getAuthInfo: attemptGetIdentityAccount,
  toggleChangePasswordWorkflow,
  toggleAddPasswordWorkflow,
  redirectToLogout,
  openConfirmDialog: openConfirmModal,
  closeConfirmModal,
}, dispatch)

export default PartnerCustomizations(
  connect(mapStateToProps, mapDispatchToProps)(
    injectIntl(MyLinkedAccountsContainer)
  ), { componentName: 'MyLinkedAccounts', unauthenticated: false })
