import { UniDealerApiC } from '@unikey/unikey-commons/release/comm'
import { UniIdentityApiID } from '@unikey/unikey-commons/release/identity'

export const partnerKey: string = 'pdk';
export const environment: string = 'staging';
export const isDevelopmentEnv: boolean = environment === 'development';
export const isTesting: boolean = '' as string === 'true'

const commNonceEndpoint = { nonceEndpoint: 'https://uni-stg-dealer-api.unikey.com/v5/nonces' };
export const api: UniDealerApiC = new UniDealerApiC('https://pdk-stg-dealer-api.unikey.com', 'v5', 'pdk'.toUpperCase(), { enableLogging: isDevelopmentEnv, isTesting, ...commNonceEndpoint })
export const identityApi: UniIdentityApiID = new UniIdentityApiID('https://identity-stg.unikey.com', 'api/v1', '04deff94-04b5-416a-b847-cb2c48261623', 'H+CAyYKkNgTnsek6qB51TuzZCxHKWUS4UboXv38gsOQMdixJ3ey+zmUt8TdVnzaHITRJUrJgEzeW84dI2rNpbA==', { enableLogging: isDevelopmentEnv && false, isTesting, ...commNonceEndpoint });

export const captchaSiteKey: string = '6LfgD6oUAAAAAC5TKAgmMuVVTQXi7Z150sLNVXwF';
export const captchaSecretKey: string = '6LfgD6oUAAAAAOKHWRnv61p9eYEnwCcKIR1dzHfS';

export const termsOfServiceDocument: string = '/legal/termsofuse';
export const privacyPolicyDocument: string = '/legal/privacypolicy';
export const partnerSupportLink: string = 'https://www.unikey.com/lets-connect/';
export const deeplinkDomain = 'https://pdk-stg-dealer-web.unikey.com'

export const appInsightsInstrumentationKey: string = 'a3c53265-bd80-4e5a-bccf-4ff563898731';
export const mockAuth: string = '';

export const iosAppStoreLink: string = 'https://appcenter.ms/orgs/UniKey-Technologies/apps/touch-io-Staging-1';
export const googlePlayStoreLink: string = 'https://appcenter.ms/orgs/UniKey-Technologies/apps/touch-io-Staging';

export const oidcScope: string = 'openid email profile comm.dealer.api identity.api offline_access';
export const oidcClientId: string = '04deff94-04b5-416a-b847-cb2c48261623';
export const oidcAuthority: string = 'https://identity-stg.unikey.com/.well-known/openid-configuration';
export const oidcRedirectUri: string = 'https://pdk-stg-web.unikey.com/#/token';
export const oidcRegisterUri: string = '';
export const oidcClientSecret: string = 'H+CAyYKkNgTnsek6qB51TuzZCxHKWUS4UboXv38gsOQMdixJ3ey+zmUt8TdVnzaHITRJUrJgEzeW84dI2rNpbA==';
export const oidcPartnerBrandId: string = 'f8b70bea-396d-4c17-885e-ea44d5ba4999';
export const oidcPartnerBrandCode: string = 'PDK';
export const oidcSilentRedirectUri: string = 'https://pdk-stg-web.unikey.com/#/token';
export const oidcForgotPasswordRedirect: string = '';

export const buildId: string = '40975';
export const releaseId: string = 'Release-283';
export const cdpVersion: string = '6.0.0';
export const commonsVersion: string = '8.0.4';
export const desiredLocales: string = 'en,es,ja,fr,pt';